import React, { useState } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useGetCurrentUserQuery, useReInviteUserMutation } from 'api/users';
import { formatDate } from 'utils/helper/FormatDate';
import { isMSMAdmin } from 'utils/helper/UserRoleValidation';
import { VERIFIED, UNVERIFIED } from 'utils/constants/userStatus';
import { ARCHIVED } from 'utils/constants/offerListings';
import { invitedTab, onboardedTab } from 'utils/constants/portals';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/BaseComponents/Table';
import Button from 'components/BaseComponents/Button';
import TableColumn from 'components/Portals/Admin/UserManagement/TableColumn';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import Modal from 'components/BaseComponents/Modal';
import ReInviteBuyerModalBody from '../ReInviteModal';
import 'components/Portals/Admin/UserManagement/Table/styles.scss';

const MyAffiliationsTableSkeleton = ({ tabKey, tableHeaders, users, isFetching }) => {
  const { data: user } = useGetCurrentUserQuery();
  const [reInviteUser, { isLoading: isReInviteSubmitting }] = useReInviteUserMutation();
  const [isReInviteModalVisible, setIsReInviteModalVisible] = useState(false);
  const [userIdOnReInviteClick, setUserIdOnReInviteClick] = useState(null);

  const tabFilterStatus = {
    [t('affiliate_partners.affiliations_table.onboarded_tab')]: VERIFIED,
    [t('affiliate_partners.affiliations_table.invited_tab')]: UNVERIFIED,
    [t('affiliate_partners.affiliations_table.archived_tab')]: ARCHIVED,
  };

  const handleModalOpen = (userId) => {
    setUserIdOnReInviteClick(userId);
    setIsReInviteModalVisible(true);
  };
  const handleModalClose = () => {
    setUserIdOnReInviteClick(null);
    setIsReInviteModalVisible(false);
  };

  const generateCountColumnContent = (count) => (count === 0 ? '0' : count);

  const generateUserIdContent = (tabkey, id, serialNumber) => {
    if (tabkey === onboardedTab) {
      return serialNumber;
    }

    return id;
  };

  return (
    <Table className="admin-portal__table">
      <TableHead>
        <TableRow isHeaderRow={true}>
          {tableHeaders.map((title) => (
            <TableHeader
              key={title}
              textCase="capitalize"
              unavailableText={t('admin_user_table.no_data_available')}
            >
              {title}
            </TableHeader>
          ))}
        </TableRow>
      </TableHead>

      {isFetching ? (
        <TableBody className="position-relative padding-y-32px">
          <LoadingSpinner />
        </TableBody>
      ) : (
        <TableBody>
          {users?.map(
            ({
              firstName,
              lastName,
              companyName,
              id,
              serialNumber,
              role,
              contractCount,
              confirmedAt,
              invitationCount,
              invitedAt,
              canBeReInvited,
              email,
            }) => {
              const hasGreenStatus = tabKey === VERIFIED;
              const showReInviteButton = !isMSMAdmin(user) && tabKey === invitedTab;
              const disableReInviteButton = !canBeReInvited || isReInviteSubmitting;

              return (
                <TableRow key={id}>
                  <TableColumn content={generateUserIdContent(tabKey, id, serialNumber)} />
                  <TableColumn content={`${firstName} ${lastName}`} />
                  <TableColumn content={email} />
                  <TableColumn content={companyName} />
                  <TableColumn content={role} />

                  {tabKey === invitedTab && (
                    <TableColumn
                      className={classNames('admin-portal__table--role', {
                        'admin-portal__table--role-green-text': hasGreenStatus,
                      })}
                      content={tabFilterStatus[tabKey]}
                    />
                  )}
                  {tabKey !== onboardedTab && <TableColumn content={formatDate(invitedAt)} />}
                  {tabKey === onboardedTab && <TableColumn content={formatDate(confirmedAt)} />}
                  {tabKey === onboardedTab && (
                    <TableColumn content={generateCountColumnContent(contractCount)} />
                  )}
                  {tabKey !== onboardedTab && (
                    <TableColumn content={generateCountColumnContent(invitationCount)} />
                  )}
                  {showReInviteButton && (
                    <TableColumn
                      content={
                        <Button
                          label={t('affiliate_partners.affiliations_table.re_invite_button_label')}
                          className="admin-portal__table--re-inivte"
                          disabled={disableReInviteButton}
                          onClick={() => handleModalOpen(id)}
                        />
                      }
                    />
                  )}
                </TableRow>
              );
            },
          )}
          <Modal
            isVisible={isReInviteModalVisible}
            onOutsideClick={handleModalClose}
            className="progress-card-modal"
          >
            <Modal.Content>
              <ReInviteBuyerModalBody
                userId={userIdOnReInviteClick}
                onModalClose={handleModalClose}
                onSendInvitation={reInviteUser}
              />
            </Modal.Content>
          </Modal>
        </TableBody>
      )}
    </Table>
  );
};

MyAffiliationsTableSkeleton.propTypes = {
  tabKey: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  tableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      offer_volume: PropTypes.string,
      offer_price: PropTypes.string,
      boxes_quantity: PropTypes.number,
      nut_grade: PropTypes.string,
      nut_style: PropTypes.string,
      best_before_date: PropTypes.string,
      country_of_origin: PropTypes.string,
      estimated_shipping_date: PropTypes.string,
      full_batch_price: PropTypes.string,
      listing: PropTypes.shape({
        id: PropTypes.number,
        serial_number: PropTypes.string,
        status: PropTypes.string,
        category: PropTypes.string,
        highest_bid_price: PropTypes.string,
        total_bid_count: PropTypes.string,
        product_id: PropTypes.string,
        created_at: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default MyAffiliationsTableSkeleton;
