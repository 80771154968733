/* 
  All the route paths, components and route renderings that are commented out
  Are done intentionally.
  These are all related to static pages
  Which will be moved to WordPress by the MSM (Client) Side
  Once the WordPress site is ready, we will remove the comments and update the links accordingly
*/
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PublicRoute from 'utils/middleware/PublicRoute';
import ProtectedRouteSeller from 'utils/middleware/ProtectedRouteSeller';
import ProtectedRouteBuyer from 'utils/middleware/ProtectedRouteBuyer';
import ProtectedRouteAdmin from 'utils/middleware/ProtectedRouteAdmin';
import ProtectedRoutePartner from 'utils/middleware/ProptectedRoutePartner';
import ProtectedRouteSubscriptionMember from 'utils/middleware/ProtectedRouteSubscriptionMember';
import AdminPortal from 'components/Portals/Admin';
import ProductListing from 'components/Portals/Admin/Products';
import BuyerPortal from 'components/Portals/Buyer';
import SellerPortal from 'components/Portals/Seller';
import AffiliatePartnerPortal from 'components/Portals/AffiliatePartner';
import SubscriptionMemberPortal from 'components/Portals/SubscriptionMember';
import UserProfilePage from 'components/Portals/Admin/UserManagement/Profile';
import Login from 'components/Login';
import Signup from 'components/SignUp';
import AffiliatePartnerSignUp from 'components/AffiliatePartner/Registration';
import RegistrationSuccess from 'components/SignUp/RegistrationSuccess';
import EmailVerification from 'components/SignUp/EmailVerification';
import ForgotPasswordPage from 'components/ResetPassword/Pages/ForgotPassword';
import SuccessfulMailSentPage from 'components/ResetPassword/Pages/SuccessfulMailSentPage';
import ResetPasswordPage from 'components/ResetPassword/Pages/ResetPassword';
import ResetPasswordSuccessPage from 'components/ResetPassword/Pages/ResetPasswordSuccess';
import Product from 'components/BiddingProcess/CreateListing/Product/Product';
import EditProduct from 'components/BiddingProcess/CreateListing/Product/EditProduct';
import Listings from 'components/BiddingProcess/OfferListings';
import ReviewListing from 'components/BiddingProcess/CreateListing/ReviewListing';
import AffiliatePartnerProfile from 'components/AffiliatePartner/Profile';
import AffiliatePartnerManagement from 'components/Portals/Admin/AffiliatePartnerManagement';
import AffiliatePartnerRegistrationSuccess from 'components/AffiliatePartner/Registration/RegistrationSuccess';
import InviteBuyer from 'components/AffiliatePartner/InviteBuyer';
import InvitationSuccess from 'components/AffiliatePartner/InvitationSuccess';
import ContractDetails from 'components/BiddingProcess/Contract/ContractDetails';
import WholesaleMarketplace from 'components/StaticPages/WholesaleMarketplace';
// import LandingPage from 'components/LandingPage';
// import AboutUs from 'components/StaticPages/AboutUs';
// import AggregateSalesStatistics from 'components/StaticPages/AggregateSalesStatistics';
// import ServicesPage from 'components/StaticPages/Services';
// import SupportPage from 'components/StaticPages/Support';
// import Contact from 'components/StaticPages/Contact';
// import AffiliatePartnerStaticPage from 'components/StaticPages/AffiliatePartner';
// import Cookies from 'components/StaticPages/Cookies';
// import Jurisdiction from 'components/StaticPages/Jurisdiction';
// import PrivacyPolicy from 'components/StaticPages/PrivacyPolicy';
// import TermsAndConditions from 'components/StaticPages/TermsAndConditions';
import {
  login as loginPageRoute,
  signup as signupPageRoute,
  registrationSuccess as registrationSuccessRoute,
  forgotPassword as forgotPasswordRoute,
  verifyEmail as verifyEmailRoute,
  resetPassword as resetPasswordRoute,
  resetPasswordSuccess as resetPasswordSuccessRoute,
  resetPasswordEmailCheck as resetPasswordEmailCheckRoute,
  affiliatePartnerSignup,
  unauthorized as unauthorizedRoute,
  buyer as buyerRoute,
  seller as sellerRoute,
  admin as adminRoute,
  affiliatePartnerRegistrationSuccess as affiliatePartnerRegistrationSuccessRoute,
  affiliatePartner as affiliatePartnerRoute,
  invitedBuyerSetPassword as invitedBuyerSetPasswordRoute,
  notFound,
  wholeSaleMarketPlace as wholeSaleMarketPlaceRoute,
  subscriptionMember as subscriptionMemberRoute,
  // landingPage as landingPageRoute,
  // home as homePageRoute,
  // aboutUs as aboutUsRoute,
  // services as servicesRoute,
  // support as supportRoute,
  // aggregateSalesStatistics as aggregateSalesStatisticsRoute,
  // contactUs as contactUsRoute,
  // affiliatePartnerStaticPage as affiliatePartnerStaticPageRoute,
  // cookies as cookiesRoute,
  // jurisdiction as jurisdictionRoute,
  // privacy as privacyRoute,
  // terms as termsRoute,
} from 'utils/constants/Routes';
import ListingDetails from 'components/BiddingProcess/ListingDetails';
import AdminContractDetails from 'components/BiddingProcess/AdminContractDetails';
import AdminOfferOderDetails from 'components/BiddingProcess/AdminOfferOderDetails';
import MyListings from 'components/BiddingProcess/Listings';
import FoodSafetyOfferCreation from 'components/BiddingProcess/CreateListing/FoodSafety';
import Contracts from 'components/BiddingProcess/Contract';
import BiddingBasket from 'components/BiddingProcess/BiddingBasket';
import DeclarationOfferCreation from 'components/BiddingProcess/CreateListing/Declaration';
import SetInvitedBuyerPassword from 'components/AffiliatePartner/SetPassword';
import ErrorHandling from 'components/Shared/ErrorHandling';

const USER_PROFILE_PAGE_KEY = 'USER_PROFILE_PAGE_KEY';
const AFFILIATE_PARTNER_PROFILE_PAGE_KEY = 'AFFILIATE_PARTNER_PROFILE_PAGE_KEY';

export default function MSMRoutes() {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path={loginPageRoute} element={<Login />} />
        <Route path={signupPageRoute} element={<Signup />} />
        <Route path={registrationSuccessRoute} element={<RegistrationSuccess />} />
        <Route path={forgotPasswordRoute} element={<ForgotPasswordPage />} />
        <Route path={verifyEmailRoute} element={<EmailVerification />} />
        <Route path={resetPasswordRoute} element={<ResetPasswordPage />} />
        <Route path={resetPasswordSuccessRoute} element={<ResetPasswordSuccessPage />} />
        <Route path={resetPasswordEmailCheckRoute} element={<SuccessfulMailSentPage />} />
        <Route path={affiliatePartnerSignup} element={<AffiliatePartnerSignUp />} />
        <Route
          path={affiliatePartnerRegistrationSuccessRoute}
          element={<AffiliatePartnerRegistrationSuccess />}
        />
        <Route path={invitedBuyerSetPasswordRoute} element={<SetInvitedBuyerPassword />} />
        <Route path={notFound} element={<ErrorHandling />} />
        <Route path={unauthorizedRoute} element={<ErrorHandling />} />
        <Route path="/" element={<Navigate to={loginPageRoute} />} />
        <Route path={wholeSaleMarketPlaceRoute} element={<WholesaleMarketplace />} />
        {/* <Route path={landingPageRoute} element={<LandingPage />} /> */}
        {/* <Route path={homePageRoute} element={<LandingPage />} /> */}
        {/* <Route path={aboutUsRoute} element={<AboutUs />} /> */}
        {/* <Route path={servicesRoute} element={<ServicesPage />} /> */}
        {/* <Route path={supportRoute} element={<SupportPage />} /> */}
        {/* <Route path={aggregateSalesStatisticsRoute} element={<AggregateSalesStatistics />} /> */}
        {/* <Route path={contactUsRoute} element={<Contact />} /> */}
        {/* <Route path={affiliatePartnerStaticPageRoute} element={<AffiliatePartnerStaticPage />} /> */}
        {/* <Route path={cookiesRoute} element={<Cookies />} /> */}
        {/* <Route path={jurisdictionRoute} element={<Jurisdiction />} /> */}
        {/* <Route path={privacyRoute} element={<PrivacyPolicy />} /> */}
        {/* <Route path={termsRoute} element={<TermsAndConditions />} /> */}
      </Route>

      <Route element={<ProtectedRouteBuyer />}>
        <Route path={buyerRoute.portal} element={<BuyerPortal />} />
        <Route path={buyerRoute.dashboard} element={<Navigate to={buyerRoute.portal} />} />
        <Route path={buyerRoute.listingDetails} element={<ListingDetails />} />
        <Route path={buyerRoute.orderDetails} element={<ListingDetails />} />
        <Route path={buyerRoute.listings} element={<Listings />} />
        <Route path={buyerRoute.orders} element={<MyListings />} />
        <Route path={buyerRoute.contracts} element={<Contracts />} />
        <Route path={buyerRoute.bids} element={<BiddingBasket />} />
        <Route path={buyerRoute.contractDetails} element={<ContractDetails />} />
        <Route path={buyerRoute.createProduct} element={<Product />} />
        <Route path={buyerRoute.updateProduct} element={<EditProduct />} />
        <Route path={buyerRoute.reviewListing} element={<ReviewListing />} />
        <Route path={buyerRoute.declaration} element={<DeclarationOfferCreation />} />
      </Route>

      <Route element={<ProtectedRouteSeller />}>
        <Route path={sellerRoute.portal} element={<SellerPortal />} />
        <Route path={sellerRoute.dashboard} element={<Navigate to={sellerRoute.portal} />} />
        <Route path={sellerRoute.listingDetails} element={<ListingDetails />} />
        <Route path={sellerRoute.offerDetails} element={<ListingDetails />} />
        <Route path={sellerRoute.listings} element={<Listings />} />
        <Route path={sellerRoute.offers} element={<MyListings />} />
        <Route path={sellerRoute.contracts} element={<Contracts />} />
        <Route path={sellerRoute.bids} element={<BiddingBasket />} />
        <Route path={sellerRoute.contractDetails} element={<ContractDetails />} />
        <Route path={sellerRoute.createProduct} element={<Product />} />
        <Route path={sellerRoute.updateProduct} element={<EditProduct />} />
        <Route path={sellerRoute.reviewListing} element={<ReviewListing />} />
        <Route path={sellerRoute.foodSafety} element={<FoodSafetyOfferCreation />} />
        <Route path={sellerRoute.declaration} element={<DeclarationOfferCreation />} />
      </Route>

      <Route element={<ProtectedRoutePartner />}>
        <Route path={affiliatePartnerRoute.profile} element={<AffiliatePartnerProfile />} />
        <Route path={affiliatePartnerRoute.portal} element={<AffiliatePartnerPortal />} />
        <Route path={affiliatePartnerRoute.inviteBuyer} element={<InviteBuyer />} />
        <Route path={affiliatePartnerRoute.invitationSuccess} element={<InvitationSuccess />} />
      </Route>

      <Route element={<ProtectedRouteSubscriptionMember />}>
        <Route path={subscriptionMemberRoute.portal} element={<SubscriptionMemberPortal />} />
        <Route path={subscriptionMemberRoute.dashboard} element={<Navigate to={subscriptionMemberRoute.portal} />} />
        <Route path={subscriptionMemberRoute.listingDetails} element={<ListingDetails />} />
        <Route path={subscriptionMemberRoute.orderDetails} element={<ListingDetails />} />
        <Route path={subscriptionMemberRoute.listings} element={<Listings />} />
        <Route path={subscriptionMemberRoute.contractDetails} element={<ContractDetails />} />
      </Route>

      <Route element={<ProtectedRouteAdmin />}>
        <Route path={adminRoute.dashboard} element={<AdminPortal />} />
        <Route path={adminRoute.allUser} element={<AdminPortal />} />
        <Route path={adminRoute.productListings} element={<ProductListing />} />
        <Route
          path={adminRoute.userDetails}
          element={<UserProfilePage key={USER_PROFILE_PAGE_KEY} />}
        />
        <Route path={adminRoute.affiliatePartners} element={<AffiliatePartnerManagement />} />
        <Route
          path={adminRoute.affiliatePartnerDetails}
          element={<UserProfilePage key={AFFILIATE_PARTNER_PROFILE_PAGE_KEY} />}
        />
        <Route path={adminRoute.profile} element={<Navigate to={adminRoute.allUser} />} />
        <Route path={adminRoute.contracts} element={<ProductListing />} />
        <Route path={adminRoute.contractDetail} element={<AdminContractDetails />} />
        <Route path={adminRoute.adminOfferOderDetail} element={<AdminOfferOderDetails />} />
      </Route>
    </Routes>
  );
}
