import { ReactComponent as Achievement } from './assets/icons/achievement.svg';
import { ReactComponent as Add } from './assets/icons/add.svg';
import { ReactComponent as AddCircle } from './assets/icons/addCircle.svg';
import { ReactComponent as AddOrder } from './assets/icons/add_order.svg';
import { ReactComponent as Adult } from './assets/icons/adult.svg';
import { ReactComponent as Aeroplane } from './assets/icons/aeroplane.svg';
import { ReactComponent as Af360 } from './assets/icons/af_360.svg';
import { ReactComponent as Amex } from './assets/icons/amex.svg';
import { ReactComponent as AmexCorporate } from './assets/icons/amexCorporate.svg';
import { ReactComponent as ApprovalRequired } from './assets/icons/group_alt.svg';
import { ReactComponent as Archive } from './assets/icons/archive.svg';
import { ReactComponent as ArrowBack } from './assets/icons/arrow_back.svg';
import { ReactComponent as ArrowBackAlt } from './assets/icons/arrow_back_alt.svg';
import { ReactComponent as ArrowDown } from './assets/icons/arrowDown.svg';
import { ReactComponent as ArrowDropDown } from './assets/icons/arrow_drop_down.svg';
import { ReactComponent as ArrowForward } from './assets/icons/arrow_forward.svg';
import { ReactComponent as ArrowForwardAlt } from './assets/icons/arrow_forward_alt.svg';
import { ReactComponent as ArrowRight } from './assets/icons/arrow_right.svg';
import { ReactComponent as ArrowUp } from './assets/icons/arrowUp.svg';
import { ReactComponent as AssistanceRequest } from './assets/icons/assistance_request.svg';
import { ReactComponent as AttachFile } from './assets/icons/attach-file.svg';
import { ReactComponent as AutoRenew } from './assets/icons/autorenew.svg';
import { ReactComponent as Baggage } from './assets/icons/baggage.svg';
import { ReactComponent as Battery } from './assets/icons/battery.svg';
import { ReactComponent as Bike } from './assets/icons/bike.svg';
import { ReactComponent as Boncard } from './assets/icons/boncard.svg';
import { ReactComponent as BookingClass } from './assets/icons/bookingclass.svg';
import { ReactComponent as buyerButton } from './assets/icons/buyerButton.svg';
import { ReactComponent as Calendar } from './assets/icons/calendar.svg';
import { ReactComponent as Call } from './assets/icons/call.svg';
import { ReactComponent as Camera } from './assets/icons/camera.svg';
import { ReactComponent as CameraAlt } from './assets/icons/cameraAlt.svg';
import { ReactComponent as Car } from './assets/icons/car.svg';
import { ReactComponent as CardMembership } from './assets/icons/card_membership.svg';
import { ReactComponent as CaretDown } from './assets/icons/caret_down.svg';
import { ReactComponent as CaretUp } from './assets/icons/caret_up.svg';
import { ReactComponent as CarryOnBag } from './assets/icons/carry_on_bag.svg';
import { ReactComponent as Cash } from './assets/icons/cash.svg';
import { ReactComponent as Chart } from './assets/icons/chart.svg';
import { ReactComponent as ChartLine } from './assets/icons/chart_line.svg';
import { ReactComponent as Chat } from './assets/icons/messaging.svg';
import { ReactComponent as Check } from './assets/icons/check.svg';
import { ReactComponent as CheckCircle } from './assets/icons/check_circle.svg';
import { ReactComponent as CheckCircleIcon } from './assets/icons/check-circle.svg';
import { ReactComponent as CheckCircleOutline } from './assets/icons/check_circle_outline.svg';
import { ReactComponent as ChinaUnionPay } from './assets/icons/china_union_pay.svg';
import { ReactComponent as ClockEnd } from './assets/icons/clock_end.svg';
import { ReactComponent as ClockStart } from './assets/icons/clock_start.svg';
import { ReactComponent as Close } from './assets/icons/close.svg';
import { ReactComponent as CloseCircle } from './assets/icons/close_circle.svg';
import { ReactComponent as CloudUpload } from './assets/icons/cloud_upload.svg';
import { ReactComponent as CompareArrows } from './assets/icons/compare_arrows.svg';
import { ReactComponent as Concardis } from './assets/icons/concardis.svg';
import { ReactComponent as Copy } from './assets/icons/copy.svg';
import { ReactComponent as Content } from './assets/icons/content.svg';
import { ReactComponent as Contracts } from './assets/icons/listings.svg';
import { ReactComponent as Cruise } from './assets/icons/cruise.svg';
import { ReactComponent as CreditCard } from './assets/icons/creditCard.svg';
import { ReactComponent as CustomerInterest } from './assets/icons/customer_interest.svg';
import { ReactComponent as Dankort } from './assets/icons/dankort.svg';
import { ReactComponent as Dashboard } from './assets/icons/dashboard.svg';
import { ReactComponent as DayOrNight } from './assets/icons/dayornight.svg';
import { ReactComponent as Delete } from './assets/icons/delete.svg';
import { ReactComponent as DeleteForever } from './assets/icons/delete_forever.svg';
import { ReactComponent as DeleteLine } from './assets/icons/deleteLine.svg';
import { ReactComponent as DinersClub } from './assets/icons/dinersClub.svg';
import { ReactComponent as DinersClubCorporate } from './assets/icons/dinersClubCorporate.svg';
import { ReactComponent as Discover } from './assets/icons/discover.svg';
import { ReactComponent as DiscoverCorporate } from './assets/icons/discoverCorporate.svg';
import { ReactComponent as Done } from './assets/icons/done.svg';
import { ReactComponent as DownloadFile } from './assets/icons/download_file.svg';
import { ReactComponent as DoubleCheck } from './assets/icons/doubleCheck.svg';
import { ReactComponent as Download } from './assets/icons/download.svg';
import { ReactComponent as Duration } from './assets/icons/duration.svg';
import { ReactComponent as Edit } from './assets/icons/edit.svg';
import { ReactComponent as EditSquareBox } from './assets/icons/editSquareBox.svg';
import { ReactComponent as Elo } from './assets/icons/elo.svg';
import { ReactComponent as Email } from './assets/icons/email.svg';
import { ReactComponent as EmptyChat } from './assets/icons/empty-chat.svg';
import { ReactComponent as Entertainment } from './assets/icons/entertainment.svg';
import { ReactComponent as Equipment } from './assets/icons/equipment.svg';
import { ReactComponent as ETicket } from './assets/icons/eTicket.svg';
import { ReactComponent as Event } from './assets/icons/event.svg';
import { ReactComponent as Events } from './assets/icons/events.svg';
import { ReactComponent as ExitFullScreen } from './assets/icons/fullscreen_exit.svg';
import { ReactComponent as ExternalLink } from './assets/icons/external_link.svg';
import { ReactComponent as FacebookRectangular } from './assets/icons/facebookRectangular.svg';
import { ReactComponent as faceboolCircle } from './assets/icons/faceboolCircle.svg';
import { ReactComponent as FareBrandIcon } from './assets/icons/farebrand.svg';
import { ReactComponent as FareTypeIcon } from './assets/icons/faretype.svg';
import { ReactComponent as Filter } from './assets/icons/filter.svg';
import { ReactComponent as Financial } from './assets/icons/financial.svg';
import { ReactComponent as FullScreen } from './assets/icons/fullscreen.svg';
import { ReactComponent as GasStation } from './assets/icons/gas_station.svg';
import { ReactComponent as Globe } from './assets/icons/globe.svg';
import { ReactComponent as HamburgerMenuLarge } from './assets/icons/hamburgerMenuLarge.svg';
import { ReactComponent as HandLuggage } from './assets/icons/hand_luggage.svg';
import { ReactComponent as HeadsetMic } from './assets/icons/headset_mic.svg';
import { ReactComponent as Heart } from './assets/icons/heart.svg';
import { ReactComponent as HelpOutline } from './assets/icons/help_outline.svg';
import { ReactComponent as HighPriority } from './assets/icons/high_priority.svg';
import { ReactComponent as History } from './assets/icons/history.svg';
import { ReactComponent as Home } from './assets/icons/home.svg';
import { ReactComponent as HorizontalDots } from './assets/icons/more_horiz.svg';
import { ReactComponent as HotelRoom } from './assets/icons/hotel_room.svg';
import { ReactComponent as HourglassEmpty } from './assets/icons/hourglass_empty.svg';
import { ReactComponent as Iata } from './assets/icons/iata.svg';
import { ReactComponent as IconDownChevron } from './assets/icons/icon_down_chevron.svg';
import { ReactComponent as IconUpChevron } from './assets/icons/icon_up_chevron.svg';
import { ReactComponent as Info } from './assets/icons/info.svg';
import { ReactComponent as InstagramRectangular } from './assets/icons/instagramRectangular.svg';
import { ReactComponent as Invalid } from './assets/icons/invalid.svg';
import { ReactComponent as InvalidOutline } from './assets/icons/invalid_outline.svg';
import { ReactComponent as Invoice } from './assets/icons/invoice.svg';
import { ReactComponent as InvoiceWord } from './assets/icons/invoice_word.svg';
import { ReactComponent as Jcb } from './assets/icons/jcb.svg';
import { ReactComponent as KeyLine } from './assets/icons/key-line.svg';
import { ReactComponent as LinkedinRectangular } from './assets/icons/linkedinRectangular.svg';
import { ReactComponent as ListBulleted } from './assets/icons/list_bulleted.svg';
import { ReactComponent as ListNumbered } from './assets/icons/list_numbered.svg';
import { ReactComponent as LiveTv } from './assets/icons/liveTv.svg';
import { ReactComponent as Loading } from './assets/icons/loading.svg';
import { ReactComponent as Location } from './assets/icons/location.svg';
import { ReactComponent as Logout } from './assets/icons/logout.svg';
import { ReactComponent as Lounge } from './assets/icons/flight_lounge.svg';
import { ReactComponent as LunchCheck } from './assets/icons/lunch_check.svg';
import { ReactComponent as Machines } from './assets/icons/machines.svg';
import { ReactComponent as Map } from './assets/icons/map.svg';
import { ReactComponent as MapMarker } from './assets/icons/mapMarker.svg';
import { ReactComponent as Mastercard } from './assets/icons/mastercard.svg';
import { ReactComponent as MastercardBancontact } from './assets/icons/mastercard_bancontact.svg';
import { ReactComponent as MastercardCorporate } from './assets/icons/mastercardCorporate.svg';
import { ReactComponent as MastercardMaestro } from './assets/icons/mastercard_maestro.svg';
import { ReactComponent as Mail } from './assets/icons/mail.svg';
import { ReactComponent as MailIcon } from './assets/icons/mail-icon.svg';
import { ReactComponent as Meal } from './assets/icons/meal.svg';
import { ReactComponent as MealNo } from './assets/icons/meal_no.svg';
import { ReactComponent as MealSSR } from './assets/icons/meal_ssr.svg';
import { ReactComponent as Menu } from './assets/icons/menu.svg';
import { ReactComponent as Message } from './assets/icons/msg.svg';
import { ReactComponent as MSM } from './assets/icons/msm-logo.svg';
import { ReactComponent as Minimize } from './assets/icons/minimize.svg';
import { ReactComponent as MoreModule } from './assets/icons/more_module.svg';
import { ReactComponent as MultipleFOP } from './assets/icons/multipleFOP.svg';
import { ReactComponent as Neutral } from './assets/icons/neutral_icon.svg';
import { ReactComponent as NeutralOutline } from './assets/icons/neutral_outline.svg';
import { ReactComponent as Newspaper } from './assets/icons/newspaper.svg';
import { ReactComponent as NewTask } from './assets/icons/new_task.svg';
import { ReactComponent as NoCarryOnBag } from './assets/icons/no_carry_on_bag.svg';
import { ReactComponent as NoHandLuggage } from './assets/icons/no_hand_luggage.svg';
import { ReactComponent as Notification } from './assets/icons/notification.svg';
import { ReactComponent as NoWifi } from './assets/icons/wifi_no.svg';
import { ReactComponent as Order } from './assets/icons/order.svg';
import { ReactComponent as Orders } from './assets/icons/orders.svg';
import { ReactComponent as Organization } from './assets/icons/organization.svg';
import { ReactComponent as OutOfPolicy } from './assets/icons/insurance.svg';
import { ReactComponent as OverdueTasks } from './assets/icons/overdue_tasks.svg';
import { ReactComponent as OverflowIcon } from './assets/icons/more.svg';
import { ReactComponent as Pdf } from './assets/icons/pdf.svg';
import { ReactComponent as People } from './assets/icons/people.svg';
import { ReactComponent as Person } from './assets/icons/user.svg';
import { ReactComponent as PersonLine } from './assets/icons/person_line.svg';
import { ReactComponent as Photo } from './assets/icons/photo.svg';
import { ReactComponent as PinterestRectangular } from './assets/icons/pinterestRectangular.svg';
import { ReactComponent as Plan } from './assets/icons/plan.svg';
import { ReactComponent as Plane } from './assets/icons/plane.svg';
import { ReactComponent as PointOfContact } from './assets/icons/pointOfContact.svg';
import { ReactComponent as Policy } from './assets/icons/policy.svg';
import { ReactComponent as PostFinance } from './assets/icons/postfinance.svg';
import { ReactComponent as Power } from './assets/icons/power.svg';
import { ReactComponent as PowerOff } from './assets/icons/powerOff.svg';
import { ReactComponent as Preference } from './assets/icons/preference.svg';
import { ReactComponent as Punctuality } from './assets/icons/punctuality.svg';
import { ReactComponent as QueueManagement } from './assets/icons/queue_management.svg';
import { ReactComponent as QueuePlacement } from './assets/icons/queue_placement.svg';
import { ReactComponent as Quote } from './assets/icons/quote-01.svg';
import { ReactComponent as Receipt } from './assets/icons/receipt.svg';
import { ReactComponent as Refresh } from './assets/icons/refresh.svg';
import { ReactComponent as Reka } from './assets/icons/reka.svg';
import { ReactComponent as Remarks } from './assets/icons/remarks.svg';
import { ReactComponent as RemarksAccounting } from './assets/icons/remarks_accounting.svg';
import { ReactComponent as Remove } from './assets/icons/remove.svg';
import { ReactComponent as RemoveBaggage } from './assets/icons/remove_baggage.svg';
import { ReactComponent as RemoveFromCart } from './assets/icons/remove_from_cart.svg';
import { ReactComponent as Reply } from './assets/icons/reply.svg';
import { ReactComponent as Review } from './assets/icons/review.svg';
import { ReactComponent as Robot } from './assets/icons/robot.svg';
import { ReactComponent as Rupay } from './assets/icons/rupay.svg';
import { ReactComponent as ScheduledTasks } from './assets/icons/scheduled_tasks.svg';
import { ReactComponent as Search } from './assets/icons/search.svg';
import { ReactComponent as SearchMore } from './assets/icons/search_more.svg';
import { ReactComponent as Seat } from './assets/icons/airline_seat.svg';
import { ReactComponent as SellerButton } from './assets/icons/sellerButton.svg';
import { ReactComponent as SecureLock } from './assets/icons/secure_lock.svg';
import { ReactComponent as Send } from './assets/icons/send.svg';
import { ReactComponent as SendMessage } from './assets/icons/send_message.svg';
import { ReactComponent as Settings } from './assets/icons/settings.svg';
import { ReactComponent as Share } from './assets/icons/share.svg';
import { ReactComponent as ShoppingCart } from './assets/icons/shopping_cart.svg';
import { ReactComponent as Show } from './assets/icons/show.svg';
import { ReactComponent as ShowChat } from './assets/icons/chat.svg';
import { ReactComponent as SinglePolicy } from './assets/icons/singlePolicy.svg';
import { ReactComponent as Skype } from './assets/icons/skype.svg';
import { ReactComponent as Star } from './assets/icons/star.svg';
import { ReactComponent as StarHalf } from './assets/icons/starHalf.svg';
import { ReactComponent as StickyNote } from './assets/icons/sticky_note.svg';
import { ReactComponent as StopOver } from './assets/icons/stopover.svg';
import { ReactComponent as Subtract } from './assets/icons/subtract.svg';
import { ReactComponent as Tablet } from './assets/icons/tablet.svg';
import { ReactComponent as Tag } from './assets/icons/tag.svg';
import { ReactComponent as TakeOff } from './assets/icons/flight_takeoff.svg';
import { ReactComponent as Tasks } from './assets/icons/tasks.svg';
import { ReactComponent as Time } from './assets/icons/time.svg';
import { ReactComponent as Today } from './assets/icons/today.svg';
import { ReactComponent as Transfer } from './assets/icons/transfer.svg';
import { ReactComponent as Twint } from './assets/icons/twint.svg';
import { ReactComponent as TwitterRectangular } from './assets/icons/twitterRectangular.svg';
import { ReactComponent as Uatp } from './assets/icons/uatp.svg';
import { ReactComponent as Unarchive } from './assets/icons/unarchive.svg';
import { ReactComponent as Users } from './assets/icons/users.svg';
import { ReactComponent as UploadFile } from './assets/icons/upload_file.svg';
import { ReactComponent as Visa } from './assets/icons/visa.svg';
import { ReactComponent as VisaCB } from './assets/icons/visa_cb.svg';
import { ReactComponent as VisaCorporate } from './assets/icons/visaCorporate.svg';
import { ReactComponent as VisaElectron } from './assets/icons/visaElectron.svg';
import { ReactComponent as VisaVPay } from './assets/icons/visa_v_pay.svg';
import { ReactComponent as Voucher } from './assets/icons/voucher.svg';
import { ReactComponent as Warehouse } from './assets/icons/warehouse.svg';
import { ReactComponent as Warning } from './assets/icons/warning.svg';
import { ReactComponent as Web } from './assets/icons/web.svg';
import { ReactComponent as Welltravel } from './assets/icons/welltravel.svg';
import { ReactComponent as WelltravelInverted } from './assets/icons/welltravel_invert.svg';
import { ReactComponent as Widgets } from './assets/icons/widgets.svg';
import { ReactComponent as Wifi } from './assets/icons/wifi.svg';
import { ReactComponent as YoutubeRectangular } from './assets/icons/youtubeRectangular.svg';
import { ReactComponent as ViewDetails } from './assets/icons/view_details.svg';
import { ReactComponent as tradingIcon } from './assets/icons/trading-icon.svg';

const registeredIcon = {
  achievement: Achievement,
  add: Add,
  addCircle: AddCircle,
  addOrder: AddOrder,
  adult: Adult,
  aeroplane: Aeroplane,
  af360: Af360,
  amex: Amex,
  amexCorporate: AmexCorporate,
  approvalRequired: ApprovalRequired,
  archive: Archive,
  arrowBack: ArrowBack,
  arrowBackAlt: ArrowBackAlt,
  arrowDown: ArrowDown,
  arrowDropDown: ArrowDropDown,
  arrowForward: ArrowForward,
  arrowForwardAlt: ArrowForwardAlt,
  arrowRight: ArrowRight,
  arrowUp: ArrowUp,
  assistance_request: AssistanceRequest,
  attachFile: AttachFile,
  autoRenew: AutoRenew,
  baggage: Baggage,
  battery: Battery,
  bike: Bike,
  boncard: Boncard,
  bookingClass: BookingClass,
  buyer: buyerButton,
  calendar: Calendar,
  call: Call,
  camera: Camera,
  cameraAlt: CameraAlt,
  car: Car,
  cardMembership: CardMembership,
  caretDown: CaretDown,
  caretUp: CaretUp,
  carryOnBag: CarryOnBag,
  cash: Cash,
  chart: Chart,
  chartLine: ChartLine,
  chat: Chat,
  check: Check,
  checkCircle: CheckCircle,
  checkCircleIcon: CheckCircleIcon,
  checkCircleOutline: CheckCircleOutline,
  chinaUnionPay: ChinaUnionPay,
  clockEnd: ClockEnd,
  clockStart: ClockStart,
  close: Close,
  closeCircle: CloseCircle,
  cloudUpload: CloudUpload,
  compareArrows: CompareArrows,
  concardis: Concardis,
  copy: Copy,
  content: Content,
  listings: Contracts,
  creditCard: CreditCard,
  cruise: Cruise,
  customerInterest: CustomerInterest,
  dankort: Dankort,
  dashboard: Dashboard,
  dayOrNight: DayOrNight,
  delete: Delete,
  deleteForever: DeleteForever,
  deleteLine: DeleteLine,
  dinersClub: DinersClub,
  dinersClubCorporate: DinersClubCorporate,
  discover: Discover,
  discoverCorporate: DiscoverCorporate,
  done: Done,
  doubleCheck: DoubleCheck,
  download: Download,
  downloadFile: DownloadFile,
  duration: Duration,
  edit: Edit,
  editSquareBox: EditSquareBox,
  elo: Elo,
  email: Email,
  emptyChat: EmptyChat,
  entertainment: Entertainment,
  equipment: Equipment,
  eTicket: ETicket,
  event: Event,
  events: Events,
  exitFullScreen: ExitFullScreen,
  externalLink: ExternalLink,
  facebookRectangular: FacebookRectangular,
  faceboolCircle: faceboolCircle,
  fareBrandIcon: FareBrandIcon,
  fareTypeIcon: FareTypeIcon,
  filter: Filter,
  financial: Financial,
  fullScreen: FullScreen,
  gasStation: GasStation,
  globe: Globe,
  hamburgerMenu: Menu,
  hamburgerMenuLarge: HamburgerMenuLarge,
  handLuggage: HandLuggage,
  headsetMic: HeadsetMic,
  heart: Heart,
  help: HelpOutline,
  highPriority: HighPriority,
  history: History,
  home: Home,
  horizontalDots: HorizontalDots,
  hotelRoom: HotelRoom,
  hourglassEmpty: HourglassEmpty,
  iata: Iata,
  iconDownChevron: IconDownChevron,
  iconUpChevron: IconUpChevron,
  info: Info,
  instagramRectangular: InstagramRectangular,
  invalid: Invalid,
  invalidOutline: InvalidOutline,
  invoice: Invoice,
  invoiceWord: InvoiceWord,
  jcb: Jcb,
  keyLine: KeyLine,
  linkedinRectangular: LinkedinRectangular,
  listBulleted: ListBulleted,
  listNumbered: ListNumbered,
  liveTv: LiveTv,
  loading: Loading,
  location: Location,
  logout: Logout,
  lounge: Lounge,
  lunchCheck: LunchCheck,
  machines: Machines,
  map: Map,
  mapMarker: MapMarker,
  mastercard: Mastercard,
  mastercardBancontact: MastercardBancontact,
  mastercardCorporate: MastercardCorporate,
  mastercardMaestro: MastercardMaestro,
  mail: Mail,
  mailIcon: MailIcon,
  meal: Meal,
  mealSsr: MealSSR,
  message: Message,
  minimize: Minimize,
  more: OverflowIcon,
  moreModule: MoreModule,
  msm: MSM,
  multipleFOP: MultipleFOP,
  neutral: Neutral,
  neutralOutline: NeutralOutline,
  newspaper: Newspaper,
  newTask: NewTask,
  noBaggage: RemoveBaggage,
  noCarryOnBag: NoCarryOnBag,
  noHandLuggage: NoHandLuggage,
  noMeal: MealNo,
  notification: Notification,
  noWifi: NoWifi,
  order: Order,
  orders: Orders,
  organization: Organization,
  outOfPolicy: OutOfPolicy,
  overdueTasks: OverdueTasks,
  pdf: Pdf,
  people: People,
  person: Person,
  personLine: PersonLine,
  photo: Photo,
  pinterestRectangular: PinterestRectangular,
  plan: Plan,
  plane: Plane,
  pointOfContact: PointOfContact,
  policy: Policy,
  postFinance: PostFinance,
  power: Power,
  powerOff: PowerOff,
  preference: Preference,
  punctuality: Punctuality,
  quote: Quote,
  queueManagement: QueueManagement,
  queuePlacement: QueuePlacement,
  receipt: Receipt,
  refresh: Refresh,
  reka: Reka,
  remarks: Remarks,
  remarksAccounting: RemarksAccounting,
  remove: Remove,
  removeFromCart: RemoveFromCart,
  reply: Reply,
  review: Review,
  robot: Robot,
  rupay: Rupay,
  scheduledTasks: ScheduledTasks,
  search: Search,
  searchMore: SearchMore,
  seat: Seat,
  seller: SellerButton,
  secureLock: SecureLock,
  send: Send,
  sendMessage: SendMessage,
  settings: Settings,
  share: Share,
  shoppingCart: ShoppingCart,
  show: Show,
  showChat: ShowChat,
  singlePolicy: SinglePolicy,
  skype: Skype,
  star: Star,
  starHalf: StarHalf,
  stickyNote: StickyNote,
  stopOver: StopOver,
  subtract: Subtract,
  tablet: Tablet,
  tag: Tag,
  takeOff: TakeOff,
  tasks: Tasks,
  time: Time,
  today: Today,
  transfer: Transfer,
  twint: Twint,
  twitterRectangular: TwitterRectangular,
  uatp: Uatp,
  unarchive: Unarchive,
  users: Users,
  visa: Visa,
  visaCB: VisaCB,
  visaCorporate: VisaCorporate,
  visaElectron: VisaElectron,
  visaVPay: VisaVPay,
  voucher: Voucher,
  warehouse: Warehouse,
  warning: Warning,
  web: Web,
  welltravel: Welltravel,
  welltravelInverted: WelltravelInverted,
  widgets: Widgets,
  wifi: Wifi,
  youtubeRectangular: YoutubeRectangular,
  uploadFile: UploadFile,
  viewdetails: ViewDetails,
  tradingIcon: tradingIcon,
};

export default registeredIcon;
