import { useGetCompanyQuery } from 'api/companies';
import { useGetCurrentUserQuery, useGetUserQuery } from 'api/users';
import { getEditStatus } from 'utils/helper/Portals';
import {
  isVerified,
  userRequestedVerification,
} from 'utils/helper/UserRoleValidation';

const useUserManagement = (userId) => {
  const { data: admin = {} } = useGetCurrentUserQuery();
  const { data: userDetails = {} } = useGetUserQuery({ id: userId });

  const { company_id: companyId, role: userType } = userDetails;

  const { data: companyDetails = {}, isSuccess: companyDataFetched } = useGetCompanyQuery(
    { id: companyId },
    { skip: !companyId },
  );

  const { id: adminId } = admin;
  const { additional_documents: additionalDocuments } = companyDetails;

  const isAdmin = !!adminId;
  const isUserVerified = isVerified(companyDetails);
  const hasUserRequestedVerification = userRequestedVerification(companyDetails);
  const canEditForm = getEditStatus(isAdmin, isUserVerified, hasUserRequestedVerification);
  const showApproveButtons = companyDataFetched && hasUserRequestedVerification;

  return {
    isAdmin,
    isUserVerified,
    adminId,
    companyId,
    companyDetails,
    userDetails,
    additionalDocuments,
    canEditForm,
    showApproveButtons,
    userType,
  };
};

export default useUserManagement;
