import React, { useContext } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import ListingNotification from 'components/BiddingProcess/ListingNotification';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import UnPublishOption from 'components/BiddingProcess/ListingDetails/ListingDetailsBreadCrumb/UnPublishOption';
import { prepareContractInfoData } from 'components/BiddingProcess/AdminContractDetails/ContractHeaderDisplay/prepareContractInfoData';
import './styles.scss';

const ContractHeaderDisplay = () => {
  const {
    contractInformation,
    listingInformation,
    isUserAdmin,
    isOrderListing,
    isOfferListing,
    isListingAccepted,
    isListingInContract,
    foodSafetyInformation,
  } = useContext(ListingDetailsContext);

  const dateTimeModifier = (date) =>
    moment.parseZone(date).locale('en').format('HH:mm, DD MMM YYYY');

  const shouldShowBuyerName = isOrderListing || isListingInContract;
  const shouldShowSellerName = isOfferListing || isListingInContract;

  const getFullName = (user) => user && `${user.firstName} ${user.lastName}`;
  const buyerName = isListingAccepted
    ? getFullName(contractInformation?.buyer)
    : getFullName(listingInformation.creator);
  const sellerName = isListingAccepted
    ? getFullName(contractInformation?.seller)
    : getFullName(listingInformation.creator);
  const listingCreatorName = getFullName(listingInformation.creator);

  const contractInformationData = prepareContractInfoData(
    isListingInContract,
    listingInformation,
    shouldShowSellerName,
    sellerName,
    shouldShowBuyerName,
    buyerName,
    dateTimeModifier,
  );

  const isListingPublished = listingInformation?.status === 'published';
  const hasNoBidYet = listingInformation?.highestBidPrice === null;
  const showUnPublishOption = isListingPublished && (isOrderListing || isOfferListing) && hasNoBidYet;

  let title;

  if (isListingInContract) {
    title = t('bidding_process.contract_id_label', {
      contractId: contractInformation.serialNumber,
    });
  } else if (isOfferListing) {
    title = t('bidding_process.offer_id_label', {
      offerId: listingInformation.serialNumber,
    });
  } else {
    title = t('bidding_process.order_id_label', {
      orderId: listingInformation.serialNumber,
    });
  }

  const contractValidityDate = dateTimeModifier(contractInformation.concludedUntil);
  const isFoodSafetyNotificationEnabled =
    !foodSafetyInformation.id && !isListingInContract && !isOrderListing && isUserAdmin;
  const isContractSignNotificationEnabled =
    isListingInContract && !listingInformation?.aggregatedData;

  return (
    <div className="contract-details-header">
      <div className='flex contract-details-header-title-and-button'>
        <div className="contract-details-header-title">{title}</div>
        <div>
          {showUnPublishOption && <UnPublishOption />}
        </div>
      </div>
      <div className="contract-details-header-information">
        {contractInformationData.map(({ label, value, className }) => (
          <div key={label}>
            {label}
            <span className={`contract-details-header-information__value ${className}`}>
              {value}
            </span>
          </div>
        ))}
      </div>

      {isContractSignNotificationEnabled && (
        <ListingNotification
          notificationHeader={t('admin.contract_details.contract_is_not_signed_yet', {
            contractValidityDate,
          })}
        />
      )}

      {isFoodSafetyNotificationEnabled && (
        <ListingNotification
          notificationHeader={t('admin.contract_details.no_food_safety_notification_header')}
          notificationSubHeader={t('admin.contract_details.no_food_safety_notification_subheader', {
            listingCreatorName,
          })}
        />
      )}
    </div>
  );
};

export default ContractHeaderDisplay;
