import React, { useContext } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { formatVolume } from 'utils/helper/Charts';
import { formatDate, getYearFromDate } from 'utils/helper/FormatDate';
import { countryCodeToName } from 'utils/helper/selectBoxUtils';
import capitalizeFirstLetter from 'utils/helper/capitalizeFirstLetter';
import DetailsInformationCard from 'components/Shared/DetailsInformationCard';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import { BRC_CERT, KERNEL_PRODUCT } from 'utils/constants/product';
import './styles.scss';

const ConsignmentDetails = ({ productDetails, logisticsDetails }) => {
  const { isListingCreator } = useContext(ListingDetailsContext);

  const {
    nutStyle,
    nutGrade,
    seasonHarvested,
    seasonProcessed,
    countryOfOrigin,
    regionOfOrigin,
    bestBeforeDate,
    minimumFoodCertificate,
    brcLevel,
    offerVolume,
    boxesQuantity,
    size,
    treeVariant,
    soundKernelRecovery,
    unsoundKernelRecovery,
    type,
    halaal,
    kosher,
  } = productDetails;

  const { containerLoad, estimatedShippingDate, consignmentIdentifier, paymentOptions } =
    logisticsDetails;

  const productDetailsData = [
    { label: t('bidding_process.nut_style'), data: nutStyle },
    { label: t('bidding_process.nut_grade'), data: capitalizeFirstLetter(nutGrade) },
    {
      label: t('bidding_process.season_harvested'),
      data: seasonHarvested ? getYearFromDate(seasonHarvested) : null,
    },
    {
      label: t('bidding_process.season_processed'),
      data: seasonProcessed ? getYearFromDate(seasonProcessed) : null,
    },
    { label: t('bidding_process.origin_country'), data: countryCodeToName(countryOfOrigin) },
    { label: t('bidding_process.origin_region'), data: regionOfOrigin },
    { label: t('bidding_process.best_before'), data: formatDate(bestBeforeDate) },
    {
      label: t('bidding_process.minimum_food_certificate'),
      data: minimumFoodCertificate === BRC_CERT && brcLevel
        ? `${minimumFoodCertificate?.toUpperCase()} (${t(`shared.product.brc_levels.${brcLevel}`)})`
        : minimumFoodCertificate?.toUpperCase()
    },
    { label: t('bidding_process.total_quantity'), data: `${formatVolume(offerVolume)} kg` },
    (halaal || kosher) ? {
      label: t('bidding_process.religion'),
      data: [
        (halaal && t('shared.product.religion.halaal')),
        (kosher && t('shared.product.religion.kosher'))
      ].filter(e => e).join(', '),
    } : {},
    boxesQuantity ? { label: t('bidding_process.boxes_quantity'), data: `${boxesQuantity}`} : {},
  ];

  const dnisProductDetailsData = [
    { label: t('bidding_process.nut_size'), data: size },
    { label: t('bidding_process.nut_grade'), data: capitalizeFirstLetter(nutGrade) },
    {
      label: t('bidding_process.season_harvested'),
      data: seasonHarvested ? getYearFromDate(seasonHarvested) : null,
    },
    { label: t('bidding_process.tree_variant'), data: treeVariant },
    { label: t('bidding_process.sound_kernel_recovery'), data: soundKernelRecovery },
    { label: t('bidding_process.unsound_kernel_recovery'), data: unsoundKernelRecovery },
    { label: t('bidding_process.origin_country'), data: countryCodeToName(countryOfOrigin) },
    { label: t('bidding_process.origin_region'), data: regionOfOrigin },
    { label: t('bidding_process.best_before'), data: formatDate(bestBeforeDate) },
    { label: t('bidding_process.total_quantity'), data: `${formatVolume(offerVolume)} kg` },
    boxesQuantity ? { label: t('bidding_process.boxes_quantity'), data: `${boxesQuantity}`} : {},
  ];

  const canViewConsignmentIdentifier = consignmentIdentifier || isListingCreator;

  const logisticsDetailsData = [
    { label: t('bidding_process.container_load'), data: containerLoad?.toUpperCase() },
    {
      label: t('bidding_process.estimated_shipping_date'),
      data: estimatedShippingDate ? formatDate(estimatedShippingDate) : null,
    },
  ];

  if (canViewConsignmentIdentifier) {
    logisticsDetailsData.push({
      label: t('bidding_process.unique_consignment_identifier'),
      data: consignmentIdentifier,
    });
  }

  logisticsDetailsData.push({
    label: t('bidding_process.payment_options'),
    data: paymentOptions?.toUpperCase(),
  });

  return (
    <div className="consignment-details">
      <div className="consignment-details__title">{t('bidding_process.consignment_details')}</div>
      <DetailsInformationCard
        cardHeader={type === KERNEL_PRODUCT ? t('bidding_process.kernel_product_details') : t('bidding_process.dnis_product_details')}
        detailsData={type === KERNEL_PRODUCT ? productDetailsData : dnisProductDetailsData}
      />
      <DetailsInformationCard
        cardHeader={t('bidding_process.logistics_details')}
        detailsData={logisticsDetailsData}
      />
    </div>
  );
};

ConsignmentDetails.propTypes = {
  productDetails: PropTypes.shape({
    nutStyle: PropTypes.string,
    nutGrade: PropTypes.string,
    seasonHarvested: PropTypes.string,
    seasonProcessed: PropTypes.string,
    countryOfOrigin: PropTypes.string,
    regionOfOrigin: PropTypes.string,
    bestBeforeDate: PropTypes.string,
    minimumFoodCertificate: PropTypes.string,
    brcLevel: PropTypes.string,
    offerVolume: PropTypes.string,
    boxesQuantity: PropTypes.number,
    type: PropTypes.string,
    size: PropTypes.string,
    treeVariant: PropTypes.string,
    soundKernelRecovery: PropTypes.string,
    unsoundKernelRecovery: PropTypes.string,
    halaal: PropTypes.bool,
    kosher: PropTypes.bool,
  }).isRequired,

  logisticsDetails: PropTypes.shape({
    containerLoad: PropTypes.string,
    estimatedShippingDate: PropTypes.string,
    consignmentIdentifier: PropTypes.string,
    paymentOptions: PropTypes.string,
  }).isRequired,
};

export default ConsignmentDetails;
