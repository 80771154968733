import React, { Fragment, useReducer } from 'react';
import { t } from 'i18next';
import { useGetFollowingListingsQuery, useGetLeadingBidListingsQuery, useGetOutbidListingsQuery } from 'api/listings';
import { useGetCurrentUserQuery } from 'api/users';
import { FOLLOWING_TAB, LEADING_BID_TAB, OUTBID_TAB, BIDDING_BASKET_PER_PAGE } from "utils/constants/biddingBasket";
import Pagination from 'components/BaseComponents/Pagination';
import { RTabs } from 'components/BaseComponents/RTabs';
import SelectBox from 'components/BaseComponents/SelectBox';
import PortalsNavbar from 'components/NavBar/PortalsNavBar';
import { getBiddingBasketTabs } from 'utils/helper/biddingBasket';
import { filterOptions } from 'utils/constants/offerListings';
import filterReducer, { HANDLE_PAGE_CHANGE, HANDLE_SORT_CHANGE, HANDLE_TAB_CHANGE, initialFilterState } from './filterReducer';
import './style.scss';

const BiddingBasket = () => {
  const { data: user = {} } = useGetCurrentUserQuery();

  const [filterState, dispatch] = useReducer(filterReducer, initialFilterState);
  const {
    sort: { label, column: sortColumn, direction: sortDirection },
    currentPage, currentTab
  } = filterState;

  const selectedSort = { label, column: sortColumn, direction: sortDirection }

  const {
    data: followingListings = {},
    isLoading: isFollowingListingsLoading,
  } = useGetFollowingListingsQuery({
    currentPage,
    sortColumn,
    sortDirection,
    perPage: BIDDING_BASKET_PER_PAGE,
  });
  const { collection: followingListingsList = [] } = followingListings;

  const {
    data: leadingBidListings = {},
    isLoading: isLeadingBidListingsLoading,
  } = useGetLeadingBidListingsQuery({
    currentPage,
    sortColumn,
    sortDirection,
    perPage: BIDDING_BASKET_PER_PAGE,
  });
  const { collection: leadingBidListingsList = [] } = leadingBidListings;

  const {
    data: outbidListings = {},
    isLoading: isOutbidListingsLoading,
  } = useGetOutbidListingsQuery({
    currentPage,
    sortColumn,
    sortDirection,
    perPage: BIDDING_BASKET_PER_PAGE,
  });
  const { collection: outbidListingsList = [] } = outbidListings;

  const { role: userRole } = user;
  const totalPagesByTab = {
    [FOLLOWING_TAB]: Number(followingListings?.pagination?.totalPages),
    [LEADING_BID_TAB]: Number(leadingBidListings?.pagination?.totalPages),
    [OUTBID_TAB]: Number(outbidListings?.pagination?.totalPages),
  }
  const totalPages = totalPagesByTab[currentTab];
  const followingListingsContractCount = followingListings?.pagination?.totalCount || '0';
  const leadingBidListingsCount = leadingBidListings?.pagination?.totalCount || '0';
  const outbidListingsCount = outbidListings?.pagination?.totalCount || '0';

  const tabs = getBiddingBasketTabs({
    followingListingsContractCount,
    leadingBidListingsCount,
    outbidListingsCount,
    followingListingsList,
    leadingBidListingsList,
    isOutbidListingsLoading,
    isFollowingListingsLoading,
    isLeadingBidListingsLoading,
    outbidListingsList,
    currentTab,
    userRole,
  });

  const title = t(`navbar.seller.links.${userRole}.bids`);

  return (
    <Fragment>
      <PortalsNavbar />
      <div className="listings-wrapper">
        <div className="listings">
          <div className="bidding-basket__sort-filter">
            <h1>{title}</h1>

            <SelectBox
              className="bidding-basket__sort-filter--dropdown"
              options={filterOptions}
              isClearable={false}
              isSearchable={false}
              onChange={(value) => dispatch({ type: HANDLE_SORT_CHANGE, payload: value })}
              value={selectedSort}
            />
          </div>

          <RTabs
            allowRemove={false}
            items={tabs}
            selectedTabKey={currentTab}
            controlled={true}
            onChange={(value) => dispatch({ type: HANDLE_TAB_CHANGE, payload: value })}
          />

          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPaginationClick={(value) => dispatch({ type: HANDLE_PAGE_CHANGE, payload: value })}
              firstText={t('admin_user_table.first_page')}
              lastText={t('admin_user_table.last_page')}
              nextText={t('admin_user_table.next_page')}
              previousText={t('admin_user_table.previous_page')}
              className="admin-portal__table--pagination"
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default BiddingBasket;
