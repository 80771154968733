import i18n from 'i18n';
import {
  DOCUMENT_REQUESTED,
  REQUESTED_VERIFICATION,
  UNVERIFIED,
  VERIFIED,
} from 'utils/constants/userStatus';

const { t } = i18n;

const progressCardTexts = (status, userRole) => {
  switch (status) {
    case UNVERIFIED:
      return {
        heading: t('profile.progress_card.heading_unverified'),
        subheadings: [
          t('profile.progress_card.subheading_unverified_line1'),
          t(`profile.progress_card.${userRole}_subheading_unverified_line2`),
        ],
        hintText: t('profile.progress_card.hint_complete'),
      };
    case REQUESTED_VERIFICATION:
      return {
        heading: t('profile.progress_card.heading_requested_verification'),
        subheadings: [t(`profile.progress_card.${userRole}_subheading_unverified_line2`)],
        hintText: null,
      };
    case DOCUMENT_REQUESTED:
      return {
        heading: t('profile.progress_card.heading_requested_document'),
        subheadings: [
          t('profile.progress_card.subheading_unverified_line1'),
          t(`profile.progress_card.${userRole}_subheading_unverified_line2`),
        ],
        hintText: t('profile.progress_card.hint_complete'),
      };
    case VERIFIED:
      return {
        heading: t('profile.progress_card.heading_verified'),
        subheadings: [t(`profile.progress_card.${userRole}_subheading_verified`)],
        hintText: null,
      };
    default:
      return {
        heading: '',
        subheadings: [],
        hintText: '',
      };
  }
};

export const getProgressCardTexts = (status, userRole) => progressCardTexts(status, userRole);
