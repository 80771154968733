import { useNavigate } from 'react-router-dom';

const useCustomNavigate = () => {
  const navigate = useNavigate();
  const openRootPage = () => navigate('/');
  const registrationSuccessPage = (verificationEmail) =>
    navigate('/registration-success', { state: { verificationEmail } });
  const signupPage = () => navigate('/signup');
  const loginPage = () => navigate('/login');
  const forgotPassword = () => navigate('/forgot-password');
  const resetPasswordMailSentPage = (email) =>
    navigate('/reset-password-check-email', { state: email });
  const resetPasswordSuccess = (token) => navigate('/reset-password-success', { state: token });
  const buyerprofile = () => navigate('/buyer/profile');
  const sellerProfile = () => navigate('/seller/profile');
  const subscriptionMemberProfile = () => navigate('/subscription_member/profile');
  const listings = (role) => navigate(`/${role}/listings`);
  const createProduct = (role) => navigate(`/${role}/create-listing/product-details`);
  const updateProduct = (role, productId) =>
    navigate(`/${role}/create-listing/${productId}/product-details`);
  const reviewListing = (role) => navigate(`/${role}/create-listing/review-listing`);
  const reviewListingById = (role, listingId) =>
    navigate(`/${role}/create-listing/${listingId}/review-listing`);
  const foodSafety = (productId) => navigate(`/seller/create-listing/${productId}/food-safety`);
  const declaration = (role, productId) =>
    navigate(`/${role}/create-listing/${productId}/declaration`);
  const adminProfile = () => navigate('/admin/users');
  const adminListingManagement = () => navigate('/admin/listing');
  const adminAffiliatePartnersManagement = () => navigate('/admin/partners');
  const affiliatePartnerProfile = () => navigate('/partner/profile');
  const affiliatePartnerPortal = () => navigate('/partner/portal');
  const affiliatePartnerRegistrationSuccess = (verificationEmail) =>
    navigate('/partner-registration-success', { state: { verificationEmail } });
  const affiliatePartnerInviteBuyer = () => navigate('/partner/invite-buyer');
  const affiliatePartnerStaticPage = () => navigate('/affiliate-partner');
  const buyerInvitationSuccessPage = (verificationEmail) =>
    navigate('/invitation-success', { state: { verificationEmail } });
  const unauthorizedPage = () => navigate('/unauthorized');
  const adminOfferOrderDetails = (id) => navigate(`/admin/listing/${id}`);
  const adminContractDetails = (contractId) => navigate(`/admin/contracts/${contractId}`);
  const contractDetails = (role, contractId) => navigate(`/${role}/contracts/${contractId}`);
  const myOffers = () => navigate('/seller/offers');
  const myOrders = () => navigate('/buyer/orders');
  const sellerBids = () => navigate('seller/bids')
  const buyerBids = () => navigate('buyer/bids')

  return {
    openRootPage,
    registrationSuccessPage,
    signupPage,
    loginPage,
    forgotPassword,
    resetPasswordMailSentPage,
    resetPasswordSuccess,
    buyerprofile,
    sellerProfile,
    subscriptionMemberProfile,
    listings,
    createProduct,
    updateProduct,
    reviewListing,
    reviewListingById,
    foodSafety,
    declaration,
    adminProfile,
    adminListingManagement,
    affiliatePartnerProfile,
    adminAffiliatePartnersManagement,
    affiliatePartnerRegistrationSuccess,
    affiliatePartnerPortal,
    affiliatePartnerInviteBuyer,
    affiliatePartnerStaticPage,
    buyerInvitationSuccessPage,
    unauthorizedPage,
    adminOfferOrderDetails,
    adminContractDetails,
    contractDetails,
    myOffers,
    myOrders,
    sellerBids,
    buyerBids,
  };
};

export default useCustomNavigate;
