import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { handleServerValidationErrors, validateOptionalPortalDocuments } from 'utils/helper/Validations';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useUpdateAdditionalDocumentsMutation, useUpdateReligionCertificatesMutation } from 'api/companies';
import { generateAdditionalDocumentBody, generateReligionCertificatesFormBody } from 'utils/helper/RequestBody';
import { labelWithHints } from 'utils/helper/LabelWithHints';
import { processPortalFormSuccess } from 'utils/helper/FormHandlers';
import { errorToastConfig } from 'utils/constants/ToastConfig';
import Toastify from 'components/Shared/ToastNotification/Toastify';
import ChooseFile from 'components/Shared/ChooseFile';
import Button from 'components/BaseComponents/Button';
import './style.scss';
import { hasRequestedAdditionalDoc, isMSMSeller } from 'utils/helper/UserRoleValidation';
import Icon from 'components/BaseComponents/Icon';
import { FieldArray } from 'react-final-form-arrays';

function AdditionalDocumentForm({companyId, companyDetails, userDetails, isEditable, toastRef }) {
  const { t } = useTranslation();
  const documentRequested = hasRequestedAdditionalDoc(companyDetails);
  const [removedAdditionalDocumentIds, setRemovedAdditionalDocumentIds] = useState([]);

  const {
    additional_documents: additionalDocuments,
    halaal_certificate: halaalCertificate,
    kosher_certificate: kosherCertificate,
  } = companyDetails;

  const isSeller = isMSMSeller(userDetails);
  const [updateAdditionalDocuments] = useUpdateAdditionalDocumentsMutation();
  const [updateReligionCertificates] = useUpdateReligionCertificatesMutation();

  const handleAdditionalDocumentFormSubmit = (values) => {
    const requestBody = generateAdditionalDocumentBody(values, removedAdditionalDocumentIds);
    return updateAdditionalDocuments({ companyId, payload: requestBody })
      .unwrap()
      .then(() => processPortalFormSuccess(toastRef))
      .catch(({ data: { errors }, status }) =>
        handleServerValidationErrors(toastRef, status, errors),
      );
  };

  const handleAdditionalDocumentRemove = (fields, documentIndex) => {
    const documentId = fields.value[documentIndex]?.id;
    fields.remove(documentIndex);

    if (documentId) {
      setRemovedAdditionalDocumentIds([...removedAdditionalDocumentIds, documentId]);
    }
  };

  const handleReligionCertificatesFormSubmit = (values) => {
    const requestBody = generateReligionCertificatesFormBody(values);

    return updateReligionCertificates({ companyId, payload: requestBody })
      .unwrap()
      .then(() => processPortalFormSuccess(toastRef))
      .catch(({ data: { errors }, status }) =>
        handleServerValidationErrors(toastRef, status, errors),
      );
  };
  const filteredAdditionalDocuments =  additionalDocuments
    .filter((additionalDocument) => !removedAdditionalDocumentIds.includes(additionalDocument?.id))
    .map((additionalDocument) => ({
      id: additionalDocument?.id,
      name: additionalDocument?.filename,
      url: additionalDocument?.url,
    }));
  const additionalDocumentInitialValues = {
    additional_documents: filteredAdditionalDocuments.length > 0 ? filteredAdditionalDocuments : [{}]
  };
  const religionCertificatesInitialValues = {
    halaal_certificate: { name: halaalCertificate?.filename },
    kosher_certificate: { name: kosherCertificate?.filename},
  };

  return (
    <>
      <h3 className="additional-document__title">
        {t('profile.additional_document.heading')}
      </h3>
      <Form
        onSubmit={handleAdditionalDocumentFormSubmit}
        initialValues={additionalDocumentInitialValues}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, submitting, hasValidationErrors }) => (
          <form
            onSubmit={(event) => {
              if (hasValidationErrors) {
                toastRef?.current?.notify(<Toastify type="error" />, errorToastConfig);
              }
              handleSubmit(event);
            }}
            className="additional-document"
          >
            <fieldset disabled={false}>
              <div className="padding-x-24px">
                <h5 className="margin-t-32px margin-b-20px padding-b-12px food-safety__section-header">
                  {labelWithHints(
                    t('profile.additional_document.form_title'),
                    t('shared.incorrect_document_tooltip'),
                  )}
                  {documentRequested && (<span className="food-safety__section-header--required">*</span>)}
                </h5>
                <FieldArray
                  name="additional_documents"
                >
                  {({ fields }) => (
                    <Fragment>
                      {fields.map((name, index) => (
                        <Field
                          name={name}
                          validate={validateOptionalPortalDocuments}
                          key={name}
                        >
                          {({ input, meta }) => (
                            <ChooseFile
                              className="padding-b-32px"
                              fileName={input.value?.name}
                              fileUrl={input.value?.url}
                              label={t('profile.additional_document.fields.additional_document', { num: index + 1})}
                              hint={t('profile.file.upload')}
                              onChange={(file) => {
                                input.onChange(file);
                              }}
                              required={true}
                              touched={meta.touched}
                              errorMessage={meta?.error || meta?.submitError}
                              onDelete={() => handleAdditionalDocumentRemove(fields, index)}
                              isEditable={isEditable || !fields.value[index]?.id}
                            />
                          )}
                        </Field>
                      ))}

                      <Button
                        submitType="button"
                        className="flex items-center gap-8px margin-x-20px padding-x-15pxP"
                        label={t('profile.additional_document.add_document')}
                        icon={<Icon name="add" />}
                        type="default"
                        onClick={() => fields.push({})}
                      />
                    </Fragment>
                  )}
                </FieldArray>

                <Button
                  label={t('profile.food_safety.save_button')}
                  className="additional-document__submit-btn margin-t-24px margin-b-32px"
                  submitType="submit"
                  type="success"
                  disabled={submitting}
                />
              </div>
            </fieldset>
          </form>
        )}
      />
      {isSeller && <Form
        onSubmit={handleReligionCertificatesFormSubmit}
        initialValues={religionCertificatesInitialValues}
        render={({ handleSubmit, submitting, hasValidationErrors }) => (
          <form
            onSubmit={(event) => {
              if (hasValidationErrors) {
                toastRef?.current?.notify(<Toastify type="error" />, errorToastConfig);
              }
              handleSubmit(event);
            }}
            className="additional-document margin-t-20px"
          >
            <fieldset>
              <div className="padding-x-24px">
                <h5 className="margin-t-32px margin-b-20px padding-b-12px food-safety__section-header">
                  {t('profile.additional_document.religion_title')}
                </h5>

                <Field
                  name="halaal_certificate"
                  validate={validateOptionalPortalDocuments}
                >
                  {({ input, meta }) => (
                    <ChooseFile
                      fileName={input.value?.name}
                      fileUrl={halaalCertificate?.url}
                      label={t('profile.additional_document.fields.halaal_certificate')}
                      hint={t('profile.file.upload')}
                      touched={meta?.touched}
                      onChange={(file) => {
                        input.onChange(file);
                      }}
                      errorMessage={meta?.error || meta?.submitError}
                    />
                  )}
                </Field>

                <Field
                  name="kosher_certificate"
                  validate={validateOptionalPortalDocuments}
                >
                  {({ input, meta }) => (
                    <ChooseFile
                      fileName={input.value?.name}
                      fileUrl={kosherCertificate?.url}
                      label={t('profile.additional_document.fields.kosher_certificate')}
                      hint={t('profile.file.upload')}
                      touched={meta?.touched}
                      onChange={(file) => {
                        input.onChange(file);
                      }}
                      errorMessage={meta?.error || meta?.submitError}
                    />
                  )}
                </Field>

                <Button
                  label={t('profile.food_safety.save_button')}
                  className="additional-document__submit-btn margin-t-24px margin-b-32px"
                  submitType="submit"
                  type="success"
                  disabled={submitting}
                />
              </div>
            </fieldset>
          </form>
        )}
      />}
    </>
  );
}

AdditionalDocumentForm.defaultProps = {
  companyId: null,
  isEditable: true,
  userDetails: null,
  companyDetails: null,
  toastRef: null,
};

AdditionalDocumentForm.propTypes = {
  companyId: PropTypes.number,
  isEditable: PropTypes.bool,
  userDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  companyDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  toastRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default AdditionalDocumentForm;
