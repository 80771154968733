import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import EmptyContentPlaceholder from 'components/Shared/EmptyContentPlaceholder';
import Button from 'components/BaseComponents/Button';
import ProductCardWrapper from 'components/BiddingProcess/Listings/ProductCardWrapper';
import ContentLoaderPlaceholder from 'components/BaseComponents/ContentLoader/ContentLoader';
import useCustomNavigate from 'hooks/useCustomNavigate';

const CardsContainer = ({ listings, isLoading, userRole, currentTab }) => {
  const navigate = useCustomNavigate();

  const emptyContentLabel = t('bidding_basket.empty_content_placeholder.heading');
  const emptyContentDescription = t(`bidding_basket.empty_content_placeholder.description.${currentTab}`);
  const emptyContentButtonLabel = t(`bidding_basket.empty_content_placeholder.explore_button.${userRole}`);

  if (isLoading) {
    return (<ContentLoaderPlaceholder numberOfLines={5} />);
  }

  return (
    <div>
      {listings.length > 0 ?
        listings.map(
          ({ productId, totalBidCount, highestBidPrice, serialNumber, status, contract }) => (
            <ProductCardWrapper
              key={productId}
              productId={productId}
              totalBidCount={totalBidCount}
              highestBidPrice={highestBidPrice}
              serialNumber={serialNumber}
              productStatus={status}
              contract={contract}
              userRole={userRole}
            />
          )
        ) : (
          <div className="listings__empty-content">
            <EmptyContentPlaceholder
              heading={emptyContentLabel}
              description={emptyContentDescription}
            />
            <Button
              className="listings__empty-content--create-offer-button"
              label={emptyContentButtonLabel}
              onClick={() => navigate.listings(userRole)}
            />
          </div>
        )
      }
    </div>
  );
};

CardsContainer.defaultProps = {
  listings: [],
  isLoading: true,
};

CardsContainer.propTypes = {
  listings: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.number,
      totalbidCount: PropTypes.number,
    }),
  ),
  isLoading: PropTypes.bool,
  currentTab: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default CardsContainer;
