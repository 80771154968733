import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import Radio from 'components/BaseComponents/CustomRadio';
import Button from 'components/BaseComponents/Button';
import FormFooter from 'components/SignUp/FormFooter';
import { requiredField } from 'utils/helper/Validations';
import './style.scss';

const Step1Form = ({ onFormSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className="signup-form">
      <h2 className="signup-form__title">{t('signup.step1_form.title')}</h2>
      <p className="signup-form__message">{t('signup.step1_form.message')}</p>
      <p className="signup-form__heading">{t('signup.step1_form.card_heading')}</p>
      <Form
        onSubmit={onFormSubmit}
        render={({ handleSubmit, invalid }) => (
          <form onSubmit={handleSubmit} className="flex flex-col gap-12px">
            <Field type="radio" value="1" name="userType" validate={requiredField}>
              {({ input }) => (
                <Radio
                  label={t('signup.step1_form.seller_label')}
                  containerClassName="flex justify-between"
                  iconName="seller"
                  value={input.value}
                  name={input.name}
                  isSelected={input.checked}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Field type="radio" value="0" name="userType" validate={requiredField}>
              {({ input }) => (
                <Radio
                  label={t('signup.step1_form.buyer_label')}
                  name={input.name}
                  iconName="buyer"
                  containerClassName="flex justify-between"
                  value={input.value}
                  isSelected={input.checked}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Field type="radio" value="4" name="userType" validate={requiredField}>
              {({ input }) => (
                <Radio
                  label={t('signup.step1_form.subscription_member_label')}
                  name={input.name}
                  iconName="tradingIcon"
                  containerClassName="flex justify-between"
                  value={input.value}
                  isSelected={input.checked}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Button
              className="signup-form__submit-button"
              label={t('signup.step1_form.submit_button')}
              disabled={invalid}
              type="success"
              submitType="submit"
            />
            <FormFooter />
          </form>
        )}
      />
    </div>
  );
};

Step1Form.defaultProps = {
  onFormSubmit: () => {},
};

Step1Form.propTypes = {
  onFormSubmit: PropTypes.func,
};

export default Step1Form;
