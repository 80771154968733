import React from 'react';
import { t } from 'i18next';
import {
  ADDITIONAL_DOCUMENT_FORM_KEY,
  BUSINESS_CONTACTS_FORM_KEY,
  BUSINESS_DETAILS_FORM_KEY,
  FOOD_SAFETY_FORM_KEY,
  PERSONAL_DETAILS_FORM_KEY,
  COMPANY_PERMISSIONS_FORM_KEY,
  allUsersTabTableHeader,
  appliedTabTableHeader,
  approvedTabTableHeader,
  unapprovedTabTableHeader,
  contractsTabTableHeader,
  offersTableHeader,
  dnisOffersTableHeader,
  offersTab,
  ordersTab,
  contractsTab,
  allUsersTab,
  approvedTab,
  appliedTab,
  documentRequestedTab,
  unapprovedTab,
  onboardedTab,
  invitedTab,
  archivedTab,
  oboardedTabTableHeader,
  invitedTabTableHeader,
  archivedTabTableHeader,
  appliedPartnerTableHeader,
  approvedPartnerTableHeader,
  inProgressTab,
  completedTab,
  concludedTab,
  activeOffersTab,
  inDraftTab,
} from 'utils/constants/portals';
import {
  AFFILIATE_PARTNER_BG_COLOR,
  BUYER_AVATAR_BG_COLOR,
  SELLER_AVATAR_BG_COLOR,
} from 'utils/constants/user';
import { BUYER, AFFILIATE_PARTNER, SELLER, SUBSCRIPTION_MEMBER } from 'utils/constants/userRole';
import { HUNDRED_PERCENT } from 'utils/constants/progressCardDetails';
import PersonalDetailsForm from 'components/Shared/Portals/PersonalDetailsForm';
import BankingDetailsForm from 'components/AffiliatePartner/Profile/Forms/BankingDetailsForm';
import BusinessDetailsForm from 'components/Shared/Portals/BusinessDetailsForm';
import BusinessContacts from 'components/Shared/Portals/BusinessContacts';
import FoodSafetyForm from 'components/Shared/Portals/FoodSafety';
import AdditionalDocumentForm from 'components/Shared/Portals/AdditionalDocument';
import ProductTable from 'components/Portals/Admin/Products/ListingManagementTable/ProductTable';
import UserTable from 'components/Portals/Admin/UserManagement/Table/UserTable';
import OfferCardsContainer from 'components/BiddingProcess/Listings/OfferCardsContainer';
import PartnerTable from 'components/Portals/Admin/AffiliatePartnerManagement/Table/PartnerTable';
import MyAffiliationsTableSkeleton from 'components/AffiliatePartner/AffiliationsTable/Table';
import CompanyPermissionsForm from 'components/Portals/Admin/UserManagement/Profile/CompanyPermissionsForm';
import { KERNEL_PRODUCT } from 'utils/constants/product';

export const getAvatarBgColorCode = (userRole) => {
  if (userRole === SELLER) {
    return SELLER_AVATAR_BG_COLOR;
  }
  if (userRole === BUYER) {
    return BUYER_AVATAR_BG_COLOR;
  }
  return AFFILIATE_PARTNER_BG_COLOR;
};

export const isTabDisabled = (isAdmin, percentage) => !isAdmin && percentage !== HUNDRED_PERCENT;

const getPersonalDetailsTab = ({
  userId,
  isEditable,
  toastRef,
}) => (
  {
    disabled: false,
    getContent: ({ onTabChange }) => (
      <PersonalDetailsForm
        onTabChange={onTabChange}
        userId={userId}
        isEditable={isEditable}
        toastRef={toastRef}
      />
    ),
    key: PERSONAL_DETAILS_FORM_KEY,
    title: t('profile.form_tabs.personal_details'),
  }
);

const getBusinessDetailsTab = ({
  isAdmin,
  personalDetailsPercentage,
  companyId,
  userDetails,
  isEditable,
  toastRef,
}) => (
  {
    disabled: isTabDisabled(isAdmin, personalDetailsPercentage),
    disabledHintContent: t('profile.form_tabs.disabled_tooltip_business_details'),
    getContent: ({ onTabChange }) => (
      <BusinessDetailsForm
        userDetails={userDetails}
        companyId={companyId}
        isEditable={isEditable}
        onTabChange={onTabChange}
        toastRef={toastRef}
      />
    ),
    key: BUSINESS_DETAILS_FORM_KEY,
    title: t('profile.form_tabs.business_details'),
  }
);

const getBusinessContactsTab = ({
  isAdmin,
  businessDetailsPercentage,
  companyId,
  isEditable,
  toastRef,
}) => (
  {
    disabled: isTabDisabled(isAdmin, businessDetailsPercentage),
    disabledHintContent: t('profile.form_tabs.disabled_tooltip'),
    getContent: () => (
      <BusinessContacts companyId={companyId} isEditable={isEditable} toastRef={toastRef} />
    ),
    key: BUSINESS_CONTACTS_FORM_KEY,
    title: t('profile.form_tabs.business_contacts'),
  }
);

const getFoodSafetyTab = ({
  isAdmin,
  companyId,
  businessDetailsPercentage,
  isEditable,
  toastRef,
}) => (
  {
    disabled: isTabDisabled(isAdmin, businessDetailsPercentage),
    disabledHintContent: t('profile.form_tabs.disabled_tooltip'),
    getContent: () => <FoodSafetyForm companyId={companyId} isEditable={isEditable} toastRef={toastRef} />,
    key: FOOD_SAFETY_FORM_KEY,
    title: t('profile.form_tabs.food_safety'),
  }
);

const getCompanyPermissionsTab = ({ companyId, toastRef }) => (
  {
    getContent: () => <CompanyPermissionsForm companyId={companyId} toastRef={toastRef} />,
    key: COMPANY_PERMISSIONS_FORM_KEY,
    title: t('profile.form_tabs.company_permissions'),
  }
);

const getAdditionalDocumentTab = ({
  isAdmin,
  businessDetailsPercentage,
  companyId,
  companyDetails,
  userDetails,
  isEditable,
  toastRef,
}) => (
  {
    disabled: isTabDisabled(isAdmin, businessDetailsPercentage),
    getContent: () => (
      <AdditionalDocumentForm
        companyDetails={companyDetails}
        userDetails={userDetails}
        companyId={companyId}
        isEditable={isEditable}
        toastRef={toastRef}
      />
    ),
    key: ADDITIONAL_DOCUMENT_FORM_KEY,
    title: t('profile.form_tabs.additional_document'),
  }
);

const getBankingDetailsTab = ({
  isAdmin,
  businessDetailsPercentage,
  companyId,
  isEditable,
  toastRef,
}) => (
  {
    disabled: isTabDisabled(isAdmin, businessDetailsPercentage),
    disabledHintContent: t('profile.form_tabs.disabled_tooltip'),
    getContent: () => (
      <BankingDetailsForm companyId={companyId} isEditable={isEditable} toastRef={toastRef} />
    ),
    key: FOOD_SAFETY_FORM_KEY,
    title: t('profile.progress_card.banking_details'),
  }
);

export const getBuyerTabs = (params) => (
  [
    getPersonalDetailsTab(params),
    getBusinessDetailsTab(params),
    getBusinessContactsTab(params),
    getAdditionalDocumentTab(params),
  ]
);

export const getSellerTabs = (params) => {
  const sellerTabs = [
    getPersonalDetailsTab(params),
    getBusinessDetailsTab(params),
    getBusinessContactsTab(params),
    getFoodSafetyTab(params),
    getAdditionalDocumentTab(params),
  ]

  if (params.isAdmin) {
    sellerTabs.push(
      getCompanyPermissionsTab(params)
    );
  }

  return sellerTabs;
};

export const getAffiliatePartnerTabs = (params) => (
  [
    getPersonalDetailsTab(params),
    getBusinessDetailsTab(params),
    getBusinessContactsTab(params),
    getBankingDetailsTab(params),
    getAdditionalDocumentTab(params),
  ]
);

export const getSubscriptionMembersTabs = (params) => (
  [
    getPersonalDetailsTab(params),
    getBusinessDetailsTab(params),
    getAdditionalDocumentTab(params),
  ]
);

export const getAdminUserManagementTabs = (params) => {
  const { userType } = params;

  if (userType === SELLER) {
    return getSellerTabs(params);
  }
  if (userType === BUYER) {
    return getBuyerTabs(params);
  }
  if (userType === AFFILIATE_PARTNER) {
    return getAffiliatePartnerTabs(params);
  }
  if (userType === SUBSCRIPTION_MEMBER) {
    return getSubscriptionMembersTabs(params);
  }

  return [];
};

export const getAdminUserTableTabs = (fetchingAllUsers, users = []) => [
  {
    title: allUsersTab,
    key: allUsersTab,
    getContent: () => (
      <UserTable
        tabKey={allUsersTab}
        tableHeaders={allUsersTabTableHeader}
        users={users}
        fetchingAllUsers={fetchingAllUsers}
      />
    ),
  },
  {
    key: appliedTab,
    title: appliedTab,
    getContent: () => (
      <UserTable
        tabKey={appliedTab}
        tableHeaders={appliedTabTableHeader}
        users={users}
        fetchingAllUsers={fetchingAllUsers}
      />
    ),
  },
  {
    key: approvedTab,
    title: approvedTab,
    getContent: () => (
      <UserTable
        tabKey={approvedTab}
        tableHeaders={approvedTabTableHeader}
        users={users}
        fetchingAllUsers={fetchingAllUsers}
      />
    ),
  },
  {
    key: documentRequestedTab,
    title: documentRequestedTab,
    getContent: () => (
      <UserTable
        tabKey={documentRequestedTab}
        tableHeaders={unapprovedTabTableHeader}
        users={users}
        fetchingAllUsers={fetchingAllUsers}
      />
    ),
  },
  {
    key: unapprovedTab,
    title: unapprovedTab,
    getContent: () => (
      <UserTable
        tabKey={unapprovedTab}
        tableHeaders={unapprovedTabTableHeader}
        users={users}
        fetchingAllUsers={fetchingAllUsers}
      />
    ),
  },
];

export const getProductsTableTabs = (
  fetchingProducts,
  productType,
  productData = {},
  contractsData = {},
) => [
  {
    key: contractsTab,
    title: contractsTab,
    getContent: () => (
      <ProductTable
        tabKey={contractsTab}
        tableHeaders={contractsTabTableHeader}
        products={contractsData.collection}
        fetchingProducts={fetchingProducts}
      />
    ),
  },
  {
    key: offersTab,
    title: offersTab,
    getContent: () => (
      <ProductTable
        tabKey={offersTab}
        tableHeaders={
          productType === KERNEL_PRODUCT ? offersTableHeader : dnisOffersTableHeader
        }
        products={productData.collection}
        fetchingProducts={fetchingProducts}
      />
    ),
  },
  {
    key: ordersTab,
    title: ordersTab,
    getContent: () => (
      <ProductTable
        tabKey={ordersTab}
        tableHeaders={
          productType === KERNEL_PRODUCT ? offersTableHeader : dnisOffersTableHeader
        }
        products={productData.collection}
        fetchingProducts={fetchingProducts}
      />
    ),
  },
];

export const getEditStatus = (isAdmin, isVerified, hasRequestedVerification) =>
  !(isAdmin || (isVerified && !isAdmin) || (hasRequestedVerification && !isAdmin));

export const getMyAffiliationsTable = (isFetching, isAdmin, usersData = {}) => [
  {
    key: onboardedTab,
    title: onboardedTab,
    getContent: () => (
      <MyAffiliationsTableSkeleton
        tabKey={onboardedTab}
        tableHeaders={oboardedTabTableHeader}
        users={usersData}
        isFetching={isFetching}
      />
    ),
  },
  {
    key: invitedTab,
    title: invitedTab,
    getContent: () => (
      <MyAffiliationsTableSkeleton
        tabKey={invitedTab}
        tableHeaders={invitedTabTableHeader(isAdmin)}
        users={usersData}
        isFetching={isFetching}
      />
    ),
  },
  {
    key: archivedTab,
    title: archivedTab,
    getContent: () => (
      <MyAffiliationsTableSkeleton
        tabKey={archivedTab}
        tableHeaders={archivedTabTableHeader}
        users={usersData}
        isFetching={isFetching}
      />
    ),
  },
];

export const getAdminaffiliationsTable = (isUsersFetching, users = []) => [
  {
    key: appliedTab,
    title: appliedTab,
    getContent: () => (
      <PartnerTable
        tabKey={appliedTab}
        tableHeaders={appliedPartnerTableHeader}
        users={users}
        isUsersFetching={isUsersFetching}
      />
    ),
  },
  {
    key: approvedTab,
    title: approvedTab,
    getContent: () => (
      <PartnerTable
        tabKey={approvedTab}
        tableHeaders={approvedPartnerTableHeader}
        users={users}
        isUsersFetching={isUsersFetching}
      />
    ),
  },
];

export const getTabTitle = (tabKey, productCount) =>
  `${tabKey}${productCount ? ` (${productCount})` : ''}`;

export const getProducts = ({
  activeProductCount,
  draftProductCount,
  activeOfferList,
  darftOfferList,
  activeListingsLoading,
  draftListingsLoading,
  productStatus,
  userRole,
}) => [
  {
    key: activeOffersTab,
    title: getTabTitle(activeOffersTab, activeProductCount),
    getContent: () => (
      <OfferCardsContainer
        listings={activeOfferList}
        isLoading={activeListingsLoading}
        productStatus={productStatus}
        userRole={userRole}
      />
    ),
  },
  {
    key: inDraftTab,
    title: getTabTitle(inDraftTab, draftProductCount),
    getContent: () => (
      <OfferCardsContainer
        listings={darftOfferList}
        isLoading={draftListingsLoading}
        productStatus="draft"
        userRole={userRole}
      />
    ),
  },
];

export const getContracts = ({
  completedContractCount,
  concludedContractCount,
  inProgressContractCount,
  completedContractList,
  concludedContractList,
  inProgressContractList,
  completedContractsLoading,
  concludedContractsLoading,
  inProgressContractsLoading,
  productStatus,
  userRole,
}) => [
  {
    key: completedTab,
    title: getTabTitle(completedTab, completedContractCount),
    getContent: () => (
      <OfferCardsContainer
        listings={completedContractList}
        isLoading={completedContractsLoading}
        productStatus={productStatus}
        userRole={userRole}
      />
    ),
  },
  {
    key: concludedTab,
    title: getTabTitle(concludedTab, concludedContractCount),
    getContent: () => (
      <OfferCardsContainer
        listings={concludedContractList}
        isLoading={concludedContractsLoading}
        productStatus={productStatus}
        userRole={userRole}
      />
    ),
  },
  {
    key: inProgressTab,
    title: getTabTitle(inProgressTab, inProgressContractCount),
    getContent: () => (
      <OfferCardsContainer
        listings={inProgressContractList}
        isLoading={inProgressContractsLoading}
        productStatus={productStatus}
        userRole={userRole}
      />
    ),
  },
];
