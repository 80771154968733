import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { handleServerValidationErrors } from "utils/helper/Validations";
import { Form, Field } from "react-final-form";
import { useGetCompanyPermissionsQuery, useUpdateCompanyPermissionsMutation } from "api/companies";
import { generateCompanyPermissionsBody } from "utils/helper/RequestBody";
import { processPortalFormSuccess } from "utils/helper/FormHandlers";
import Button from "components/BaseComponents/Button";
import CheckBox from "components/BaseComponents/CheckBox";
import "./styles.scss";

const CompanyPermissionsForm = ({ companyId, toastRef }) => {
  const { t } = useTranslation();

  const { data: companyPermissions = {}, isFetching } = useGetCompanyPermissionsQuery({ companyId });
  const [updateCompanyPermissions] = useUpdateCompanyPermissionsMutation();

  const handleFormSubmit = (values) => {
    const requestBody = generateCompanyPermissionsBody(values);

    return updateCompanyPermissions({ companyId, payload: requestBody })
      .unwrap()
      .then(() => processPortalFormSuccess(toastRef))
      .catch(({ data: { message }, status }) =>
        handleServerValidationErrors(toastRef, status, message),
      );
  };

  const initValue = {
    trade_kernel: companyPermissions.tradeKernel,
    trade_dried_nut_in_shell: companyPermissions.tradeDriedNutInShell,
  }

  return (
    <div className="company-permissions-form padding-t-32px">
      <h3 className="company-permissions-form__title padding-x-24px">
        {t("admin.company_permissions.heading")}
      </h3>
      <Form
        onSubmit={handleFormSubmit}
        initialValues={initValue}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="padding-y-32px">
            <fieldset>
              <div className="padding-x-24px">
                <Field name="trade_kernel" type="checkbox">
                  {({ input, meta }) => (
                    <CheckBox
                      className="company-permissions-form__permissions-checkbox"
                      label={t("admin.company_permissions.trade_kernel")}
                      isChecked={input.checked}
                      onChange={input.onChange}
                      hintText={meta.submitError}
                      disabled={isFetching && submitting}
                    />
                  )}
                </Field>

                <Field name="trade_dried_nut_in_shell" type="checkbox">
                  {({ input, meta }) => (
                    <CheckBox
                      className="company-permissions-form__permissions-checkbox"
                      label={t("admin.company_permissions.trade_dried_nut_in_shell")}
                      isChecked={input.checked}
                      onChange={input.onChange}
                      hintText={meta.submitError}
                      disabled={isFetching || submitting}
                    />
                  )}
                </Field>

                <Button
                  type="success"
                  label={t("admin.company_permissions.submit")}
                  submitType="submit"
                  className="company-permissions-form__submit-btn margin-t-24px"
                  disabled={submitting || isFetching}
                />
              </div>
            </fieldset>
          </form>
        )}
      />
    </div>
  );
};

CompanyPermissionsForm.propTypes = {
  companyId: PropTypes.number.isRequired,
  toastRef: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};

export default CompanyPermissionsForm;
