import React, { Fragment, useState } from 'react';
import { t } from 'i18next';
import { useGetMyListingsQuery } from 'api/listings';
import ContentLoaderPlaceholder from 'components/BaseComponents/ContentLoader/ContentLoader';
import { useGetCurrentUserQuery } from 'api/users';
import { activeOffersTab, inDraftTab } from 'utils/constants/portals';
import Pagination from 'components/BaseComponents/Pagination';
import { PUBLISHED, UNPUBLISHED, ADMIN_LISTINGS_PER_PAGE } from 'utils/constants/offerListings';
import { getProducts } from 'utils/helper/Portals';
import { RTabs } from 'components/BaseComponents/RTabs';
import PortalsNavbar from 'components/NavBar/PortalsNavBar';
import './styles.scss';

const Listings = () => {
  const [productStatus, setProductStatus] = useState(activeOffersTab);
  const [currentPage, setCurrentPage] = useState(1);
  const { data: user = {} } = useGetCurrentUserQuery();

  const {
    data: activeListings = {},
    isFetching: isFetchingActiveListings,
    isSuccess: activeListingsFetched,
    isLoading: activeListingsLoading,
  } = useGetMyListingsQuery({
    currentPage,
    productStatus: PUBLISHED,
    perPage: ADMIN_LISTINGS_PER_PAGE,
  });
  const { collection: activeOfferList = [] } = activeListings;

  const {
    data: draftListtings = {},
    isFetching: isFetchingInDraftListings,
    isSuccess: draftListingsFetched,
    isLoading: draftListingsLoading,
  } = useGetMyListingsQuery({
    currentPage,
    productStatus: UNPUBLISHED,
    perPage: ADMIN_LISTINGS_PER_PAGE,
  });
  const { collection: darftOfferList = [] } = draftListtings;

  const activeProductCount = activeListings?.pagination?.totalCount;
  const draftProductCount = draftListtings?.pagination?.totalCount;

  const handleTabChange = (tabKey) => {
    setProductStatus(tabKey);
    setCurrentPage(1);
  };

  const handlePagination = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const { role: userRole } = user;
  const title = t(`navbar.seller.links.${userRole}.title`);

  let totalPages;
  const totalPageCount = () => {
    if (productStatus === activeOffersTab && !isFetchingActiveListings) {
      totalPages = Number(activeListings?.pagination?.totalPages);
      return totalPages;
    }

    if (productStatus === inDraftTab && !isFetchingInDraftListings) {
      totalPages = Number(draftListtings?.pagination?.totalPages);
      return totalPages;
    }
    return null;
  };

  const offerTabs = getProducts({
    activeProductCount,
    draftProductCount,
    activeOfferList,
    darftOfferList,
    activeListingsLoading,
    draftListingsLoading,
    productStatus,
    userRole,
  });

  return (
    <Fragment>
      <PortalsNavbar />
      <div className="listings-wrapper">
        <div className="listings">
          <h1>{title}</h1>
          <RTabs
            allowRemove={false}
            items={offerTabs}
            selectedTabKey={productStatus}
            controlled={true}
            onChange={handleTabChange}
          />

          {!activeListingsFetched ||
            (!draftListingsFetched && <ContentLoaderPlaceholder numberOfLines={5} />)}

          {totalPageCount() > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPaginationClick={handlePagination}
              firstText={t('admin_user_table.first_page')}
              lastText={t('admin_user_table.last_page')}
              nextText={t('admin_user_table.next_page')}
              previousText={t('admin_user_table.previous_page')}
              className="admin-portal__table--pagination"
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Listings;
