import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import Icon from 'components/BaseComponents/Icon';
import './styles.scss';

const DisplayNavLink = ({ content, isUserVerified, userRole }) => {
  const isLinkDisabled = !isUserVerified;

  const linkClassName = classNames('drop-down__content--menu-item', {
    'drop-down__content--menu-disable': isLinkDisabled,
  });

  return (
    <NavLink
      to={content.link(userRole)}
      onClick={(e) => {
        if (isLinkDisabled) {
          e.preventDefault();
        }
      }}
      className={linkClassName}
    >
      {content.name(userRole)}
    </NavLink>
  );
};

DisplayNavLink.defaultProps = {
  content: PropTypes.shape({
    isDisabled: true,
    name: () => {},
    link: () => {},
  }),
  isUserVerified: false,
  userRole: '',
};

DisplayNavLink.propTypes = {
  content: PropTypes.shape({
    isDisabled: PropTypes.bool.isRequired,
    name: PropTypes.func.isRequired,
    link: PropTypes.func.isRequired,
  }),
  isUserVerified: PropTypes.bool,
  userRole: PropTypes.string,
};

const CustomNavLink = ({
  userRole,
  name,
  link,
  dropDownContents,
  isDropDownOpen = false,
  isDisabled,
  isUserVerified = false,
}) => {
  const displayDropDown = dropDownContents && isDropDownOpen;
  const navLinkClassName = classNames('nav-header__navigation--item', {
    'nav-header__navigation--item-disable': isDisabled,
  });

  return (
    <li className={navLinkClassName}>
      {dropDownContents ? (
        <div className="nav-header__navigation--link">
          <div className="nav-header__navigation--link-icon">
            {name()}
            {dropDownContents && <Icon name="iconDownChevron" color="gray" size="tiny" />}
          </div>

          {displayDropDown && (
            <div className="drop-down__content">
              <ul className="drop-down__content--menu">
                {dropDownContents.map((content) => (
                  <li key={content.id}>
                    <DisplayNavLink
                      content={content}
                      userRole={userRole}
                      isUserVerified={isUserVerified}
                      isDisabled={isDisabled}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ) : (
        <NavLink
          to={link(userRole)}
          onClick={(e) => {
            if (isDisabled) {
              e.preventDefault();
            }
          }}
          className="nav-header__navigation--link"
        >
          {name()}
        </NavLink>
      )}
    </li>
  );
};

CustomNavLink.propTypes = {
  userRole: PropTypes.string,
  name: PropTypes.func.isRequired,
  link: PropTypes.func,
  dropDownContents: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.func.isRequired,
      link: PropTypes.func.isRequired,
    }),
  ),
  isDropDownOpen: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isUserVerified: PropTypes.bool,
};

CustomNavLink.defaultProps = {
  userRole: null,
  link: null,
  dropDownContents: null,
  isDropDownOpen: false,
  isDisabled: false,
  isUserVerified: false,
};

export default CustomNavLink;
