import React, { useRef, useState } from 'react';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import useCustomNavigate from 'hooks/useCustomNavigate';
import useIsListingUnpublished from 'hooks/useIsListingUnpublished';
import { useGetCurrentUserQuery } from 'api/users';
import {
  useGetFoodSafetyTestQuery,
  useGetProductDetailsQuery,
  useSubmitFoodSafetyTestMutation,
  useUpdateFoodSafetyTestMutation,
} from 'api/products';
import { generateOfferFoodSafetyForm, generateDnisOfferFoodSafetyForm } from 'utils/helper/RequestBody';
import { handleFoodSafetyServerValidationErrors } from 'utils/helper/Validations';
import { handleErrorsOfferFoodSafety } from 'utils/helper/ResponseConverter';
import { errorToastConfig } from 'utils/constants/ToastConfig';
import { helpDocs, offerOrderPageIndex } from 'utils/constants/listingCreation';
import SelectBox from 'components/BaseComponents/SelectBox';
import Modal from 'components/BaseComponents/Modal';
import CreateListingLayout from 'components/BiddingProcess/CreateListing/Layout';
import SubmitButtons from 'components/Shared/BiddingProcess/StepperButtons/SubmitButtons';
import ToastNotification from 'components/Shared/ToastNotification';
import Toastify from 'components/Shared/ToastNotification/Toastify';
import { DRIED_NUT_IN_SHELL_PRODUCT, KERNEL_PRODUCT } from 'utils/constants/product';
import CreateListingAuthorizer from '../Layout/CreateListingAuthorizer';
import BatchDetails from './BatchDetails';
import DriedNutInShellSection from './DriedNutInShellSection';
import MicrobiologicalTest from './MicrobiologicalTest';
import HeavyMetalTest from './HeavyMetalTest';
import OrganicCertificateDocuments from './OrganicCertificate';
import ChemicalTest from './ChemicalTest';
import MaxResidueLevels from './MaxResidueLevels';
import FoodSafetySkipModalBody from './SkipModalBody';
import './styles.scss';

const FoodSafetyOfferCreation = () => {
  const currentPageIndex = offerOrderPageIndex.foodSafety;
  const toastRef = useRef();
  const navigate = useCustomNavigate();
  const { productId } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data: user = {} } = useGetCurrentUserQuery();
  const { data: foodSafetyTestDetails = {} } = useGetFoodSafetyTestQuery({ productId });
  const { data: productDetails = {} } = useGetProductDetailsQuery(
    { product_id: productId },
    { skip: !productId },
  );

  const listingId = productDetails?.listingId;
  const companyId = user?.company_id;
  const isListingUnpublished = useIsListingUnpublished({ listingId });
  const canSkip = !foodSafetyTestDetails?.productId && isListingUnpublished;

  const [submitFoodSafetyTest, { isLoading: isFoodSafetySubmitting }] =
    useSubmitFoodSafetyTestMutation();
  const [updateFoodSafetyTest, { isLoading: isFoodSafetyUpdating }] =
    useUpdateFoodSafetyTestMutation();

  const isSubmitButtonsDisabled =
    isFoodSafetySubmitting || isFoodSafetyUpdating || !isListingUnpublished;

  const handleModalOpen = () => setIsModalVisible(true);
  const handleModalClose = () => setIsModalVisible(false);

  const handleFoodSafetyFormSubmit = async (params, buttonAction = 'submit') => {
    const isDnisProduct = productDetails?.type === DRIED_NUT_IN_SHELL_PRODUCT;
    const requestBody = isDnisProduct ? generateDnisOfferFoodSafetyForm(params) : generateOfferFoodSafetyForm(params);

    if (foodSafetyTestDetails?.productId) {
      return updateFoodSafetyTest({ productId, payload: requestBody })
        .unwrap()
        .then(() => {
          if (buttonAction === 'submit') {
            return navigate.declaration(user?.role, productId);
          }
          if (buttonAction === 'draft') {
            return navigate.myOffers();
          }

          return null;
        })
        .catch(({ data: { errors }, status }) => {
          handleFoodSafetyServerValidationErrors(toastRef, status, errors);
          return handleErrorsOfferFoodSafety(errors);
        });
    }

    return submitFoodSafetyTest({ productId, payload: requestBody })
      .unwrap()
      .then(() => {
        if (buttonAction === 'submit') {
          return navigate.declaration(user?.role, productId);
        }
        if (buttonAction === 'draft') {
          return navigate.myOffers();
        }

        return null;
      })
      .catch(({ data: { errors }, status }) => {
        handleFoodSafetyServerValidationErrors(toastRef, status, errors);
        return handleErrorsOfferFoodSafety(errors);
      });
  };

  const handleSkipSubmit = () => {
    handleModalClose();
    navigate.declaration(user?.role, productId);
  };

  const preventSubmissionOnEnter = (event) => {
    const ENTER_KEY_KEYCODE = 13;

    if (event.keyCode === ENTER_KEY_KEYCODE) {
      event.preventDefault();
    }
  };

  return (
    <CreateListingAuthorizer>
      <CreateListingLayout activeStep={currentPageIndex}>
        <Form
          onSubmit={(params) => handleFoodSafetyFormSubmit(params)}
          initialValues={foodSafetyTestDetails}
          mutators={{
            ...arrayMutators,
            setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
              changeValue(state, fieldName, () => fieldVal);
            },
          }}
          render={({ form, handleSubmit, submitting, hasValidationErrors }) => (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <form
              onKeyDown={(event) => preventSubmissionOnEnter(event)}
              onSubmit={(event) => {
                if (hasValidationErrors) {
                  toastRef?.current?.notify(<Toastify type="error" />, errorToastConfig);
                }

                handleSubmit(event);
              }}
            >
              <SubmitButtons
                isSaveAsDraftVisible={true}
                isSkipButtonVisible={canSkip}
                userRole={user?.role}
                currentStepIndex={currentPageIndex}
                onSkip={handleModalOpen}
                onSaveAsDraft={() => handleFoodSafetyFormSubmit(form.getState().values, 'draft')}
                onPrevButtonClick={() => navigate.reviewListingById(user?.role, productId)}
                isSaveAsDraftButtonDisabled={
                  submitting || hasValidationErrors || isSubmitButtonsDisabled
                }
                isSubmitButtonDisabled={submitting || isSubmitButtonsDisabled}
              />
              <div className="food-safety-offer-form">
                <div className="food-safety-offer-form__header">
                  <h2 className="food-safety-offer-form__header--title">
                    {t('offer_creation.food_safety.title')}
                  </h2>
                  <SelectBox
                    placeholderText={t('offer_creation.food_safety.help_doc_select_box_label')}
                    isSearchable={false}
                    options={helpDocs}
                    onChange={(selected) => window.open(selected.link, '_blank')}
                    className="food-safety-offer-form__batch-select-box"
                  />
                </div>

                {productDetails?.type === KERNEL_PRODUCT && (
                  <>
                    <BatchDetails />
                    <OrganicCertificateDocuments form={form} />
                    <MicrobiologicalTest form={form} />
                    <ChemicalTest form={form} />
                    <HeavyMetalTest form={form} />
                    <MaxResidueLevels />
                  </>
                )}

                {productDetails?.type === DRIED_NUT_IN_SHELL_PRODUCT && (
                  <>
                    <DriedNutInShellSection form={form} companyId={companyId} />
                    <OrganicCertificateDocuments form={form} />
                  </>
                )}
              </div>
            </form>
          )}
        />
        <Modal
          isVisible={isModalVisible}
          onOutsideClick={handleModalClose}
          className="progress-card-modal"
        >
          <Modal.Content>
            <FoodSafetySkipModalBody
              onModalClose={handleModalClose}
              onSkipSubmit={handleSkipSubmit}
            />
          </Modal.Content>
        </Modal>
        <ToastNotification toastRef={toastRef} />
      </CreateListingLayout>
    </CreateListingAuthorizer>
  );
};

export default FoodSafetyOfferCreation;
