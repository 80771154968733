import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import EmptyContentPlaceholder from 'components/Shared/EmptyContentPlaceholder';
import Button from 'components/BaseComponents/Button';
import { COMPLETED, CONCLUDED, IN_PROGRESS } from 'utils/constants/offerListings';
import ProductCardWrapper from '../ProductCardWrapper';

const OfferCardsContainer = ({ listings, isLoading, userRole, productStatus }) => {
  const productStatusKey = productStatus.toLowerCase().replace(" ", "_")
  const isStatusExists = [COMPLETED, CONCLUDED, IN_PROGRESS].includes(productStatusKey);

  const emptyContentLabel = isStatusExists
    ? t('shared.bidding_process.empty_content_placeholder.contracts_heading')
    : t('shared.bidding_process.empty_content_placeholder.heading');
  const emptyContentButtonLabel = isStatusExists
    ? t(`shared.empty_content.${userRole}.explore_button`)
    : t(`shared.empty_content.${userRole}.button`);
  const emptyContentDescription = isStatusExists
    ? t(`shared.empty_content.seller.${productStatusKey}`)
    : t(`shared.empty_content.${userRole}.${productStatusKey}`);
  const redirectLink = isStatusExists
    ? `/${userRole}/listings`
    : `/${userRole}/create-listing/product-details`;

  return (
    <div>
      {listings.length > 0
        ? listings.map(
            ({ productId, totalBidCount, highestBidPrice, serialNumber, status, contract }) => (
              <ProductCardWrapper
                key={productId}
                productId={productId}
                totalBidCount={totalBidCount}
                highestBidPrice={highestBidPrice}
                serialNumber={serialNumber}
                productStatus={status}
                contract={contract}
                userRole={userRole}
              />
            ),
          )
        : !isLoading && (
            <div className="listings__empty-content">
              <EmptyContentPlaceholder
                heading={emptyContentLabel}
                description={emptyContentDescription}
              />
              <NavLink to={redirectLink}>
                <Button
                  className="listings__empty-content--create-offer-button"
                  label={emptyContentButtonLabel}
                />
              </NavLink>
            </div>
          )}
    </div>
  );
};

OfferCardsContainer.defaultProps = {
  listings: [],
  isLoading: true,
};

OfferCardsContainer.propTypes = {
  listings: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.number,
      totalbidCount: PropTypes.number,
    }),
  ),
  isLoading: PropTypes.bool,
  productStatus: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default OfferCardsContainer;
