import React, { Fragment, useState } from 'react';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetCompanyQuery } from 'api/companies';
import { isVerified } from 'utils/helper/UserRoleValidation';
import PortalsNavbar from 'components/NavBar/PortalsNavBar';
import AvatarCard from 'components/Shared/BiddingProcess/Avatar';
import { DRIED_NUT_IN_SHELL_PRODUCT, KERNEL_PRODUCT } from 'utils/constants/product';
import PortalsFooter from 'components/Shared/PortalsFooter';
import ListingsContainer from './Container';
import './styles.scss';

const OfferListings = () => {
  const storedNutsTab = localStorage.getItem('nutsTab');
  const initNutsTab = [DRIED_NUT_IN_SHELL_PRODUCT, KERNEL_PRODUCT].includes(storedNutsTab)
    ? storedNutsTab
    : DRIED_NUT_IN_SHELL_PRODUCT;

  const [nutsTab, setNutsTab] = useState(initNutsTab);
  localStorage.setItem('nutsTab', nutsTab); // add nutsTab to localStorage to catch it on 'Create Product' page

  const { data: user = {}, isLoading: isUserDetailsLoading } = useGetCurrentUserQuery();
  const { data: companyDetails = {}, isLoading: isCompanyDetailsLoading } = useGetCompanyQuery(
    { id: user?.company_id },
    { skip: !user?.id },
  );

  const {
    first_name: firstName,
    last_name: lastName,
    company_id: companyId,
    role: userRole,
  } = user;
  const userName = `${firstName} ${lastName}`;
  const { name: companyName } = companyDetails;
  const isUserVerified = isVerified(companyDetails);

  return (
    <Fragment>
      <PortalsNavbar />
      {!isUserDetailsLoading && !isCompanyDetailsLoading && (
        <AvatarCard
          className="border-y-1 border-gray-200 border-y-solid padding-y-32px"
          userName={userName}
          companyName={companyName}
          isVerified={isUserVerified}
          userRole={userRole}
          companyDetails={companyDetails}
          nutsTab={nutsTab}
        />
      )}
      <div className="offer-listing padding-y-32px">
        <ListingsContainer companyId={companyId} userType={userRole} nutsTab={nutsTab} setNutsTab={setNutsTab} />
      </div>
      <PortalsFooter/>
    </Fragment>
  );
};

export default OfferListings;
