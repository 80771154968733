import React, { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import './style.scss';

const RadioWithLabel = (props) => {
  const { name, iconName, label, containerClassName, radioId, isSelected, value, onChange } = props;
  const inputRef = useRef();

  const customRadioWrapperClass = classNames('custom-radio', containerClassName, {
    'custom-radio-selected': isSelected,
    'custom-radio-not-selected': !isSelected,
  });
  const radioClassName = classNames({'custom-radio__input-field--hidden': isSelected});
  return (
    <div className={customRadioWrapperClass} onClick={() => inputRef.current.click()}>
      <div className="flex items-center gap-14px">
        <Icon name={iconName} color="none" size="huge" />
        <label className="custom-radio__label" htmlFor={name}>
          {label}
        </label>
      </div>
      {isSelected && <Icon name="checkCircle" color="success" />}
      <input
        type="radio"
        id={radioId}
        name={name}
        value={value}
        onChange={onChange}
        ref={inputRef}
        className={radioClassName}
      />
    </div>
  );
};

RadioWithLabel.defaultProps = {
  label: null,
  containerClassName: null,
  radioId: 'customRadio',
  isSelected: false,
  onChange: () => {},
};

RadioWithLabel.propTypes = {
  name: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  radioId: PropTypes.string,
  isSelected: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default RadioWithLabel;
