import { apiSlice } from 'api';
import { toSnakecaseKeys } from 'utils/helper/ObjectKeyConverter';
import {
  companyContactResponseBody,
  convertBusinessDetailsResponse,
} from 'utils/helper/ResponseConverter';
import routes from 'utils/routes';
import {
  COMPANY,
  VERIFICATION_STATUS,
  COMPANY_CONTACTS,
  PROFILE_COMPLETENESS,
  COMPANY_PERMISSIONS,
} from 'utils/tagTypes';

const companyApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCompany: build.query({
      query: (params) => routes.companies.entity(params),
      transformResponse: (response) => convertBusinessDetailsResponse(toSnakecaseKeys(response)),
      providesTags: [COMPANY, VERIFICATION_STATUS],
    }),
    updateCompany: build.mutation({
      query: ({ companyId, payload }) => ({
        url: routes.companies.entity({ id: companyId }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [COMPANY, PROFILE_COMPLETENESS],
    }),
    getCompanyBusinessContacts: build.query({
      query: (params) => routes.companies.businessContacts(params),
      transformResponse: (response) => companyContactResponseBody(toSnakecaseKeys(response)),
      providesTags: [COMPANY_CONTACTS],
    }),
    getBankigDetails: build.query({
      query: ({ company_id }) => ({
        url: routes.companies.bankingDetails({ company_id }),
        method: 'GET',
      }),
    }),
    updateCompanyBusinessContacts: build.mutation({
      query: ({ companyId, payload }) => ({
        url: routes.companies.businessContacts({ id: companyId }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [COMPANY_CONTACTS, PROFILE_COMPLETENESS],
    }),
    updateAdditionalDocuments: build.mutation({
      query: ({ companyId, payload }) => ({
        url: routes.companies.additionalDocuments({ id: companyId }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [COMPANY, PROFILE_COMPLETENESS],
    }),
    updateReligionCertificates: build.mutation({
      query: ({ companyId, payload }) => ({
        url: routes.companies.religionSertificates({ id: companyId }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [COMPANY, PROFILE_COMPLETENESS],
    }),
    updateBankingDetails: build.mutation({
      query: ({ company_id, payload }) => ({
        url: routes.companies.bankingDetails({ company_id }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [COMPANY, PROFILE_COMPLETENESS],
    }),
    getCompanyPermissions: build.query({
      query: ({ companyId }) => ({
        url: routes.companies.companyPermissions({ id: companyId }),
        method: 'GET',
      }),
      providesTags: [COMPANY_PERMISSIONS],
    }),
    updateCompanyPermissions: build.mutation({
      query: ({ companyId, payload }) => ({
        url: routes.companies.companyPermissions({ id: companyId }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [COMPANY, COMPANY_PERMISSIONS],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCompanyQuery,
  useUpdateCompanyMutation,
  useGetCompanyBusinessContactsQuery,
  useUpdateCompanyBusinessContactsMutation,
  useUpdateAdditionalDocumentsMutation,
  useUpdateReligionCertificatesMutation,
  useUpdateBankingDetailsMutation,
  useGetCompanyPermissionsQuery,
  useUpdateCompanyPermissionsMutation,
} = companyApi;
