import { ADMIN, BUYER, SELLER, AFFILIATE_PARTNER, SUBSCRIPTION_MEMBER } from 'utils/constants/userRole';
import { DOCUMENT_REQUESTED, REQUESTED_VERIFICATION, VERIFIED } from 'utils/constants/userStatus';

const isMSMSeller = ({ role }) => role === SELLER;
const isMSMSubscriptionMember = ({ role }) => role === SUBSCRIPTION_MEMBER;
const isMSMBuyer = ({ role }) => role === BUYER;
const isMSMAdmin = ({ role }) => role === ADMIN;
const isMSMPartner = ({ role }) => role === AFFILIATE_PARTNER;
const isVerified = ({ status }) => status === VERIFIED;

const hasRequestedAdditionalDoc = ({ status }) => status === DOCUMENT_REQUESTED;
const userRequestedVerification = ({ status }) => status === REQUESTED_VERIFICATION;

export {
  isMSMSeller,
  isMSMBuyer,
  isMSMAdmin,
  isMSMSubscriptionMember,
  hasRequestedAdditionalDoc,
  userRequestedVerification,
  isVerified,
  isMSMPartner,
};
