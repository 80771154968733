import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useGetCurrentUserQuery } from 'api/users';
import { isMSMPartner } from 'utils/helper/UserRoleValidation';
import { HUNDRED_PERCENT } from 'utils/constants/progressCardDetails';
import '../styles.scss';

const ProgressCardList = ({ id, title, completePercentage }) => {
  const { t } = useTranslation();
  const { data: user } = useGetCurrentUserQuery();
  const isComplete = completePercentage === HUNDRED_PERCENT;

  const generateHintText = (itemId) => {
    if (itemId !== 4) {
      return t('profile.progress_card.required_field_incomplete');
    }
    if (isMSMPartner(user)) {
      return t('profile.progress_card.required_field_incomplete');
    }
    return t('profile.progress_card.required_file_empty');
  };

  return (
    <li className="flex items-center">
      <span
        className={classNames('steps-list-number', { 'steps-list-number--complete': isComplete })}
      >
        <span className="steps-list-number--digit">{id}</span>
      </span>
      <span>{t('profile.progress_card.completed_label', { title, completePercentage })}</span>
      {!isComplete && (
        <Fragment>
          <span className="steps-separator">-</span>
          <span className="steps-separated-text">{generateHintText(id)}</span>
        </Fragment>
      )}
    </li>
  );
};

ProgressCardList.defaultProps = {
  completePercentage: 0,
};

ProgressCardList.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  completePercentage: PropTypes.number,
};

export default ProgressCardList;
