import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCurrentUserQuery } from 'api/users';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { isMSMSeller } from 'utils/helper/UserRoleValidation';
import { DRIED_NUT_IN_SHELL_PRODUCT, KERNEL_PRODUCT } from 'utils/constants/product';
import SubmitButtons from '../StepperButtons/SubmitButtons';
import DriedNutInShellProductListingCard from './DriedNutInShellProductListingCard';
import KernelProductListingCard from './KernelProductListingCard';
import SimilarListings from './SimilarListings';
import './styles.scss';

const ReviewListing = () => {
  const navigate = useCustomNavigate();
  const { data: user = {} } = useGetCurrentUserQuery();
  const { productId } = useParams();
  const isSeller = isMSMSeller(user);

  const handleSubmitClick = () => {
    if (isSeller) {
      return navigate.foodSafety(productId);
    }
    return navigate.declaration(user?.role, productId);
  };

  return (
    <Fragment>
      <SubmitButtons
        userRole={user.role}
        currentStepIndex={2}
        onSaveAsDraft={() => (isMSMSeller(user) ? navigate.myOffers() : navigate.myOrders())}
        onPrevButtonClick={() => navigate.updateProduct(user.role, productId)}
        onSubmitClick={handleSubmitClick}
      />
      { localStorage.getItem('nutsTab') === DRIED_NUT_IN_SHELL_PRODUCT && (
        <DriedNutInShellProductListingCard />
      )}
      { localStorage.getItem('nutsTab') === KERNEL_PRODUCT && (
        <KernelProductListingCard />
      )}
      <SimilarListings userRole={user.role} productId={productId} />
    </Fragment>
  );
};

export default ReviewListing;
