import { apiSlice } from 'api';
import routes from 'utils/routes';
import { CONTRACTS, LISTINGS } from 'utils/tagTypes';

const contractsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getContract: builder.query({
      query: (params) => routes.contract.details(params),
      providesTags: [CONTRACTS],
    }),

    getContracts: builder.query({
      query: (params) => routes.contract.contracts(params),
      providesTags: [CONTRACTS],
    }),

    updateContractDocuments: builder.mutation({
      query: ({ contract_id, payload }) => ({
        url: routes.contract.updateContractDocuments({ contract_id }),
        method: 'PATCH',
        body: payload,
      }),
    }),

    uploadSignedDocument: builder.mutation({
      query: ({ contract_id, payload }) => ({
        url: routes.contract.uploadSignedContract({ contract_id }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error) => (error ? [] : [CONTRACTS, LISTINGS]),
    }),

    requestAssistance: builder.mutation({
      query: ({ contract_id, payload }) => ({
        url: routes.contract.requestAssistance({ contract_id }),
        method: 'POST',
        body: payload,
      }),
    }),

    getContractPdf: builder.query({
      query: (params) => ({
        url: routes.contract.getContractPdf(params),
        method: 'GET',
      }),
    }),

    uploadDraftContractPdf: builder.mutation({
      query: ({ contract_id, payload }) => ({
        url: routes.contract.uploadDraftContractPdf({ contract_id }),
        method: 'PATCH',
        body: payload,
      }),
    }),

    deleteAttachment: builder.mutation({
      query: ({ attachmentId }) => ({
        url: routes.products.attachmentDelete({ attachmentId }),
        method: 'DELETE',
      }),
    }),

    renameContractDocument: builder.mutation({
      query: ({ contract_id, new_name, document_id }) => ({
        url: routes.contract.renameContractDocument({ contract_id }),
        method: 'PATCH',
        body: {
          new_name,
          document_id,
        },
      }),
    }),

    changeContractStatus: builder.mutation({
      query: ({ contract_id, status }) => ({
        url: routes.contract.changeContractStatus({ contract_id }),
        method: 'PATCH',
        body: {
          status
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetContractQuery,
  useGetContractsQuery,
  useUploadSignedDocumentMutation,
  useRequestAssistanceMutation,
  useGetContractPdfQuery,
  useLazyGetContractPdfQuery,
  useUploadDraftContractPdfMutation,
  useUpdateContractDocumentsMutation,
  useDeleteAttachmentMutation,
  useRenameContractDocumentMutation,
  useChangeContractStatusMutation,
} = contractsApi;
