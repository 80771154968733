import i18n from 'i18n';
import {
  affiliatePartnerStaticPage,
  retailsPage,
  newsPage,
  landingPage,
  aboutUs,
  services,
  contactUs,
  support,
} from './Routes';
import { AFFILIATE_PARTNER, BUYER, SELLER, SUBSCRIPTION_MEMBER } from './userRole';

export const primaryNavContents = [
  {
    id: 1,
    name: () => i18n.t('navbar.primary.links.home_link'),
    link: () => landingPage,
    dropDownContents: null,
  },
  {
    id: 2,
    name: () => i18n.t('navbar.primary.links.about_link'),
    link: () => aboutUs,
    dropDownContents: null,
  },
  {
    id: 3,
    name: () => i18n.t('navbar.primary.links.services_link'),
    link: () => services,
    dropDownContents: null,
  },
  {
    id: 4,
    name: () => i18n.t('navbar.primary.links.affiliate_partner_link'),
    link: () => affiliatePartnerStaticPage,
    dropDownContents: null,
  },
  // { Commenting since MSM wanted to hide this for the time being, might need iun the future.
  //   id: 5,
  //   name: () => i18n.t('navbar.primary.links.esg_link'),
  //   link: () => '/esg',
  //   dropDownContents: null,
  //   isDisabled: true,
  // },
  {
    id: 6,
    name: () => i18n.t('navbar.primary.links.contact_link'),
    link: () => contactUs,
    dropDownContents: null,
  },
  {
    id: 7,
    name: () => i18n.t('navbar.primary.links.news'),
    link: () => newsPage,
    dropDownContents: null,
  },
];

export const defaultNavContents = [
  {
    id: 1,
    name: () => i18n.t('navbar.primary.links.offer_listings'),
    link: (userType) => `/${userType}/listings`,
    dropDownContents: null,
  },
  {
    id: 2,
    name: () => i18n.t('navbar.seller.links.my_business'),
    link: () => null,
    dropDownContents: [
      {
        id: 1,
        name: (userType) => i18n.t(`navbar.seller.links.${userType}.title`),
        link: (userType) => (userType === BUYER ? '/buyer/orders' : '/seller/offers'),
        isDisabled: false,
      },
      {
        id: 2,
        name: () => i18n.t('navbar.seller.dropdown_links.my_business.my_contacts'),
        link: (userType) => `/${userType}/contracts`,
      },
      {
        id: 3,
        name: () => i18n.t('navbar.seller.dropdown_links.my_business.my_bids'),
        link: (userType) => `/${userType}/bids`,
      }
    ],
  },
  {
    id: 3,
    name: () => i18n.t('navbar.seller.links.resources'),
    link: () => null,
    dropDownContents: [
      {
        id: 1,
        name: () => i18n.t('navbar.primary.links.retail_link'),
        link: () => retailsPage,
      },
      {
        id: 2,
        name: () => i18n.t('navbar.seller.dropdown_links.resources.support'),
        link: () => support,
        isDisabled: true,
      },
      {
        id: 3,
        name: () => i18n.t('navbar.primary.links.contact_link'),
        link: () => contactUs,
        isDisabled: true,
      },
    ],
  },
];

export const subscriptionMemberNavContents = [
  {
    id: 1,
    name: () => i18n.t('navbar.primary.links.offer_listings'),
    link: (userType) => `/${userType}/listings`,
    dropDownContents: null,
  },
  {
    id: 2,
    name: () => i18n.t('navbar.seller.links.resources'),
    link: () => null,
    dropDownContents: [
      {
        id: 1,
        name: () => i18n.t('navbar.primary.links.retail_link'),
        link: () => retailsPage,
      },
      {
        id: 2,
        name: () => i18n.t('navbar.seller.dropdown_links.resources.support'),
        link: () => support,
        isDisabled: true,
      },
      {
        id: 3,
        name: () => i18n.t('navbar.primary.links.contact_link'),
        link: () => contactUs,
        isDisabled: true,
      },
    ],
  },
]

export const partnerNavContents = [
  {
    id: 1,
    name: () => i18n.t('navbar.primary.links.my_affiliations'),
    link: () => '/partner/portal',
    dropDownContents: null,
  },
];

export const avatarDropdownContent = [
  {
    id: 1,
    icon: 'person',
    name: () => i18n.t('navbar.avatar.profile'),
  },
];

export const navContentByRole = {
  [SELLER]: defaultNavContents,
  [BUYER]: defaultNavContents,
  [SUBSCRIPTION_MEMBER]: subscriptionMemberNavContents,
  [AFFILIATE_PARTNER]: partnerNavContents,
}
