import { useEffect } from 'react';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetCompanyQuery } from 'api/companies';
import { ADMIN, BUYER, AFFILIATE_PARTNER, SELLER, SUBSCRIPTION_MEMBER } from 'utils/constants/userRole';
import useCustomNavigate from './useCustomNavigate';

const useAuth = () => {
  const {
    data: user,
    isFetching: isUserFetching,
    isSuccess: isUserSuccess,
  } = useGetCurrentUserQuery();
  const {
    data: companyDetails = {},
    isFetching: isCompanyFetching,
    isSuccess: isCompanySuccess,
  } = useGetCompanyQuery({ id: user?.company_id }, { skip: !user?.id });

  const isFetching = isUserFetching || isCompanyFetching;
  const isSuccess = isUserSuccess && isCompanySuccess;

  const isAuthenticated = !!user && isSuccess;
  const navigate = useCustomNavigate();

  const handleNavigationAfterLogin = (userRole, verificationStatus) => {
    if (userRole === ADMIN) {
      return navigate.adminProfile();
    }

    if (userRole === AFFILIATE_PARTNER) {
      return navigate.affiliatePartnerProfile();
    }

    if (verificationStatus === 'verified') {
      return navigate.listings(userRole);
    }

    if (userRole === SELLER) {
      return navigate.sellerProfile();
    }

    if (userRole === BUYER) {
      return navigate.buyerprofile();
    }

    if (userRole === SUBSCRIPTION_MEMBER) {
      return navigate.subscriptionMemberProfile();
    }

    return null;
  };

  useEffect(() => {
    if (!isSuccess || isFetching) {
      return;
    }

    const { role: userRole } = user;
    const { status: verificationStatus } = companyDetails;

    handleNavigationAfterLogin(userRole, verificationStatus);
  }, [isFetching, isSuccess]);

  return { isAuthenticated, isFetching, userRole: user?.role, user };
};

export default useAuth;
