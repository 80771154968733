import React, { useContext } from 'react';
import { t } from 'i18next';
import useCustomNavigate from 'hooks/useCustomNavigate';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import './styles.scss';

const EditListingOption = () => {
  const navigate = useCustomNavigate();

  const { currentUserInformation, productInformation } = useContext(ListingDetailsContext);
  const onClickEditListing = () =>
    navigate.updateProduct(currentUserInformation.role, productInformation.id);

  return (
    <button
      onClick={onClickEditListing}
      className="listing-details-breadcrumb__edit-listing"
      type="button"
    >
      {t('bidding_process.edit_listing')}
    </button>
  );
};

export default EditListingOption;
