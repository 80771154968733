import React, { useContext, useRef, useState } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import classNames from 'classnames';
import { formatPrice } from 'utils/helper/Charts';
import Modal from 'components/BaseComponents/Modal';
import { useAcceptOfferMutation, useRetractBidMutation } from 'api/listings';
import useCustomNavigate from 'hooks/useCustomNavigate';
import ToastNotification from 'components/Shared/ToastNotification';
import generateAndUploadPdf from 'utils/helper/generateAndUploadPdf';
import CustomToastify from 'components/Shared/ToastNotification/CustomToastify';
import { useLazyGetContractPdfQuery, useUploadDraftContractPdfMutation } from 'api/contracts';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import PlaceCounterBidModal from 'components/BiddingProcess/ListingDetails/ListingInformationBody/ActionPanel/PlaceCounterBidModal';
import NeedProductPermissionsIcon from 'components/Shared/BiddingProcess/NeedProductPermissionsIcon';
import hasCompanyPermissions from 'utils/helper/companyPermissionsValidation';
import './styles.scss';
import RetractBidConfirmationModal from './RetractBidConfirmationModal';

const CurrentBid = () => {
  const navigate = useCustomNavigate();
  const toastRef = useRef();

  const [isBidModalVisible, setIsBidModalVisible] = useState(false);
  const [isRetractModalVisible, setIsRetractModalVisible] = useState(false);
  const [isLoadingAcceptRequest, setIsLoadingAcceptRequest] = useState(false);

  const [acceptOffer] = useAcceptOfferMutation();
  const [retractBid, { isLoading: isRetracting }] = useRetractBidMutation();
  const [getContractPdf, { data: contractPdfData }] = useLazyGetContractPdfQuery();
  const [uploadDraftContractPdf, { isSuccess: isPdfUploaded }] =
    useUploadDraftContractPdfMutation();

  const {
    isUserAdmin,
    isUserBuyer,
    isUserSubscriptionMember,
    listingInformation,
    currentUserInformation,
    isListingCreator,
    isOrderListing,
    isListingInContract,
    isListingPublished,
    isListingUnPublished,
    isUserSeller,
    isOfferListing,
    productInformation,
    companyDetails,
  } = useContext(ListingDetailsContext);

  const onBidModalOpen = () => setIsBidModalVisible(true);
  const onBidModalClose = () => setIsBidModalVisible(false);
  const onRetractModalOpen = () => setIsRetractModalVisible(true);
  const onRetractModalClose = () => setIsRetractModalVisible(false);

  const { role: userRole } = currentUserInformation;
  const { type: productType } = productInformation;

  const {
    currentBidderId, 
    totalBidCount = 0,
    bidCanBeAcceptedAt,
  } = listingInformation;
  const isCurrentBidder = currentBidderId === currentUserInformation.id
  const totalBidsCount = t('bidding_process.total_bids', {
    totalBidCount,
  });
  const placeBidText = totalBidCount
    ? t('bidding_process.place_counter_bid')
    : t('bidding_process.place_bid');
  const highestBidPrice = `USD ${formatPrice(listingInformation.highestBidPrice) || 0.0}`;
  const hasNoActionButton = isUserAdmin || isUserSubscriptionMember || isListingInContract;
  const canViewAcceptBidButton =
    currentBidderId && isListingCreator && !isUserAdmin && !isListingInContract;
  const canViewPlaceBidButton = !isListingCreator && !isUserAdmin && !isListingInContract && !isUserSubscriptionMember;
  const hasPermissionToPlaceBid = hasCompanyPermissions(userRole, productType, companyDetails);
  const canNotPlaceBid =
    (isOfferListing && isUserSeller) ||
    (isOrderListing && isUserBuyer) ||
    isListingUnPublished ||
    isListingInContract ||
    !hasPermissionToPlaceBid;
  const canViewRetractButton = isCurrentBidder && isListingPublished;
  const canAcceptBid = !bidCanBeAcceptedAt;
  const isAcceptBidButtonDisabled = !canAcceptBid || isLoadingAcceptRequest;

  const onAcceptBid = async () => {
    try {
      setIsLoadingAcceptRequest(true);

      const acceptResponse = await acceptOffer({ id: listingInformation.id });
      const pdfResponse = await getContractPdf({
        contract_id: acceptResponse?.data?.contract?.id,
      });

      await generateAndUploadPdf(
        pdfResponse?.data?.contractId,
        pdfResponse?.data,
        uploadDraftContractPdf,
      );
    } catch (error) {
      setIsLoadingAcceptRequest(false);
      toastRef?.current?.notify(
        <CustomToastify type="error" message={t('bidding_process.something_went_wrong')} />,
      );
    }
  };

  const onRetractBid = () => {
    retractBid({ id: listingInformation.id })
      .unwrap()
      .then(() => {
        toastRef?.current?.notify(
          <CustomToastify type="success" message={t('bidding_process.retract_bid.success')} />,
        )
      })
      .catch(() => {
        toastRef?.current?.notify(
          <CustomToastify type="error" message={t('bidding_process.something_went_wrong')} />,
        )
      });
  };

  if (isPdfUploaded) {
    navigate.contractDetails(userRole, contractPdfData?.contractId);
  }

  return (
    <div className="current-bid">
      <div className="current-bid-header">
        <div className="current-bid-header-title">{t('bidding_process.current_bid')}</div>
        <div className="current-bid-header-bids">{totalBidsCount}</div>
      </div>
      <div className="current-bid-body">
        {totalBidCount && currentBidderId ? (
          <div
            className={classNames('current-bid-body-data', {
              'current-bid-body-data--admin-portal': hasNoActionButton,
            })}
          >
            <span className="current-bid-body-data--currency">{highestBidPrice}</span>
            <span className="current-bid-body-data--unit">/{t('bidding_process.per_kg')}</span>
            {isCurrentBidder &&
              <span className="current-bid-body-data--your-bid">
                {t('bidding_process.your_bid')}
              </span>
            }
          </div>
        ) : (
          <div
            className={classNames('current-bid-body-supporting-text', {
              'current-bid-body-supporting-text--no-bid': isListingCreator || hasNoActionButton,
            })}
          >
            {
              (totalBidCount && t('bidding_process.no_active_bid')) ||
              (!canViewPlaceBidButton
                ? t('bidding_process.no_bid_yet')
                : t('bidding_process.be_the_first_one_to_bid'))
            }
          </div>
        )}

        {canViewRetractButton && (
          <button
            type="button"
            disabled={isRetracting}
            className={classNames('current-bid-body-retract-bid-button', {
              'current-bid-body-retract-bid-button--disabled': isRetracting,
            })}
            onClick={onRetractModalOpen}
          >
            {t('bidding_process.retract_bid.button')}
          </button>
        )}

        {canViewAcceptBidButton ? (
          <button
            className={classNames('current-bid-body--accept-bid', {
              'current-bid-body--accept-bid__disabled': isAcceptBidButtonDisabled,
            })}
            type="button"
            disabled={isAcceptBidButtonDisabled}
            onClick={onAcceptBid}
          >
            {t('bidding_process.accept_bid')}
          </button>

        ) : null}

        {canViewAcceptBidButton && !canAcceptBid ? (
          <div className='current-bid-body--accept-bid-retract-note'>
            {
              t('bidding_process.retract_bid.accept_note', {
                date: moment.parseZone(bidCanBeAcceptedAt).locale('en').format('HH:mm, DD MMM YYYY')
              })
            }
          </div>
        ) : null}

        {canViewPlaceBidButton && (
          <button
            type="button"
            disabled={canNotPlaceBid}
            className={classNames('current-bid-body-counter-bid-button', {
              'current-bid-body-counter-bid-button--disabled': canNotPlaceBid,
            })}
            onClick={onBidModalOpen}
          >
            {placeBidText}
            {!hasPermissionToPlaceBid &&
              <NeedProductPermissionsIcon type={productType} />
            }
          </button>
        )}

        <Modal
          isVisible={isBidModalVisible}
          onOutsideClick={onBidModalClose}
          className="progress-card-modal"
        >
          <Modal.Content>
            <PlaceCounterBidModal onModalClose={onBidModalClose} />
          </Modal.Content>
        </Modal>

        <Modal
          isVisible={isRetractModalVisible}
          onOutsideClick={onRetractModalClose}
          className="progress-card-modal"
        >
          <Modal.Content>
            <RetractBidConfirmationModal onModalClose={onRetractModalClose} onRetractConfirm={onRetractBid} />
          </Modal.Content>
        </Modal>

        <ToastNotification toastRef={toastRef} />
      </div>
    </div>
  );
};

export default CurrentBid;
