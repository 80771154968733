import React, { Fragment, useRef } from 'react';
import { affiliatePartnerProgressCardDetails } from 'utils/constants/progressCardDetails';
import { getAffiliatePartnerTabs } from 'utils/helper/Portals';
import { PERSONAL_DETAILS_FORM_KEY } from 'utils/constants/portals';
import { RTabs } from 'components/BaseComponents/RTabs';
import PortalsNavbar from 'components/NavBar/PortalsNavBar';
import ProgressCard from 'components/Shared/ProgressCard';
import AvatarCard from 'components/Shared/Portals/Avatar';
import ToastNotification from 'components/Shared/ToastNotification';
import usePortal from 'hooks/usePortal';

const AffiliatePartnerProfile = () => {
  const isAdmin = false;
  const toastRef = useRef();

  const {
    userId,
    companyId,
    personalDetailsPercentage,
    businessDetailsPercentage,
    companyDetails,
    userDetails,
    isEditable,
    canUploadAvatar,
  } = usePortal(isAdmin);

  if (!userId) {
    return null;
  }

  const partnerPortalTabs = getAffiliatePartnerTabs({
    isAdmin,
    userId,
    personalDetailsPercentage,
    businessDetailsPercentage,
    companyId,
    companyDetails,
    userDetails,
    isEditable,
    toastRef,
  });

  return (
    <Fragment>
      <PortalsNavbar />
      <div className="portal-profile">
        <AvatarCard
          containerClassName="portal-profile__avatar-container margin-x-auto border-t-1 border-gray-200 border-t-solid"
          userId={userId}
          isEditable={isEditable}
          canUploadAvatar={canUploadAvatar}
          toastRef={toastRef}
        />
        {userId && companyId && (
          <div className="flex justify-center">
            <ProgressCard
              userId={userId}
              companyId={companyId}
              details={affiliatePartnerProgressCardDetails}
              className="flex justify-center"
            />
          </div>
        )}
        <RTabs
          containerClass="portal-profile__tabs margin-x-auto margin-t-24px"
          isVertical={true}
          items={partnerPortalTabs}
          selectedTabKey={PERSONAL_DETAILS_FORM_KEY}
        />
      </div>
      <ToastNotification toastRef={toastRef} />
    </Fragment>
  );
};

export default AffiliatePartnerProfile;
