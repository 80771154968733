import React, { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { useRevokeUserMutation, useVerifyUserMutation, useRejectUserMutation } from 'api/admin';
import useUserManagement from 'hooks/useUserManagement';
import { getAdminUserManagementTabs } from 'utils/helper/Portals';
import breadcrumbRoutes from 'utils/breadcrumb-helper/route-names';
import { AFFILIATE_PARTNER, SELLER, SUBSCRIPTION_MEMBER } from 'utils/constants/userRole';
import { PERSONAL_DETAILS_FORM_KEY } from 'utils/constants/portals';
import Modal from 'components/BaseComponents/Modal';
import Icon from 'components/BaseComponents/Icon';
import Button from 'components/BaseComponents/Button';
import { RTabs } from 'components/BaseComponents/RTabs';
import AvatarCard from 'components/Shared/Portals/Avatar';
import AdminNavBar from 'components/NavBar/AdminNavBar';
import MyAffiliationsTable from 'components/AffiliatePartner/AffiliationsTable';
import UserAdditionalDocumentModal from './AdditionalDocModalBody';
import AggregateCSVModal from './AggregateCSVModalBody';
import ActionConfirmationModalBody from './ActionConfirmationModal';
import './styles.scss';

const UserProfile = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const userId = Number(id);
  const [showProfile, setShowProfile] = useState(true);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    isAdmin,
    isUserVerified,
    adminId,
    companyId,
    companyDetails,
    canEditForm,
    showApproveButtons,
    userType,
    userDetails,
  } = useUserManagement(userId);
  const [verifyUser, { isLoading: isVerifyUserSubmitting }] = useVerifyUserMutation();
  const [revokeUser, { isLoading: isRevokeUserSubmitting }] = useRevokeUserMutation();

  const onModalOpen = () => setIsModalVisible(true);
  const onModalClose = () => setIsModalVisible(false);

  const handleToggleProfileAndAffiliations = useCallback(() => {
    setShowProfile((prevState) => !prevState);
  }, []);

  const adminPortalTabs = getAdminUserManagementTabs({
    isAdmin,
    userId,
    companyId,
    companyDetails,
    isEditable: canEditForm,
    userType,
    userDetails,
  });

  const canImportCSV = userType === SELLER && isUserVerified;
  const showAffiliationsButton = userType === AFFILIATE_PARTNER && isUserVerified;
  const showRevokeButton = userType === SUBSCRIPTION_MEMBER && isUserVerified;

  const [rejectUserMutation, { isLoading: isRejectUserSubmitting }] = useRejectUserMutation();
  const [userIdToReject, setUserIdToReject] = useState(null);
  const [isActionConfirmationModalVisible, setIsActionConfirmationModalVisible] = useState(false);

  const handleModalOpen = () => {
    setIsActionConfirmationModalVisible(true);
  };

  const handleModalClose = () => {
    setIsActionConfirmationModalVisible(false);
  };

  const rejectUser = () =>
    rejectUserMutation({ userId: userIdToReject })
      .unwrap()
      .then(() => {
        window.location.href = '/admin/users';
      })
      .catch(({ data: { errors } }) => errors);

  return (
    <div className="portal-profile">
      <div className="flex justify-between portal-profile__container border-b-1 border-gray-200 border-b-solid">
        <AvatarCard
          userId={userId}
          adminId={adminId}
          isEditable={canEditForm}
          containerClassName="portal-profile__avatar-container"
          canUploadAvatar={false}
        />
        <div className="portal-profile__verification-buttons flex items-center gap-12px">
          {showAffiliationsButton && (
            <Button
              label={
                showProfile
                  ? t('admin.affiliate_partners.affiliations_button_label')
                  : t('admin.affiliate_partners.profile_button_label')
              }
              onClick={handleToggleProfileAndAffiliations}
              className="portal-profile__verification-buttons--request"
            />
          )}
          {showApproveButtons && (
            <Fragment>
              <Button
                label={t('admin.request_modal_body.title')}
                onClick={onModalOpen}
                className="portal-profile__verification-buttons--request"
              />
              <Button
                label={t('admin.request_modal_body.approve_button_label')}
                type="success"
                onClick={async () => verifyUser({ userId })}
                disabled={isVerifyUserSubmitting}
                className="portal-profile__verification-buttons--approve"
              />
              <Button
                label={t('admin.request_modal_body.reject_button_label')}
                type="danger"
                onClick={() => {
                  setUserIdToReject(userId);
                  handleModalOpen();
                }}
                disabled={isRejectUserSubmitting}
                className="portal-profile__verification-buttons--reject"
              />
            </Fragment>
          )}
          {showRevokeButton && (
            <Button
              label={t('admin.request_modal_body.revoke_button_label')}
              type="danger"
              onClick={async () => revokeUser({ userId })}
              disabled={isRevokeUserSubmitting}
              className="portal-profile__verification-buttons--approve"
            />
          )}
          {canImportCSV && (
            <Button
              label={t('admin.csv_modal_body.import_aggregated_csv_button_label')}
              type="success"
              icon={<Icon name="cloudUpload" size="normal" />}
              onClick={onModalOpen}
              className="portal-profile__verification-buttons--approve"
            />
          )}
        </div>
      </div>

      <Modal
        isVisible={isModalVisible}
        onOutsideClick={onModalClose}
        className="progress-card-modal"
      >
        <Modal.Content>
          {canImportCSV ? (
            <AggregateCSVModal userId={userId} />
          ) : (
            <UserAdditionalDocumentModal adminId={adminId} handleModalClose={onModalClose} />
          )}
        </Modal.Content>
      </Modal>

      {showProfile ? (
        <RTabs
          containerClass="portal-profile__tabs margin-x-auto margin-t-24px"
          isVertical={true}
          items={adminPortalTabs}
          selectedTabKey={PERSONAL_DETAILS_FORM_KEY}
        />
      ) : (
        <div className="portal-profile__table">
          <MyAffiliationsTable />
        </div>
      )}

      <Modal
        isVisible={isActionConfirmationModalVisible}
        onOutsideClick={handleModalClose}
        className="progress-card-modal"
      >
        <Modal.Content>
          <ActionConfirmationModalBody
            onModalClose={handleModalClose}
            action={rejectUser}
            actionText={t('admin.action_confirmation_modal_body.reject_action')}
            type="danger"
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

const UserProfilePage = () => {
  const breadcrumbs = useReactRouterBreadcrumbs(breadcrumbRoutes);

  return (
    <AdminNavBar breadcrumbs={breadcrumbs}>
      <section>
        <UserProfile />
      </section>
    </AdminNavBar>
  );
};

export default UserProfilePage;
