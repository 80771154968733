import { DRIED_NUT_IN_SHELL_PRODUCT, KERNEL_PRODUCT } from 'utils/constants/product';
import { isMSMSeller } from './UserRoleValidation';

const hasCompanyPermissions = (role, type, company) => {
  const companyPermissions = company?.company_permissions;
  if (!companyPermissions) {
    return false;
  }

  const {
    trade_kernel: canTradeKernel,
    trade_dried_nut_in_shell: canTradeDriedNutInShell,
  } = companyPermissions;

  return !isMSMSeller({ role }) ||
    (type === KERNEL_PRODUCT && canTradeKernel) ||
    (type === DRIED_NUT_IN_SHELL_PRODUCT && canTradeDriedNutInShell)
};

export default hasCompanyPermissions;
