import React, { useContext } from 'react';
import { t } from 'i18next';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import FoodSafetyDetails from 'components/BiddingProcess/ListingDetails/ListingInformationBody/DetailsBody/FoodSafetyDetails';
import DnisFoodSafetyDetails from 'components/BiddingProcess/ListingDetails/ListingInformationBody/DetailsBody/DnisFoodSafetyDetails';
import ConsignmentDetails from 'components/BiddingProcess/ListingDetails/ListingInformationBody/DetailsBody/ConsignmentDetails';
import ListingDetailsHeader from 'components/BiddingProcess/ListingDetails/ListingInformationBody/DetailsBody/ListingDetailsHeader';
import './styles.scss';

const DetailsBody = () => {
  const {
    foodSafetyInformation,
    productInformation,
    currentUserInformation,
    listingInformation,
    isOrderListing,
  } = useContext(ListingDetailsContext);

  const {
    offerVolume,
    nutGrade,
    nutStyle,
    seasonHarvested,
    seasonProcessed,
    regionOfOrigin,
    countryOfOrigin,
    minimumFoodCertificate,
    brcLevel,
    bestBeforeDate,
    containerLoad,
    estimatedShippingDate,
    paymentOptions,
    consignmentIdentifier,
    boxesQuantity,
    type,
    size,
    soundKernelRecovery,
    unsoundKernelRecovery,
    treeVariant,
    halaal,
    kosher,
    pasteurized,
  } = productInformation;

  const productDetailsData = {
    nutStyle,
    nutGrade,
    seasonHarvested,
    seasonProcessed,
    countryOfOrigin,
    regionOfOrigin,
    bestBeforeDate,
    minimumFoodCertificate,
    brcLevel,
    offerVolume,
    boxesQuantity,
    soundKernelRecovery,
    unsoundKernelRecovery,
    treeVariant,
    type,
    size,
    halaal,
    kosher,
    pasteurized,
  };

  const logisticsDetailsData = {
    containerLoad,
    estimatedShippingDate,
    consignmentIdentifier,
    paymentOptions,
  };

  const {
    consignment_value,
    organic,
    num_of_batch,
    e_coli_test_method,
    e_coli_test_value,
    salmonella_test_method,
    salmonella_test_value,
    plate_count_test_method,
    plate_count_test_value,
    yeast_test_method,
    yeast_test_value,
    mould_test_method,
    mould_test_value,
    coliforms_0_4_test_method,
    coliforms_0_4_test_value,
    coliforms_5_8_test_method,
    coliforms_5_8_test_value,
    lysteria_monocytogenes_test_method,
    lysteria_monocytogenes_test_value,
    staphylococcus_aureus_test_method,
    staphylococcus_aureus_test_value,
    enterobacteria_test_method,
    enterobacteria_test_value,
    b_cereus_test_method,
    b_cereus_test_value,
    total_aflatoxin_test_method,
    total_aflatoxin_test_value,
    aflatoxin_b1_test_method,
    aflatoxin_b1_test_value,
    free_fatty_acid_test_method,
    free_fatty_acid_test_value,
    peroxide_value2_test_method,
    peroxide_value2_test_value,
    peroxide_value1_test_method,
    peroxide_value1_test_value,
    lead_test_method,
    lead_test_value,
    mercury_test_method,
    mercury_test_value,
    cadmium_test_method,
    cadmium_test_value,
    arsenic_test_method,
    arsenic_test_value,
    compliance_institute,
  } = foodSafetyInformation || {};

  const microbiologicalTestResultData = [
    {
      id: 1,
      testItem: 'E-coli',
      testMethod: e_coli_test_method?.label,
      testCount: e_coli_test_value,
      maxLimit: e_coli_test_method?.maxLimit,
    },
    {
      id: 2,
      testItem: 'Salmonella',
      testMethod: salmonella_test_method?.label,
      testCount: salmonella_test_value,
      maxLimit: salmonella_test_method?.maxLimit,
    },
    {
      id: 3,
      testItem: 'Plate Count',
      testMethod: plate_count_test_method?.label,
      testCount: plate_count_test_value,
      maxLimit: plate_count_test_method?.maxLimit,
    },
    {
      id: 4,
      testItem: 'Yeast',
      testMethod: yeast_test_method?.label,
      testCount: yeast_test_value,
      maxLimit: yeast_test_method?.maxLimit,
    },
    {
      id: 5,
      testItem: 'Mould',
      testMethod: mould_test_method?.label,
      testCount: mould_test_value,
      maxLimit: mould_test_method?.maxLimit,
    },
    {
      id: 6,
      testItem: 'Coliforms (Style 0-4)',
      testMethod: coliforms_0_4_test_method?.label,
      testCount: coliforms_0_4_test_value,
      maxLimit: coliforms_0_4_test_method?.maxLimit,
    },
    {
      id: 6,
      testItem: 'Coliforms (Style 5-8)',
      testMethod: coliforms_5_8_test_method?.label,
      testCount: coliforms_5_8_test_value,
      maxLimit: coliforms_5_8_test_method?.maxLimit,
    },
    {
      id: 6,
      testItem: 'Lysteria Monocytogenes',
      testMethod: lysteria_monocytogenes_test_method?.label,
      testCount: lysteria_monocytogenes_test_value,
      maxLimit: lysteria_monocytogenes_test_method?.maxLimit,
    },
    {
      id: 6,
      testItem: 'Staphylococcus aureus',
      testMethod: staphylococcus_aureus_test_method?.label,
      testCount: staphylococcus_aureus_test_value,
      maxLimit: staphylococcus_aureus_test_method?.maxLimit,
    },
    {
      id: 6,
      testItem: 'Enterobacteria',
      testMethod: enterobacteria_test_method?.label,
      testCount: enterobacteria_test_value,
      maxLimit: enterobacteria_test_method?.maxLimit,
    },
    {
      id: 6,
      testItem: 'B. cereus',
      testMethod: b_cereus_test_method?.label,
      testCount: b_cereus_test_value,
      maxLimit: b_cereus_test_method?.maxLimit,
    },
  ];

  const chemicalTestResultData = [
    {
      id: 1,
      testItem: 'Total Aflatoxin',
      testMethod: total_aflatoxin_test_method?.label,
      testCount: total_aflatoxin_test_value,
      maxLimit: total_aflatoxin_test_method?.maxLimit,
    },
    {
      id: 2,
      testItem: 'Aflatoxin B1',
      testMethod: aflatoxin_b1_test_method?.label,
      testCount: aflatoxin_b1_test_value,
      maxLimit: aflatoxin_b1_test_method?.maxLimit,
    },
    {
      id: 3,
      testItem: 'Free Fatty Acid',
      testMethod: free_fatty_acid_test_method?.label,
      testCount: free_fatty_acid_test_value,
      maxLimit: free_fatty_acid_test_method?.maxLimit,
    },
    {
      id: 4,
      testItem: 'Peroxide Value 1 year shelf-life',
      testMethod: peroxide_value1_test_method?.label,
      testCount: peroxide_value1_test_value,
      maxLimit: peroxide_value1_test_method?.maxLimit,
    },
    {
      id: 5,
      testItem: 'Peroxide Value 2 years shelf-life',
      testMethod: peroxide_value2_test_method?.label,
      testCount: peroxide_value2_test_value,
      maxLimit: peroxide_value2_test_method?.maxLimit,
    },
  ];

  const heavyMetalTestResultData =
    !lead_test_method?.label &&
    !mercury_test_method?.label &&
    !cadmium_test_method?.label &&
    !arsenic_test_method?.label
      ? []
      : [
          {
            id: 1,
            testItem: 'Lead',
            testMethod: lead_test_method?.label,
            testCount: lead_test_value,
            maxLimit: lead_test_method?.maxLimit,
          },
          {
            id: 2,
            testItem: 'Mercury',
            testMethod: mercury_test_method?.label,
            testCount: mercury_test_value,
            maxLimit: mercury_test_method?.maxLimit,
          },
          {
            id: 3,
            testItem: 'Cadmium',
            testMethod: cadmium_test_method?.label,
            testCount: cadmium_test_value,
            maxLimit: cadmium_test_method?.maxLimit,
          },
          {
            id: 4,
            testItem: 'Arsenic',
            testMethod: arsenic_test_method?.label,
            testCount: arsenic_test_value,
            maxLimit: arsenic_test_method?.maxLimit,
          },
        ].filter((item) => item.testMethod);

    const organicData = [
      {
        label: t('bidding_process.organic'),
        data: organic ? t('shared.product.organic.yes') : t('shared.product.organic.no'),
      },
    ];

  const batchDetailsData = [
    {
      label: t('bidding_process.consignment_pasteurized'),
      data: consignment_value === 'true' ? t('bidding_process.yes') : t('bidding_process.no'),
    },
    { label: t('bidding_process.total_batches_in_consignment'), data: num_of_batch },
  ];

  const dnisCertifications = [
    {
      label: t('bidding_process.global_gap_certificate'),
      data: foodSafetyInformation?.global_gap_certificate,
    },
    {
      label: t('bidding_process.ppecb_certificate'),
      data: foodSafetyInformation?.ppecb_certificate,
    },
    {
      label: t('bidding_process.esg_certificate'),
      data: foodSafetyInformation?.esg_certificate,
    },
  ];

  const isCurrentUserCreator = currentUserInformation?.id === listingInformation?.creator?.id;

  const enableFoodSafetyDetails = !isOrderListing && foodSafetyInformation.productId;

  return (
    <div className="details-body details-body--buyer-seller">
      <ListingDetailsHeader
        offerVolume={productDetailsData?.offerVolume}
        nutGrade={productDetailsData?.nutGrade}
        nutStyle={productDetailsData?.nutStyle}
        countryOfOrigin={productDetailsData.countryOfOrigin}
      />

      <ConsignmentDetails
        productDetails={productDetailsData}
        logisticsDetails={logisticsDetailsData}
      />

      {enableFoodSafetyDetails && productDetailsData?.type === 'KernelProduct' ? (
        <FoodSafetyDetails
          batchDetails={batchDetailsData}
          organicData={organicData}
          microbiologicalTestResult={microbiologicalTestResultData}
          chemicalTestResult={chemicalTestResultData}
          heavyMetalTestResult={heavyMetalTestResultData}
          complianceInstitute={compliance_institute}
        />
      ) : null}

      {enableFoodSafetyDetails && productDetailsData?.type === 'DriedNutInShellProduct' ? (
        <DnisFoodSafetyDetails
          organicData={organicData}
          dnisCertifications={dnisCertifications}
          isCurrentUserCreator={isCurrentUserCreator}
        />
      ) : null}
    </div>
  );
};

export default DetailsBody;
