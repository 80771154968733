import React, { useContext, useRef, useState } from 'react';
import { t } from 'i18next';
import Button from 'components/BaseComponents/Button';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import { useFollowListingMutation, useUnfollowListingMutation } from 'api/listings';
import CustomToastify from 'components/Shared/ToastNotification/CustomToastify';
import ToastNotification from 'components/Shared/ToastNotification';
import './styles.scss';
import Icon from 'components/BaseComponents/Icon';

const FollowListing = () => {
  const toastRef = useRef();

  const { listingInformation } = useContext(ListingDetailsContext);
  const { id, category, following } = listingInformation;
  const [isFollowing, setIsFollowing] = useState(following);

  const [followListing, { isLoading: isFollowSubmitting }] = useFollowListingMutation();
  const [unfollowListing,  { isLoading: isUnfollowSubmitting }] = useUnfollowListingMutation();

  const onFollowListing = () => {
    followListing({ id })
      .unwrap()
      .then(() => {
        setIsFollowing(true);
        toastRef?.current?.notify(
          <CustomToastify type="success" message={t('bidding_process.follow_listing.success')} />,
        );
      })
      .catch(() => {
        toastRef?.current?.notify(
          <CustomToastify type="error" message={t('bidding_process.something_went_wrong')} />,
        );
      });
  }

  const onUnfollowListing = () => {
    unfollowListing({ id })
      .unwrap()
      .then(() => {
        setIsFollowing(false);
        toastRef?.current?.notify(
          <CustomToastify type="success" message={t('bidding_process.unfollow_listing.success')} />,
        );
      })
      .catch(() => {
        toastRef?.current?.notify(
          <CustomToastify type="error" message={t('bidding_process.something_went_wrong')} />,
        );
      });
  }

  const onButtonClick = () => isFollowing ? onUnfollowListing() : onFollowListing();

  const isButtonDisabled = isFollowSubmitting || isUnfollowSubmitting;
  const buttonClassName = isFollowing
    ? "listing-details-breadcrumb__unfollow-listing"
    : "listing-details-breadcrumb__follow-listing"
  const buttonLabel = isFollowing
    ? t(`bidding_process.unfollow_listing.${category}`)
    : t(`bidding_process.follow_listing.${category}`)

  return (
    <>
      <Button
        type="button"
        onClick={onButtonClick}
        className={buttonClassName}
        label={buttonLabel}
        disabled={isButtonDisabled}
        icon={<Icon name='star' color="default" size="tiny" className={`${buttonClassName}-icon`}/>}
      />

      <ToastNotification toastRef={toastRef} />
    </>
  );
};

export default FollowListing;
