import React, { useContext, useRef, useState } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { formatPrice } from 'utils/helper/Charts';
import Icon from 'components/BaseComponents/Icon';
import { useAcceptOfferMutation } from 'api/listings';
import useCustomNavigate from 'hooks/useCustomNavigate';
import CheckBox from 'components/BaseComponents/CheckBox';
import IconButton from 'components/BaseComponents/IconButton';
import ToastNotification from 'components/Shared/ToastNotification';
import generateAndUploadPdf from 'utils/helper/generateAndUploadPdf';
import CustomToastify from 'components/Shared/ToastNotification/CustomToastify';
import { useLazyGetContractPdfQuery, useUploadDraftContractPdfMutation } from 'api/contracts';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import './styles.scss';

const AcceptOfferOrderModal = ({ onModalClose }) => {
  const navigate = useCustomNavigate();
  const toastRef = useRef();
  const [isLoadingAcceptRequest, setIsLoadingAcceptRequest] = useState(false);
  const [isTermsAcknowledged, setIsTermsAcknowledged] = useState(false);

  const [getContractPdf, { data: contractPdfData }] = useLazyGetContractPdfQuery();
  const [acceptOffer] = useAcceptOfferMutation();
  const [uploadDraftContractPdf, { isSuccess: isPdfUploaded }] =
    useUploadDraftContractPdfMutation();

  const { currentUserInformation, productInformation, isOrderListing, listingInformation } =
    useContext(ListingDetailsContext);

  const { fullBatchPrice, offerPrice, offerVolume } = productInformation;
  const totalBatchPrice = `USD ${fullBatchPrice}`;
  const totalBatchPriceBreakdown = `(${offerVolume} kg x USD ${formatPrice(offerPrice)} per kg)`;
  const modalTitle = isOrderListing
    ? t('bidding_process.accept_order')
    : t('bidding_process.accept_offer');
  const isAcceptOfferButtonDisabled = !isTermsAcknowledged || isLoadingAcceptRequest;

  const onCheckboxClick = () => setIsTermsAcknowledged((prev) => !prev);

  const onAcceptOffer = async () => {
    try {
      setIsLoadingAcceptRequest(true);

      const acceptResponse = await acceptOffer({ id: listingInformation.id });
      const pdfResponse = await getContractPdf({ contract_id: acceptResponse?.data?.contract?.id });

      await generateAndUploadPdf(
        pdfResponse?.data?.contractId,
        pdfResponse?.data,
        uploadDraftContractPdf,
      );
    } catch (error) {
      setIsLoadingAcceptRequest(false);
      toastRef?.current?.notify(
        <CustomToastify type="error" message={t('bidding_process.something_went_wrong')} />,
      );
    }
  };

  if (isPdfUploaded) {
    onModalClose();
    navigate.contractDetails(currentUserInformation.role, contractPdfData?.contractId);
  }

  return (
    <div className="accept-offer-modal">
      <div className="accept-offer-modal__header">
        <div className="accept-offer-modal__header--text">{modalTitle}</div>
        <IconButton
          className="accept-offer-modal__header--close-icon"
          icon={<Icon name="close" />}
          size="tiny"
          onClick={onModalClose}
        />
      </div>
      <div className="accept-offer-modal__total">
        <div className="flex justify-between">
          <div className="accept-offer-modal__total--text">{t('bidding_process.total')}</div>
          <div className="accept-offer-modal__total--price">{totalBatchPrice}</div>
        </div>
        <div className="accept-offer-modal__total--breakdown">{totalBatchPriceBreakdown}</div>
      </div>

      <div className="accept-offer-modal__acknowledgement">
        <CheckBox
          className="accept-offer-modal__acknowledgement-checkbox"
          disabled={false}
          isChecked={isTermsAcknowledged}
          onChange={onCheckboxClick}
          size="small"
        />
        <div className="accept-offer-modal__acknowledgement-text">
          {t('bidding_process.acknowledgement_text1')}
          <Link to="/terms">
            <span className="accept-offer-modal__acknowledgement-text--bold">
              {t('bidding_process.acknowledgement_text2')}
            </span>
          </Link>
          {t('bidding_process.acknowledgement_text3', { role: currentUserInformation.role })}
        </div>
      </div>
      <div className="accept-offer-modal__cta">
        <button className="accept-offer-modal__cta--cancel" type="button" onClick={onModalClose}>
          {t('bidding_process.cancel')}
        </button>
        <button
          className={classNames('accept-offer-modal__cta--accept-offer', {
            'accept-offer-modal__cta--accept-offer--disabled': isAcceptOfferButtonDisabled,
          })}
          type="button"
          disabled={isAcceptOfferButtonDisabled}
          onClick={onAcceptOffer}
        >
          {modalTitle}
        </button>
      </div>
      <ToastNotification toastRef={toastRef} />
    </div>
  );
};

AcceptOfferOrderModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
};

export default AcceptOfferOrderModal;
