import React, { useState } from 'react';
import { t } from 'i18next';
import { useGetAllUsersQuery } from 'api/users';
import { getAdminUserTableTabs } from 'utils/helper/Portals';
import { allUsersTab } from 'utils/constants/portals';
import { USER_GROUP_BUYERS_SELLERS, tabFilterStatus } from 'utils/constants/userStatus';
import { RTabs } from 'components/BaseComponents/RTabs';
import Pagination from 'components/BaseComponents/Pagination';
import EmptyContentPlaceholder from 'components/Shared/EmptyContentPlaceholder';
import './styles.scss';

function UserManagementTable() {
  const [userStatus, setUserStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const { data = {}, isFetching: fetchingAllUsers } = useGetAllUsersQuery({
    currentPage,
    userStatus,
    userGroup: USER_GROUP_BUYERS_SELLERS,
  });
  const totalPages = Number(data?.pagination?.totalPages);
  const isUserExists = data?.collection?.length > 0;

  const handleTabChange = (tabKey) => {
    setCurrentPage(1);
    setUserStatus(tabFilterStatus[tabKey]);
  };

  const handlePagination = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  return (
    <div className="admin-portal__table--container">
      <h5 className="admin-portal__table--caption">{t('admin_user_table.title')}</h5>
      <RTabs
        className="admin-portal__table--tab"
        isVertical={false}
        items={getAdminUserTableTabs(fetchingAllUsers, data?.collection)}
        selectedTabKey={allUsersTab}
        onChange={handleTabChange}
      />
      {!isUserExists && !fetchingAllUsers && (
        <EmptyContentPlaceholder
          heading={t('shared.bidding_process.empty_content_placeholder.user')}
        />
      )}
      {totalPages > 1 && !fetchingAllUsers && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPaginationClick={handlePagination}
          firstText={t('admin_user_table.first_page')}
          lastText={t('admin_user_table.last_page')}
          nextText={t('admin_user_table.next_page')}
          previousText={t('admin_user_table.previous_page')}
          className="admin-portal__table--pagination"
        />
      )}
    </div>
  );
}

export default UserManagementTable;
