import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { userProfileLinks } from 'utils/constants/userRole';
import Icon from 'components/BaseComponents/Icon';
import './styles.scss';

const PendingVerification = ({ className, userRole }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames('pending-verification flex justify-between items-center', className)}
    >
      <div className="flex items-center gap-14px">
        <Icon name="neutralOutline" className="pending-verification__warning-icon" />
        <div>
          <p className="pending-verification__heading">
            {t('shared.bidding_process.pending_verification.heading')}
          </p>
          <p className="pending-verification__description">
            {t(`shared.bidding_process.pending_verification.${userRole}_description`)}
          </p>
        </div>
      </div>
      <Link to={userProfileLinks[userRole]} className="pending-verification__redirect-button">
        {t('shared.bidding_process.pending_verification.profile_update')}
      </Link>
    </div>
  );
};

PendingVerification.defaultProps = {
  className: null,
};

PendingVerification.propTypes = {
  className: PropTypes.string,
  userRole: PropTypes.string.isRequired,
};

export default PendingVerification;
