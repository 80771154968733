import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { primaryNavContents } from 'utils/constants/navbar';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetCompanyQuery } from 'api/companies';
import msmLogo from 'assets/images/msm-logo.svg';
import { landingPage, login, signup } from 'utils/constants/Routes';
import CustomNavLink from 'components/NavBar/CustomNavLink';
import AvatarDropdown from '../AvatarDropdown';
import './styles.scss';

const PrimaryNavBar = ({ className }) => {
  const { t } = useTranslation();
  const { data: user, isSuccess: userDataFetched, isFetching } = useGetCurrentUserQuery();
  const { data: companyDetails } = useGetCompanyQuery(
    { id: user?.company_id },
    { skip: !userDataFetched },
  );

  const { role: userRole } = user || {};
  const isLoggedIn = !!user;
  const isGuestUser = !user && !isFetching; // user is not loggedin
  const navBarClassName = classNames('nav-header', className);

  return (
    <header className={navBarClassName}>
      <div className="nav-header__container">
        <div className="nav-header__container--left">
          <div className="nav-header__content">
            <div className="nav-header__content--logo">
              <Link to={landingPage}>
                <img src={msmLogo} alt="MSM Logo" />
              </Link>
            </div>
          </div>

          <nav className="nav-header__navigation">
            <ul className="nav-header__navigation--list">
              {primaryNavContents.map((navItem) => (
                <CustomNavLink
                  key={navItem.id}
                  userRole={userRole}
                  name={navItem.name}
                  link={navItem.link}
                  dropDownContents={navItem.dropDownContents}
                  isDisabled={navItem.isDisabled}
                />
              ))}
            </ul>
          </nav>
        </div>

        <div className="nav-header__container--right">
          {isLoggedIn && (
            <AvatarDropdown
              firstName={user?.first_name}
              lastName={user?.last_name}
              description={companyDetails?.name}
              userRole={user?.role}
            />
          )}

          {isGuestUser && (
            <div className="nav-header__navigation--actions">
              <ul className="nav-header__navigation--actions-list">
                <li className="nav-header__navigation--actions-item">
                  <Link to={login} className="nav-header__navigation--actions-button">
                    {t('navbar.primary.actions.login_button_label')}
                  </Link>
                </li>
                <li className="nav-header__navigation--actions-item">
                  <Link
                    to={signup}
                    className="nav-header__navigation--actions-button button-signup"
                  >
                    {t('navbar.primary.actions.signup_button_label')}
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

PrimaryNavBar.defaultProps = {
  className: null,
};

PrimaryNavBar.propTypes = {
  className: PropTypes.string,
};

export default PrimaryNavBar;
