import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import useScroll from 'hooks/useScroll';
import { useGetCurrentUserQuery } from 'api/users';
import { isMSMSeller } from 'utils/helper/UserRoleValidation';
import { buyerSteps, sellerSteps } from 'utils/constants/listingCreation';
import PortalsNavbar from 'components/NavBar/PortalsNavBar';
import Stepper from 'components/BaseComponents/stepper';
import Button from 'components/BaseComponents/Button';
import './styles.scss';

const CreateListingLayout = ({ activeStep, children }) => {
  const { data: user = {} } = useGetCurrentUserQuery();
  const isSeller = isMSMSeller(user);
  const steps = isSeller ? sellerSteps : buyerSteps;
  const { showElement, scrollToTop } = useScroll(200, 3);

  return (
    <Fragment>
      <PortalsNavbar />
      <div className="create-listing">
        <Stepper
          steps={steps}
          activeStepIndex={activeStep}
          direction="horizontal"
          showStepTitle={false}
          className="create-listing__stepper"
        />
        {children}
      </div>
      {showElement && (
        <Button
          type="success"
          onClick={scrollToTop}
          className="stb border-none"
          label={t('offer_creation.next')}
        />
      )}
    </Fragment>
  );
};

CreateListingLayout.defaultProps = {
  children: null,
  activeStep: 0,
};

CreateListingLayout.propTypes = {
  children: PropTypes.node,
  activeStep: PropTypes.number,
};

export default CreateListingLayout;
