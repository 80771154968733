import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { affiliatePartner, sharedRoutes } from 'utils/constants/Routes';
import { isMSMPartner, isMSMSubscriptionMember } from 'utils/helper/UserRoleValidation';
import Button from 'components/BaseComponents/Button';
import hasCompanyPermissions from 'utils/helper/companyPermissionsValidation';
import PendingVerification from '../PendingVerification';
import NeedProductPermissionsIcon from '../NeedProductPermissionsIcon';
import './styles.scss';

const AvatarCard = ({ className, userName, companyName, isVerified, userRole, companyDetails, nutsTab }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const avatarClassName = classNames(
    'avatar-container flex flex-col items-center justify-center',
    className,
  );
  const buttonLabel = t(`shared.bidding_process.avatar.create_listing.${userRole}`);
  const isSubscriptionMember = isMSMSubscriptionMember({ role: userRole });
  const redirectLink = isMSMPartner({role: userRole})
    ? affiliatePartner.inviteBuyer
    : sharedRoutes.navigateCreateProduct(userRole);

  const nutsType = nutsTab.replace('Tab','')
  const createProductEnabled = hasCompanyPermissions(userRole, nutsType, companyDetails)

  return (
    <div className={avatarClassName}>
      <div className="avatar-container__card flex justify-between items-end">
        <div>
          <p className="avatar-container__card--welcome-text">
            {t('shared.bidding_process.avatar.welcome')}
          </p>
          <h2 className="avatar-container__card--user-name">{userName}</h2>
          <p className="avatar-container__card--company-name">{companyName}</p>
        </div>
        {isVerified && !isSubscriptionMember && (
          <div className="flex flex-col items-end">
            <Button
              onClick={() => navigate(redirectLink)}
              label={buttonLabel}
              disabled={!createProductEnabled}
              className="avatar-container__card--redirect-button justify-center flex"
            />
            <div className="avatar-container__card--welcome-text padding-t-16px flex gap-4px">
              {t('shared.bidding_process.avatar.view_listing')}
              {!createProductEnabled && <NeedProductPermissionsIcon type={nutsType} />}
            </div>
          </div>
        )}
      </div>
      {!isVerified && <PendingVerification className="margin-t-24px" userRole={userRole} />}
    </div>
  );
};

AvatarCard.defaultProps = {
  className: '',
  userName: '',
  companyName: '',
  isVerified: false,
  companyDetails: {},
  nutsTab: '',
};

AvatarCard.propTypes = {
  className: PropTypes.string,
  userName: PropTypes.string,
  companyName: PropTypes.string,
  isVerified: PropTypes.bool,
  userRole: PropTypes.string.isRequired,
  companyDetails: PropTypes.shape({
    company_permissions: PropTypes.shape({
      trade_kernel: PropTypes.bool,
      trade_dried_nut_in_shell: PropTypes.bool,
    }),
  }),
  nutsTab: PropTypes.string,
};

export default AvatarCard;
