const USER = 'User';
const ALL_USER = 'ALL_USER';
const ON_BOARDED_USER = 'ON_BOARDED_USER';
const COMPANY = 'COMPANY';
const VERIFICATION_STATUS = 'VERIFICATION_STATUS';
const PROFILE_COMPLETENESS = 'PROFILE_COMPLETENESS';
const COMPANY_CONTACTS = 'COMPANY_CONTACTS';
const FOOD_SAFETY = 'FOOD_SAFETY';
const CONTRACTS = 'CONTRACTS';
const PRODUCT = 'PRODUCT';
const PRODUCT_DETAILS = 'PRODUCT_DETAILS';
const PRODUCTS = 'PRODUCTS';
const OFFER_FOOD_SAFETY = 'OFFER_FOOD_SAFETY';
const LISTINGS = 'LISTINGS';
const MY_LISTINGS = 'MY_LISTINGS';
const FOLLOWING_LISTINGS = 'FOLLOWING_LISTINGS';
const LEADING_BID_LISTINGS = 'LEADING_BID_LISTINGS';
const OUTBID_LISTINGS = 'OUTBID_LISTINGS';
const COMPANY_PERMISSIONS = 'COMPANY_PERMISSIONS';

export {
  USER,
  ALL_USER,
  ON_BOARDED_USER,
  COMPANY,
  VERIFICATION_STATUS,
  PROFILE_COMPLETENESS,
  COMPANY_CONTACTS,
  FOOD_SAFETY,
  CONTRACTS,
  PRODUCT,
  PRODUCT_DETAILS,
  PRODUCTS,
  OFFER_FOOD_SAFETY,
  LISTINGS,
  MY_LISTINGS,
  COMPANY_PERMISSIONS,
  FOLLOWING_LISTINGS,
  LEADING_BID_LISTINGS,
  OUTBID_LISTINGS,
};
