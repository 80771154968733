import { apiSlice } from 'api';
import { toSnakecaseKeys } from 'utils/helper/ObjectKeyConverter';
import routes from 'utils/routes';
import {
  VERIFICATION_STATUS,
  PROFILE_COMPLETENESS,
  USER,
  ALL_USER,
  ON_BOARDED_USER,
} from 'utils/tagTypes';

const userApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query({
      query: () => routes.currentUser.entity(),
      transformResponse: (response) => toSnakecaseKeys(response),
      providesTags: [USER],
    }),
    getUser: builder.query({
      query: (params) => routes.users.entity(params),
      transformResponse: (response) => toSnakecaseKeys(response),
      providesTags: [USER],
    }),
    getAllUsers: builder.query({
      query: (params) => ({
        url: routes.users.getAllUsers(params),
        method: 'GET',
      }),
      providesTags: [ALL_USER],
    }),
    updateUser: builder.mutation({
      query: ({ userId, payload }) => ({
        url: routes.users.entity({ id: userId }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [USER, PROFILE_COMPLETENESS],
    }),
    getProfileCompleteness: builder.query({
      query: (id) => routes.users.getProfileCompleteness({ id }),
      transformResponse: (response) => toSnakecaseKeys(response),
      providesTags: [PROFILE_COMPLETENESS],
    }),
    requestUserVerification: builder.mutation({
      query: (userId) => ({
        url: routes.users.requestUserVerification({ id: userId }),
        method: 'PATCH',
      }),
      invalidatesTags: [VERIFICATION_STATUS],
    }),
    getMyAffiliations: builder.query({
      query: ({ userStatus, page }) => ({
        url: routes.users.getMyAffiliations(userStatus, page),
        method: 'GET',
      }),
      providesTags: [ON_BOARDED_USER],
    }),
    getOnboardedUsers: builder.query({
      query: ({ id, userStatus, page }) => ({
        url: routes.users.getOnboardedUsers(id, userStatus, page),
        method: 'GET',
      }),
    }),
    reInviteUser: builder.mutation({
      query: (userId) => ({
        url: routes.users.reInvite({ id: userId }),
        method: 'POST',
      }),
      invalidatesTags: [ON_BOARDED_USER],
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useGetUserQuery,
  useGetAllUsersQuery,
  useUpdateUserMutation,
  useGetProfileCompletenessQuery,
  useRequestUserVerificationMutation,
  useLazyGetCurrentUserQuery,
  useGetMyAffiliationsQuery,
  useGetOnboardedUsersQuery,
  useReInviteUserMutation,
} = userApi;
