import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { useDeleteFoodSafetyTestCertificateMutation } from 'api/products';
import { validateTestCertificates } from 'utils/helper/Validations';
import { requiredText } from 'utils/helper/LabelWithHints';
import Icon from 'components/BaseComponents/Icon';
import Button from 'components/BaseComponents/Button';
import IconButton from 'components/BaseComponents/IconButton';
import ChooseFile from 'components/Shared/ChooseFile';
import './styles.scss';

const TestCertificates = ({ testType, isOptional }) => {
  const [deleteFoodSafetyTestCertificate] = useDeleteFoodSafetyTestCertificateMutation();

  const generateTestCertificateTitle = () => {
    if (isOptional) {
      return t('offer_creation.food_safety.certificate_title', {
        testName: testType.title,
      });
    }

    return requiredText(
      t('offer_creation.food_safety.certificate_title', {
        testName: testType.title,
      }),
    );
  };

  const handleTestCertificateDelete = (attachmentId) => {
    if (!attachmentId) {
      return;
    }

    deleteFoodSafetyTestCertificate({ attachmentId });
  };

  return (
    <div className="test-certificates">
      <div className="test-certificates__header">{generateTestCertificateTitle()}</div>
      <div className="margin-t-16px padding-b-16px test-certificates__form">
        <FieldArray name={testType.fieldName}>
          {({ fields }) => (
            <Fragment>
              {fields.map((name, index) => (
                <Field
                  key={`${name}.name`}
                  name={`${name}.fileName`}
                  validate={(value) => validateTestCertificates(value, isOptional)}
                >
                  {({ input, meta }) => (
                    <div className="flex">
                      <ChooseFile
                        fileName={input.value?.name}
                        fileUrl={input.value?.url}
                        label={t('offer_creation.food_safety.file_label', {
                          testName: testType.title,
                          fileIndex: index + 1,
                        })}
                        hint={t('profile.file.upload')}
                        onChange={input.onChange}
                        onDelete={() => {
                          const attachmentId = input.value?.fileId;
                          handleTestCertificateDelete(attachmentId);
                        }}
                        disableDelete={
                          !isOptional &&
                          input.value?.numberOfCertificates <= 1 &&
                          input.value?.fileId
                        }
                        touched={meta?.touched}
                        errorMessage={meta?.error || meta?.submitError}
// todo
                        // errorMessage={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                        className="margin-b-16px"
                      />
                      {fields.length > 1 && !input.value?.name && (
                        <IconButton
                          icon={<Icon name="deleteLine" size="medium" color="danger" />}
                          size="large"
                          color="danger"
                          isIconOnly
                          onClick={() => {
                            fields.remove(index);
                          }}
                          className="test-certificates__form--delete-doc"
                        />
                      )}
                    </div>
                  )}
                </Field>
              ))}
              <Button
                submitType="button"
                className="flex items-center gap-8px padding-x-24px business-contacts__add-fields"
                label={t('offer_creation.food_safety.add_another_document')}
                icon={<Icon name="add" color="success" />}
                onClick={() => {
                  fields.push({});
                }}
              />
            </Fragment>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

TestCertificates.defaultProps = {
  isOptional: false,
};

TestCertificates.propTypes = {
  testType: PropTypes.shape({
    title: PropTypes.string,
    fieldName: PropTypes.string,
  }).isRequired,
  isOptional: PropTypes.bool,
};

export default TestCertificates;
