import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { PUBLISHED, ACCEPTED, CONCLUDED, IN_PROGRESS } from 'utils/constants/offerListings';
import { useGetProductDetailsQuery } from 'api/products';
import ProductCard from 'components/Shared/BiddingProcess/ProductCard';
import EmptyContentPlaceholder from 'components/Shared/EmptyContentPlaceholder';
import ContentLoaderPlaceholder from 'components/BaseComponents/ContentLoader/ContentLoader';
import { BUYER } from 'utils/constants/userRole';

const ProductCardWrapper = ({
  productId,
  highestBidPrice,
  serialNumber,
  productStatus,
  contract,
  userRole,
}) => {
  const isBidStatusVisible = productStatus === PUBLISHED;
  const canViewContractDetails = [ACCEPTED, CONCLUDED, IN_PROGRESS].includes(productStatus);
  const {
    data: productDetails = {},
    isLoading: isProductDetailsLoading,
    error,
  } = useGetProductDetailsQuery({
    product_id: productId,
  });

  const {
    offerVolume,
    nutGrade,
    countryOfOrigin,
    offerPrice,
    estimatedShippingDate,
    bestBeforeDate,
    fullBatchPrice,
    nutStyle,
    type,
    size,
    soundKernelRecovery,
    unsoundKernelRecovery,
  } = productDetails;

  if (error) {
    return (
      <EmptyContentPlaceholder
        heading={t('shared.bidding_process.empty_content_placeholder.heading')}
      />
    );
  }

  const redirectPath = canViewContractDetails
    ? `/${userRole}/contracts/${contract?.id}`
    : `/${userRole}/listings/${productId}`;

  const labelForID =
    userRole === BUYER
      ? t('shared.product_card.order_id_label')
      : t('shared.product_card.offer_id_label');
  const idLabel = canViewContractDetails ? t('shared.product_card.contract_id_label') : labelForID;
  const createdAt = canViewContractDetails && contract?.createdAt;
  const bestBefore = !canViewContractDetails && bestBeforeDate;
  const id = canViewContractDetails ? contract?.serialNumber : serialNumber;

  const productCardContent = isProductDetailsLoading ? (
    <ContentLoaderPlaceholder numberOfLines={1} />
  ) : (
    <ProductCard
      redirectLink={redirectPath}
      isBidStatusVisible={isBidStatusVisible}
      highestBidPrice={highestBidPrice}
      quantity={offerVolume}
      style={nutStyle}
      soundKernelRecovery={soundKernelRecovery}
      unsoundKernelRecovery={unsoundKernelRecovery}
      size={size}
      type={type}
      quality={nutGrade}
      country={countryOfOrigin}
      priceLabel={t('shared.product_card.price_label')}
      perKgPrice={offerPrice}
      isIdVisible={true}
      idLabel={idLabel}
      id={id}
      esdLabel={t('shared.product_card.esd_label')}
      esdDate={estimatedShippingDate}
      bestBeforeLabel={t('shared.product_card.best_before_label')}
      soundKernelRecoveryLabel={t('shared.product_card.sound_kernel_recovery_label')}
      unsoundKernelRecoveryLabel={t('shared.product_card.unsound_kernel_recovery_label')}
      bestBeforeDate={bestBefore}
      batchLabel={t('shared.product_card.batch_label')}
      batchPrice={fullBatchPrice}
      currency={t('shared.product_card.currency_usd')}
      createdAtLabel={t('shared.product_card.created_at_label')}
      createdAtDate={createdAt}
    />
  );

  return <div>{productCardContent}</div>;
};

ProductCardWrapper.defaultProps = {
  highestBidPrice: null,
  contract: {},
};

ProductCardWrapper.propTypes = {
  productId: PropTypes.number.isRequired,
  userRole: PropTypes.string.isRequired,
  highestBidPrice: PropTypes.string,
  serialNumber: PropTypes.string.isRequired,
  productStatus: PropTypes.string.isRequired,
  contract: PropTypes.shape({
    id: PropTypes.number,
    serialNumber: PropTypes.string,
    createdAt: PropTypes.string,
  }),
};
export default ProductCardWrapper;
