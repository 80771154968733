import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';
import { useSignupMutation } from 'api/auth';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { labelWithHints } from 'utils/helper/LabelWithHints';
import { generateSignupBody } from 'utils/helper/RequestBody';
import { handleServerValidationErrors, requiredField } from 'utils/helper/Validations';
import { termsAndConditionPDF } from 'utils/constants/pdf';
import Input from 'components/BaseComponents/Input';
import Modal from 'components/BaseComponents/Modal';
import Button from 'components/BaseComponents/Button';
import CheckBox from 'components/BaseComponents/CheckBox';
import PasswordInput from 'components/Shared/PasswordInput';
import ToastNotification from 'components/Shared/ToastNotification';
import FormFooter from 'components/SignUp/FormFooter';
import CompanyAlreadyInvitedModal from 'components/SignUp/Step2Form/CompanyAlreadyInvitedModal';
import SelectBox from 'components/BaseComponents/SelectBox';
import './style.scss';

const Step2Form = ({ userType }) => {
  const { t } = useTranslation();
  const toastRef = useRef();
  const [signup] = useSignupMutation();
  const navigate = useCustomNavigate();
  const isSubscriptionMemeber = userType === '4';

  const subscriptionMemeberCategories = [
    {
      label: t('signup.step2_form.category.farmer'),
      value: 'farmer',
    },
    {
      label: t('signup.step2_form.category.media'),
      value: 'media',
    },
    {
      label: t('signup.step2_form.category.trader'),
      value: 'trader',
    },
    {
      label: t('signup.step2_form.category.processor'),
      value: 'processor',
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);

  const onModalOpen = () => setIsModalVisible(true);
  const onModalClose = () => setIsModalVisible(false);

  const handleSignup = async (params) => {
    if (params.user_password !== params.confirm_password) {
      return {
        user_password: t('signup.step2_form.validation_error.password_not_matched'),
        confirm_password: t('signup.step2_form.validation_error.password_not_matched'),
      };
    }
    const requestBody = generateSignupBody(params, userType);
    const response = await signup({ payload: { company_attributes: requestBody } });

    if (response.error) {
      const {
        error: {
          data: { message },
          status,
        },
      } = response;

      if (message.userBase) onModalOpen();

      const toasterRef = message.userBase && Object.keys(message)?.length === 1 ? null : toastRef;

      return handleServerValidationErrors(toasterRef, status, message);
    }

    navigate.registrationSuccessPage(params.user_email);
    return null;
  };

  const termsLabel = (
    <>
      <span className="signup-form__policy-content--content1">
        {t('signup.step2_form.terms_body.content1')}
      </span>
      <Link
        to={termsAndConditionPDF}
        target="_blank"
        className="signup-form__policy-content--redirect-link"
      >
        {t('signup.step2_form.terms_body.redirect_link')}
      </Link>
      <span className="signup-form__policy-content--content2">
        {t('signup.step2_form.terms_body.content2')}
      </span>
    </>
  );

  return (
    <div className="signup-form">
      <h2 className="signup-form__title">{t('signup.step2_form.title')}</h2>
      <p className="signup-form__message">{t('signup.step2_form.message')}</p>
      <h5 className="signup-form__form-title">
        {t('signup.step2_form.form_header')}
        <span className="signup-form__form-title--required">*</span>
      </h5>
      <Form
        onSubmit={handleSignup}
        render={({ handleSubmit, invalid, dirtySinceLastSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="margin-t-16px">
            <Field name="user_first_name" validate={requiredField}>
              {({ input, meta }) => (
                <Input
                  label={t('signup.step2_form.first_name.label')}
                  placeholder={t('signup.step2_form.first_name.placeholder')}
                  required
                  className="signup-form__first-name margin-b-20px"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Field name="user_last_name" validate={requiredField}>
              {({ input, meta }) => (
                <Input
                  label={t('signup.step2_form.last_name.label')}
                  placeholder={t('signup.step2_form.last_name.placeholder')}
                  required
                  size="small"
                  className="signup-form__last-name"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            {isSubscriptionMemeber && (
              <Field name="subscription_member_category" validate={requiredField}>
                {({ input, meta }) => (
                  <SelectBox
                    options={subscriptionMemeberCategories}
                    label={t('signup.step2_form.category.label')}
                    placeholderText={t('signup.step2_form.category.placeholder')}
                    required={true}
                    size="small"
                    errorMsg={meta?.error || meta?.submitError}
                    touched={meta.touched}
                    value={input.value}
                    onChange={input.onChange}
                    width="full"
                    className="margin-t-20px"
                  />
                )}
              </Field>
            )}
            <Field name="name" validate={isSubscriptionMemeber ? null : requiredField}>
              {({ input, meta }) => (
                <Input
                  label={isSubscriptionMemeber
                    ? t('signup.step2_form.company_name.label_for_subscription_member')
                    : labelWithHints(
                    t('signup.step2_form.company_name.label'),
                    t('signup.step2_form.company_name.tooltip_content'),
                  )}
                  placeholder={t('signup.step2_form.company_name.placeholder')}
                  size="small"
                  className="margin-t-20px"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            {!isSubscriptionMemeber && (
              <>
                <Field name="country_code_of_registration" validate={requiredField}>
                  {({ input, meta }) => (
                    <div className="margin-t-20px">
                      <p className="signup-form__country--title">
                        {t('signup.step2_form.country.label')}
                        <span className="signup-form__country--title--required">*</span>
                      </p>
                      <ReactFlagsSelect
                        selectButtonClassName={classNames('signup-form__country--dropdown', {
                          'signup-form__country--dropdown--error': meta?.touched && meta?.error,
                        })}
                        searchable
                        placeholder={t('signup.step2_form.country.placeholder')}
                        selected={input.value}
                        onSelect={(code) => input.onChange(code)}
                      />
                    </div>
                  )}
                </Field>
                <Field name="registration_number" validate={requiredField}>
                  {({ input, meta }) => (
                    <Input
                      label={labelWithHints(
                        t('signup.step2_form.company_registration_number.label'),
                        t('signup.step2_form.company_registration_number.tooltip_content'),
                      )}
                      placeholder={t('signup.step2_form.company_registration_number.placeholder')}
                      required
                      size="small"
                      className="margin-t-20px"
                      error={meta?.error || meta?.submitError}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </>
            )}
            <Field name="user_email" validate={requiredField}>
              {({ input, meta }) => (
                <Input
                  label={t('signup.step2_form.user_email.label')}
                  placeholder={t('signup.step2_form.user_email.placeholder')}
                  required
                  size="small"
                  className="margin-t-20px"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Field name="user_password" validate={requiredField}>
              {({ input, meta }) => (
                <PasswordInput
                  label={t('signup.step2_form.password.label')}
                  placeholder={t('signup.step2_form.password.placeholder')}
                  required
                  size="small"
                  className="margin-t-20px margin-b-32px"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Field name="confirm_password" validate={requiredField}>
              {({ input, meta }) => (
                <PasswordInput
                  label={t('signup.step2_form.confirm_password.label')}
                  placeholder={t('signup.step2_form.confirm_password.placeholder')}
                  required
                  size="small"
                  className="margin-t-20px margin-b-40px"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <h5 className="signup-form__form-title">
              {t('signup.step2_form.checkbox_card_title')}
              <span className="signup-form__form-title--required">*</span>
            </h5>
            <Field name="is_accept_terms" type="checkbox" validate={requiredField}>
              {({ input, meta }) => (
                <CheckBox
                  id="terms&condition"
                  label={termsLabel}
                  className="signup-form__policy-content margin-t-16px margin-b-16px"
                  hintText={meta.touched && meta.error}
                  isChecked={input.checked}
                  onChange={(checked) => {
                    input.onChange(checked);
                  }}
                />
              )}
            </Field>
            <Field name="is_accept_credit_check" type="checkbox" validate={requiredField}>
              {({ input, meta }) => (
                <CheckBox
                  id="creditcheck"
                  label={t('signup.step2_form.credit_check_body')}
                  className="signup-form__policy-content margin-t-16px margin-b-32px"
                  size="small"
                  hintText={meta.touched && meta.error}
                  isChecked={input.checked}
                  onChange={(checked) => {
                    input.onChange(checked);
                  }}
                />
              )}
            </Field>
            <Button
              className="margin-b-20px signup-form__submit-btn"
              type="success"
              label={t('signup.step2_form.submit_button')}
              submitType="submit"
              disabled={(!dirtySinceLastSubmit && invalid) || submitting}
            />
          </form>
        )}
      />
      <FormFooter />
      <Modal
        isVisible={isModalVisible}
        onOutsideClick={onModalClose}
        className="progress-card-modal"
      >
        <Modal.Content>
          <CompanyAlreadyInvitedModal onModalClose={onModalClose} />
        </Modal.Content>
      </Modal>
      <ToastNotification toastRef={toastRef} />
    </div>
  );
};

Step2Form.propTypes = {
  userType: PropTypes.string.isRequired,
};

export default Step2Form;
