import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetProductsQuery } from 'api/admin';
import { wholeSaleOfferCTA } from 'utils/constants/wholeSaleMarketPlace';
import { priceChartCollection } from 'utils/constants/AggregateSalesStatistics';
import { CONCLUDED_CONTRACTS, PREVIOUS_YEAR } from 'utils/constants/offerListings';
import { signup } from 'utils/constants/Routes';
import { PRICE_CHART_TYPE } from 'utils/constants/Charts';
import { priceWithCurrency } from 'utils/helper/Charts';
import { isMSMPartner } from 'utils/helper/UserRoleValidation';
import Layout from 'components/Layout';
import OverlayCTA from 'components/Shared/OverlayCTA';
import LineChartComponent from 'components/Shared/StaticPages/LineChartComponent';
import ProductCardContainer from 'components/Shared/BiddingProcess/ListingCardContainer';
import BannerWithButton from '../shared/BannerWithButton';
import TradingSteps from './TradingSteps';
import TradingCards from './TradingCards';
import './styles.scss';

const WholesaleMarketplace = () => {
  const { t } = useTranslation();

  const { heading, subHeadings, buttonText } = wholeSaleOfferCTA;
  const { data: user, isSuccess: userDetailsFetched } = useGetCurrentUserQuery();
  const isRedirectLinkVisible = user ? !isMSMPartner(user) : true;

  const { data: listings } = useGetProductsQuery({
    with_price_graph_data: true,
    time_period: PREVIOUS_YEAR,
    listing_type: CONCLUDED_CONTRACTS,
    page: 1,
    per_page: 5,
  });

  const {
    collection: {
      aggregatedPriceGraph: { graphData = [], overallPeriodAverage = '0.00' } = {},
      collection: products = [],
    } = {},
  } = listings || {};
  const priceChart = {
    ...priceChartCollection,
    chartData: graphData,
  };

  return (
    <Layout>
      <div className="wholesale-marketplace">
        <BannerWithButton
          className="wholesale-marketplace__header"
          subHeading={t('wholesale_marketplace.header_section.sub_header')}
          heading={t('wholesale_marketplace.header_section.header')}
          ctaText={t('wholesale_marketplace.header_section.start_trading')}
          ctaRedirectLink={signup}
          isRedirectLinkVisible={isRedirectLinkVisible}
        />
        <div
          className={classNames(
            'wholesale-marketplace__listing-container flex flex-col gap-20px margin-x-auto',
            {
              'padding-b-60px': userDetailsFetched,
            },
          )}
        >
          <LineChartComponent
            className="wholesale-marketplace__listing-container--line-chart"
            heading={t('wholesale_marketplace.listings.heading')}
            chartTitle={t('wholesale_marketplace.listings.chart.title')}
            chartSubtitle={priceWithCurrency(overallPeriodAverage)}
            chartCollection={priceChart}
            chartType={PRICE_CHART_TYPE}
            isGraphUpdated={true}
          />
          <ProductCardContainer listings={products} />
        </div>
        {!userDetailsFetched && (
          <OverlayCTA
            heading={heading}
            subHeadings={subHeadings}
            ctaButtonLink={signup}
            ctaButtonText={buttonText}
          />
        )}
        <TradingCards />
        <TradingSteps />
      </div>
    </Layout>
  );
};

export default WholesaleMarketplace;
