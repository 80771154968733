import { admin, affiliatePartner, buyer, seller, subscriptionMember } from './Routes';

const SELLER = 'seller';
const BUYER = 'buyer';
const ADMIN = 'admin';
const AFFILIATE_PARTNER = 'affiliate_partner';
const SUBSCRIPTION_MEMBER = 'subscription_member';

const AFFILIATE_PARTNER_ROLE_CODE = 3;

const userRoleLabels = {
  [SELLER]: 'Seller',
  [BUYER]: 'Buyer',
  [ADMIN]: 'Admin',
  [AFFILIATE_PARTNER]: 'Affiliate Partner',
  [SUBSCRIPTION_MEMBER]: 'Subscription Member',
};

const userProfileLinks = {
  [SELLER]: seller.portal,
  [BUYER]: buyer.portal,
  [ADMIN]: admin.dashboard,
  [AFFILIATE_PARTNER]: affiliatePartner.profile,
  [SUBSCRIPTION_MEMBER]: subscriptionMember.portal,
};

export {
  SELLER,
  BUYER,
  ADMIN,
  AFFILIATE_PARTNER,
  SUBSCRIPTION_MEMBER,
  AFFILIATE_PARTNER_ROLE_CODE,
  userRoleLabels,
  userProfileLinks,
};
