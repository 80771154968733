import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetCompanyQuery } from 'api/companies';
import { isVerified } from 'utils/helper/UserRoleValidation';
import { navContentByRole, defaultNavContents } from 'utils/constants/navbar';
import { landingPage } from 'utils/constants/Routes';
import msmLogo from 'assets/images/msm-logo.svg';
import Tooltip from 'components/BaseComponents/Tooltip';
import CustomNavLink from '../CustomNavLink';
import AvatarDropdown from '../AvatarDropdown';
import '../PrimaryNavBar/styles.scss';

const PortalsNavbar = () => {
  const { t } = useTranslation();
  const [selectedLinkIndex, setSelectedLinkIndex] = useState(null);
  const [isDropDownTooltipVisible, setIsDropDownTooltipVisible] = useState(true);
  const { data: user = {}, isSuccess: userDataFetched } = useGetCurrentUserQuery();
  const { data: companyDetails = {} } = useGetCompanyQuery(
    { id: user?.company_id },
    { skip: !userDataFetched },
  );
  const isUserVerified = isVerified(companyDetails);

  const { role: userRole } = user;
  const navContents = navContentByRole[userRole] || defaultNavContents;

  const handleClick = (clickedLinkIndex) => {
    if (selectedLinkIndex === clickedLinkIndex) {
      setSelectedLinkIndex(-1);
    } else {
      setSelectedLinkIndex(clickedLinkIndex);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.closest('.seller-nav__navigation') === null) {
        setSelectedLinkIndex(null);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <header className="nav-header">
      <div className="nav-header__container">
        <div className="nav-header__container--left">
          <div className="nav-header__content">
            <div className="nav-header__content--logo">
              <Link to={landingPage}>
                <img src={msmLogo} alt="MSM Logo" />
              </Link>
            </div>
          </div>

          <nav className="nav-header__navigation seller-nav__navigation">
            <ul className="nav-header__navigation--list">
              {navContents.map(({ id, name, link, dropDownContents, isDisabled }) => (
                <div key={id} onClick={() => handleClick(id)} role="button" aria-hidden="true">
                  <CustomNavLink
                    userRole={userRole}
                    name={name}
                    link={link}
                    dropDownContents={dropDownContents}
                    isDropDownOpen={selectedLinkIndex === id}
                    isDisabled={isDisabled}
                    isUserVerified={isUserVerified}
                  />
                </div>
              ))}
            </ul>
          </nav>
        </div>

        <div className="nav-header__container--right">
          <Tooltip
            content={t('shared.avatar_tooltip')}
            type="midnight-blue"
            isActive={isDropDownTooltipVisible}
          >
            <AvatarDropdown
              firstName={user?.first_name}
              lastName={user?.last_name}
              description={companyDetails?.name}
              userRole={user?.role}
              imgSrc={user?.avatar_url}
              setIsDropDownTooltipVisible={setIsDropDownTooltipVisible}
            />
          </Tooltip>
        </div>
      </div>
    </header>
  );
};

export default PortalsNavbar;
