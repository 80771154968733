import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useGetCurrentUserQuery } from 'api/users';
import { toSnakecaseKeys } from 'utils/helper/ObjectKeyConverter';
import { isMSMSeller } from 'utils/helper/UserRoleValidation';
import { labelWithHints, requiredSectionHeading } from 'utils/helper/LabelWithHints';
import { localizedSelectedObject } from 'utils/helper/selectBoxUtils';
import { requiredField, requiredCheckbox } from 'utils/helper/Validations';
import Button from 'components/BaseComponents/Button';
import {
  containerLoads,
  nutGrades,
  sizes,
  soundKernelRecoveries,
  unsoundKernelRecoveries,
  treeVariants,
  paymentOptions,
  regions,
  seasons,
  KERNEL_PRODUCT,
} from 'utils/constants/product';
import CountrySelect from 'components/BaseComponents/CountrySelect';
import DatePicker from 'components/BaseComponents/DatePicker';
import SelectBox from 'components/BaseComponents/SelectBox';
import CheckBox from 'components/BaseComponents/CheckBox';
import Input from 'components/BaseComponents/Input';
import hasCompanyPermissions from 'utils/helper/companyPermissionsValidation';
import SubmitButtons from '../StepperButtons/SubmitButtons';
import './styles.scss';

const DriedNutInShellProductDetails = ({
  currentStepIndex,
  onPrevButtonClick,
  onProductSave,
  productDetails,
  companyDetails,
}) => {
  const { t } = useTranslation();
  const [isBestBeforeOpen, setIsBestBeforeOpen] = useState(false);
  const [isESDDateOpen, setIsESDDateOpen] = useState(false);

  const { data: user = {} } = useGetCurrentUserQuery();
  const isSeller = isMSMSeller(user);
  const { role: userRole } = user;
  const isNewProduct = !productDetails;
  const canCreateNewKernel = isNewProduct && hasCompanyPermissions(userRole, KERNEL_PRODUCT, companyDetails);

  const initValue = useMemo(() => {
    if (isNewProduct) {
      return null;
    }

    const nutGrade = localizedSelectedObject(
      `shared.product.nut_grade.${productDetails.nutGrade}`,
      productDetails.nutGrade,
    );
    const size = localizedSelectedObject(
      `shared.product.size.${productDetails.size}`,
      productDetails.size,
    );
    const unsoundKernelRecovery = localizedSelectedObject(
      `shared.product.unsound_kernel_recovery.${productDetails.unsoundKernelRecovery}`,
      productDetails.unsoundKernelRecovery,
    );
    const soundKernelRecovery = localizedSelectedObject(
      `shared.product.sound_kernel_recovery.${productDetails.soundKernelRecovery}`,
      productDetails.soundKernelRecovery,
    );
    const moistureContent = localizedSelectedObject(
      `shared.product.moisture_content.${productDetails.moistureContent}`,
      productDetails.moistureContent,
    );
    const treeVariant = localizedSelectedObject(
      `shared.product.tree_variant.${productDetails.treeVariant}`,
      productDetails.treeVariant,
    );
    const regionOfOrigin = localizedSelectedObject(
      `shared.product.regions.${productDetails.regionOfOrigin}`,
      productDetails.regionOfOrigin,
    );
    const seasonHarvested = localizedSelectedObject(
      productDetails.seasonHarvested,
      productDetails.seasonHarvested,
    );
    const containerLoad = localizedSelectedObject(
      `shared.product.container_load.${productDetails.containerLoad}`,
      productDetails.containerLoad,
    );
    const paymentOption = localizedSelectedObject(
      `shared.product.payment_options.${productDetails.paymentOptions}`,
      productDetails.paymentOptions,
    );

    return toSnakecaseKeys({
      ...productDetails,
      nutGrade,
      size,
      unsoundKernelRecovery,
      soundKernelRecovery,
      moistureContent,
      treeVariant,
      seasonHarvested,
      regionOfOrigin,
      containerLoad,
      paymentOptions: paymentOption,
    });
  }, []);

  return (
    <div className="product-details-form">
      <div className="flex gap-32px align-center">
        <h2 className="margin-l-12px padding-l-12px">Create a New DNIS Listing</h2>
        {canCreateNewKernel && (
          <Button
            label="Kernel"
            type="success"
            onClick={() => {
              localStorage.setItem('nutsTab', KERNEL_PRODUCT);
              window.location.reload();
            }}
          />
        )}
      </div>
      <Form
        onSubmit={onProductSave}
        initialValues={initValue}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <SubmitButtons
              isSaveAsDraftVisible={false}
              isSubmitButtonDisabled={submitting}
              userRole={userRole}
              currentStepIndex={currentStepIndex}
              onPrevButtonClick={onPrevButtonClick}
            />
            <div className="product-details-form__fields">
              <h3 className="product-details-form__title margin-none">
                {t(`offer_creation.product_details.form.title.${userRole}`)}
              </h3>
              <p className="product-details-form__section-title">
                {t('offer_creation.product_details.form.price_section')}
              </p>
              <div className="flex gap-20px padding-t-16px">
                <Field name="offer_volume" validate={requiredField}>
                  {({ input, meta }) => (
                    <Input
                      label={t(`offer_creation.product_details.form.volume.label.${userRole}`)}
                      placeholder={t('offer_creation.product_details.form.volume.placeholder')}
                      required={true}
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                      type="number"
                      className="product-details-form__offer-volume"
                    />
                  )}
                </Field>
                <Field name="offer_price" validate={requiredField}>
                  {({ input, meta }) => (
                    <Input
                      label={t(`offer_creation.product_details.form.price.label.${userRole}`)}
                      placeholder={t('offer_creation.product_details.form.price.placeholder')}
                      required={true}
                      className="product-details-form__offer-price"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      type="number"
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>
              <Field name="boxes_quantity">
                {({ input, meta }) => (
                  <Input
                    label={t(`offer_creation.product_details.form.boxes_quantity.label`)}
                    placeholder={t(
                      'offer_creation.product_details.form.boxes_quantity.placeholder',
                    )}
                    required={false}
                    className="product-details-form__boxes-quantity padding-t-16px"
                    error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                    touched={meta.touched}
                    value={input.value}
                    type="number"
                    onChange={input.onChange}
                  />
                )}
              </Field>
              <p className="product-details-form__section-title margin-t-60px">
                {t('offer_creation.product_details.form.details_section')}
              </p>
              <div className="flex gap-20px padding-t-16px">
                <Field name="nut_grade" validate={isSeller ? requiredField : null}>
                  {({ input, meta }) => (
                    <SelectBox
                      label={t('offer_creation.product_details.form.nut_grade.label')}
                      placeholderText={t(
                        'offer_creation.product_details.form.nut_grade.placeholder',
                      )}
                      options={nutGrades}
                      value={input.value}
                      errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      onChange={input.onChange}
                      required={isSeller}
                      isSearchable={false}
                      width="full"
                      className="product-details-form__offer-volume"
                    />
                  )}
                </Field>
                <Field name="size" validate={requiredField}>
                  {({ input, meta }) => (
                    <SelectBox
                      label={t('offer_creation.product_details.form.size.label')}
                      placeholderText={t('offer_creation.product_details.form.size.placeholder')}
                      tooltip={<>{t('offer_creation.product_details.form.size.tooltip')}</>}
                      options={sizes}
                      value={input.value}
                      errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      onChange={input.onChange}
                      onCreateOption={(createdOption) =>
                        input.onChange({ label: createdOption, value: createdOption })
                      }
                      isCreatable={true}
                      required={true}
                      width="full"
                      className="product-details-form__offer-volume"
                    />
                  )}
                </Field>
              </div>
              <div className="flex gap-20px margin-t-20px">
                <Field name="unsound_kernel_recovery" validate={isSeller ? requiredField : null}>
                  {({ input, meta }) => (
                    <SelectBox
                      label={t('offer_creation.product_details.form.unsound_kernel_recovery.label')}
                      placeholderText={t(
                        'offer_creation.product_details.form.unsound_kernel_recovery.placeholder',
                      )}
                      options={unsoundKernelRecoveries}
                      value={input.value}
                      errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      onChange={input.onChange}
                      required={isSeller}
                      width="full"
                      className="product-details-form__offer-volume"
                    />
                  )}
                </Field>
              </div>
              <div className="flex gap-20px margin-t-20px">
                <Field name="sound_kernel_recovery" validate={isSeller ? requiredField : null}>
                  {({ input, meta }) => (
                    <SelectBox
                      label={t('offer_creation.product_details.form.sound_kernel_recovery.label')}
                      placeholderText={t(
                        'offer_creation.product_details.form.sound_kernel_recovery.placeholder',
                      )}
                      options={soundKernelRecoveries}
                      value={input.value}
                      errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      onChange={input.onChange}
                      required={isSeller}
                      width="full"
                      className="product-details-form__offer-volume"
                    />
                  )}
                </Field>
              </div>
              {isSeller && (
                <div className="flex flex-col margin-t-20px">
                  {requiredSectionHeading(
                    t('offer_creation.product_details.form.moisture_content.label'),
                  )}
                  <Field name="moisture_content" type="checkbox" validate={requiredCheckbox}>
                    {({ input, meta }) => (
                      <div className="flex flex-col items-start margin-t-16px">
                        <CheckBox
                          id="MoistureContent"
                          label={
                            <>
                              {t('offer_creation.product_details.form.moisture_content.text')}
                              <span className="business-details__section-header--required">*</span>
                            </>
                          }
                          isChecked={input.checked}
                          onChange={input.onChange}
                          className="flex items-center margin-t-16px margin-b-20px business-details__checkbox"
                        />
                        <div>
                          <p className="offer-declaration__text--error margin-none">
                            {meta?.touched && meta?.error}
                          </p>
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
              )}
              <div className="flex gap-20px margin-t-20px">
                <Field name="tree_variant" validate={isSeller ? requiredField : null}>
                  {({ input, meta }) => (
                    <SelectBox
                      label={<>{t('offer_creation.product_details.form.tree_variant.label')}</>}
                      placeholderText={t(
                        'offer_creation.product_details.form.tree_variant.placeholder',
                      )}
                      tooltip={<>{t('offer_creation.product_details.form.tree_variant.tooltip')}</>}
                      options={treeVariants}
                      value={input.value}
                      errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      onChange={input.onChange}
                      onCreateOption={(createdOption) =>
                        input.onChange({ label: createdOption, value: createdOption })
                      }
                      isCreatable={true}
                      required={isSeller}
                      width="full"
                      className="product-details-form__offer-volume"
                    />
                  )}
                </Field>
              </div>
              <div className="flex gap-20px margin-t-20px">
                <Field name="season_harvested" validate={isSeller ? requiredField : null}>
                  {({ input, meta }) => (
                    <SelectBox
                      label={t('offer_creation.product_details.form.season_harvested.label')}
                      placeholderText={t(
                        'offer_creation.product_details.form.season_harvested.placeholder',
                      )}
                      options={seasons}
                      value={input.value}
                      errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      onChange={input.onChange}
                      required={isSeller}
                      width="full"
                      className="product-details-form__offer-volume"
                    />
                  )}
                </Field>
              </div>
              <div className="flex gap-20px margin-t-20px">
                <Field name="region_of_origin">
                  {({ input, meta }) => (
                    <SelectBox
                      label={t('offer_creation.product_details.form.region.label')}
                      placeholderText={t('offer_creation.product_details.form.region.placeholder')}
                      options={regions}
                      value={input.value}
                      errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      onChange={input.onChange}
                      width="full"
                      className="product-details-form__offer-volume"
                    />
                  )}
                </Field>
                <Field name="country_of_origin" validate={isSeller ? requiredField : null}>
                  {({ input, meta }) => (
                    <CountrySelect
                      label={t('offer_creation.product_details.form.country.label')}
                      tooltip={t('offer_creation.product_details.form.country.tooltip')}
                      placeholder={t('offer_creation.product_details.form.country.placeholder')}
                      required={isSeller}
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta?.touched}
                      country={input.value}
                      onChange={(code) => input.onChange(code)}
                    />
                  )}
                </Field>
              </div>
              <div className="flex gap-20px margin-t-20px">
                <Field name="best_before_date">
                  {({ input, meta }) => (
                    <DatePicker
                      label={t('offer_creation.product_details.form.best_before.label')}
                      placeholder={t('offer_creation.product_details.form.best_before.placeholder')}
                      id="bestBeforeDate"
                      focused={isBestBeforeOpen}
                      onFocusChange={(isFocued) => setIsBestBeforeOpen(isFocued)}
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      date={input.value}
                      onChange={input.onChange}
                      required={true}
                      pastYearsCount={0}
                      className="product-details-form__offer-price"
                    />
                  )}
                </Field>
              </div>
              <p className="product-details-form__section-title margin-t-60px">
                {t('offer_creation.product_details.form.logistics_section')}
              </p>
              <div className="flex gap-20px margin-t-20px">
                <Field name="container_load" validate={isSeller ? requiredField : null}>
                  {({ input, meta }) => (
                    <SelectBox
                      label={t('offer_creation.product_details.form.container_load.label')}
                      placeholderText={t(
                        'offer_creation.product_details.form.container_load.placeholder',
                      )}
                      options={containerLoads}
                      value={input.value}
                      errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      onChange={input.onChange}
                      required={isSeller}
                      width="full"
                      className="product-details-form__offer-volume"
                    />
                  )}
                </Field>
                <Field name="estimated_shipping_date">
                  {({ input, meta }) => (
                    <DatePicker
                      label={t('offer_creation.product_details.form.esd.label')}
                      placeholder={t('offer_creation.product_details.form.esd.placeholder')}
                      id="estimatedShippingDate"
                      focused={isESDDateOpen}
                      onFocusChange={(isFocued) => setIsESDDateOpen(isFocued)}
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      date={input.value}
                      onChange={input.onChange}
                      required={isSeller}
                      pastYearsCount={0}
                      openDirection="up"
                      className="product-details-form__offer-price"
                    />
                  )}
                </Field>
              </div>
              <div className="product-details-form__logisitcs margin-t-20px">
                {isSeller && (
                  <Field name="payment_options" validate={requiredField}>
                    {({ input, meta }) => (
                      <SelectBox
                        label={t('offer_creation.product_details.form.payment.label')}
                        placeholderText={t(
                          'offer_creation.product_details.form.payment.placeholder',
                        )}
                        options={paymentOptions}
                        value={input.value}
                        errorMsg={
                          !meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)
                        }
                        touched={meta.touched}
                        onChange={input.onChange}
                        onCreateOption={(createdOption) =>
                          input.onChange({ label: createdOption, value: createdOption })
                        }
                        required={true}
                        isCreatable={true}
                        menuPlacement="top"
                        width="full"
                        className="product-details-form__offer-volume"
                      />
                    )}
                  </Field>
                )}
                <Field name="consignment_identifier">
                  {({ input, meta }) => (
                    <Input
                      label={labelWithHints(
                        t('offer_creation.product_details.form.identifier.label'),
                        t('offer_creation.product_details.form.identifier.tooltip'),
                      )}
                      placeholder={t('offer_creation.product_details.form.identifier.placeholder')}
                      className="product-details-form__offer-volume"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};

DriedNutInShellProductDetails.defaultProps = {
  currentStepIndex: 0,
  onPrevButtonClick: () => {},
  onProductSave: () => {},
  productDetails: null,
};

DriedNutInShellProductDetails.propTypes = {
  currentStepIndex: PropTypes.number,
  onPrevButtonClick: PropTypes.func,
  onProductSave: PropTypes.func,
  productDetails: PropTypes.shape({
    id: PropTypes.number,
    offerVolume: PropTypes.string,
    nutGrade: PropTypes.string,
    size: PropTypes.string,
    soundKernelRecovery: PropTypes.string,
    unsoundKernelRecovery: PropTypes.string,
    moistureContent: PropTypes.bool,
    certifications: PropTypes.string,
    treeVariant: PropTypes.string,
    seasonHarvested: PropTypes.string,
    regionOfOrigin: PropTypes.string,
    countryOfOrigin: PropTypes.string,
    bestBeforeDate: PropTypes.string,
    containerLoad: PropTypes.string,
    estimatedShippingDate: PropTypes.string,
    paymentOptions: PropTypes.string,
    consignmentIdentifier: PropTypes.string,
  }),
  companyDetails: PropTypes.shape({
    company_permissions: PropTypes.shape({
      trade_kernel: PropTypes.bool,
      trade_dried_nut_in_shell: PropTypes.bool,
    }),
  }).isRequired,
};

export default DriedNutInShellProductDetails;
