import React from 'react';
import { offerOrderPageIndex } from 'utils/constants/listingCreation';
import ReviewListingCard from 'components/Shared/BiddingProcess/ReviewListing';
import CreateListingAuthorizer from './Layout/CreateListingAuthorizer';
import CreateListingLayout from './Layout';

const ReviewListing = () => (
  <CreateListingAuthorizer>
    <CreateListingLayout activeStep={offerOrderPageIndex.reviewListing}>
      <ReviewListingCard />
    </CreateListingLayout>
  </CreateListingAuthorizer>
);

export default ReviewListing;
