import {
  FIRST_PAGE,
  HANDLE_BEST_BEFORE_CHANGE,
  HANDLE_SHIPPING_START_DATE_CHANGE,
  HANDLE_SHIPPING_END_DATE_CHANGE,
  HANDLE_BRC_LEVEL_CHANGE,
  HANDLE_COUNTRY_CHANGE,
  HANDLE_PASTEURIZED_CHANGE,
  HANDLE_HALAAL_CHANGE,
  HANDLE_HARVEST_SEASON_CHANGE,
  HANDLE_KOSHER_CHANGE,
  HANDLE_LISTING_DATE_CHANGE,
  HANDLE_LISTING_TYPE_CHANGE,
  HANDLE_MY_LISTINGS_CHANGE,
  HANDLE_PRODUCT_TYPE_CHANGE,
  HANDLE_PREMIUM_NUT_GRADE_CHANGE,
  HANDLE_COMMERCIAL_NUT_GRADE_CHANGE,
  HANDLE_REJECTED_NUT_GRADE_CHANGE,
  HANDLE_HACCP_CERTIFICATION_CHANGE,
  HANDLE_FSSC_CERTIFICATION_CHANGE,
  HANDLE_BRC_CERTIFICATION_CHANGE,
  HANDLE_FCL_CONTAINER_LOAD_CHANGE,
  HANDLE_LCL_CONTAINER_LOAD_CHANGE,
  HANDLE_NUT_STYLE_0_CHANGE,
  HANDLE_NUT_STYLE_1_CHANGE,
  HANDLE_NUT_STYLE_1S_CHANGE,
  HANDLE_NUT_STYLE_2_CHANGE,
  HANDLE_NUT_STYLE_4_CHANGE,
  HANDLE_NUT_STYLE_4R_CHANGE,
  HANDLE_NUT_STYLE_4L_CHANGE,
  HANDLE_NUT_STYLE_5_CHANGE,
  HANDLE_NUT_STYLE_6_CHANGE,
  HANDLE_NUT_STYLE_7_CHANGE,
  HANDLE_NUT_STYLE_8_CHANGE,
  HANDLE_OTHER_NUT_STYLE_CHANGE,
  HANDLE_SOUND_KERNEL_RECOVERY_CHANGE,
  HANDLE_UNSOUND_KERNEL_RECOVERY_CHANGE,
  HANDLE_TREE_VARIANT_BEAUMONT_CHANGE,
  HANDLE_TREE_VARIANT_HYBRID_CHANGE,
  HANDLE_TREE_VARIANT_INTEGRIFOLIA_CHANGE,
  HANDLE_TREE_VARIANT_OTHER_CHANGE,
  HANDLE_DNIS_CERTIFICATION_GLOBAL_GAP_CHANGE,
  HANDLE_DNIS_CERTIFICATION_PPECB_CHANGE,
  HANDLE_DNIS_CERTIFICATION_ESG_CHANGE,
  HANDLE_PAGE_CHANGE,
  HANDLE_DAP_PAYMENT_OPTION_CHANGE,
  HANDLE_DAYS_30_PAYMENT_OPTION_CHANGE,
  HANDLE_DAYS_60_PAYMENT_OPTION_CHANGE,
  HANDLE_DAYS_90_PAYMENT_OPTION_CHANGE,
  HANDLE_DAYS_180_PAYMENT_OPTION_CHANGE,
  HANDLE_CUSTOM_PAYMENT_OPTION_CHANGE,
  HANDLE_PRICE_CHANGE,
  HANDLE_PROCESSED_SEASON_CHANGE,
  HANDLE_QUANTITY_CHANGE,
  HANDLE_REGION_CHANGE,
  HANDLE_RESET_CLICK,
  HANDLE_ORGANIC_CHANGE,
  HANDLE_NUT_STYLE_RESET_CLICK,
  HANDLE_NUT_SIZE_RESET_CLICK,
  HANDLE_NUT_SIZE_LESS_THAN_18_CHANGE,
  HANDLE_NUT_SIZE_20_PLUS_CHANGE,
  HANDLE_NUT_SIZE_18_PLUS_CHANGE,
  HANDLE_NUT_SIZE_22_PLUS_CHANGE,
  HANDLE_NUT_SIZE_25_PLUS_CHANGE,
  HANDLE_NUT_SIZE_20_22_CHANGE,
  HANDLE_NUT_SIZE_22_25_CHANGE,
  HANDLE_NUT_SIZE_18_20_CHANGE,
  HANDLE_OTHER_NUT_SIZE_CHANGE,
  HANDLE_NUT_GRADE_RESET_CLICK,
  HANDLE_PAYMENT_OPTION_RESET_CLICK,
  HANDLE_CERTIFICATIONS_RESET_CLICK,
  HANDLE_DNIS_CERTIFICATIONS_RESET_CLICK,
  HANDLE_TREE_VARIANT_RESET_CLICK,
  HANDLE_SORT_CHANGE,
  filterOptions,
} from 'utils/constants/offerListings';
import { parseToTwoDecimals } from 'utils/helper/formatNumber';

export const initialFilterState = {
  myListings: false,
  listingType: 'concluded_contracts',
  listingDateRange: 'previous_year',
  haccp: null,
  fssc: null,
  brc: null,
  fcl: null,
  lcl: null,
  style0: null,
  style1: null,
  style1s: null,
  style2: null,
  style4: null,
  style4r: null,
  style4l: null,
  style5: null,
  style6: null,
  style7: null,
  style8: null,
  otherNutStyle: null,
  less_than_18mm: null,
  size_18_20mm: null,
  size_20_22mm: null,
  size_22_25mm: null,
  size_25mm_plus: null,
  size_22mm_plus: null,
  size_20mm_plus: null,
  size_18mm_plus: null,
  other_size: null,
  nutGrade: null,
  harvestSeason: '',
  processedSeason: '',
  bestBeforeDate: null,
  shippingStartDate: null,
  shippingEndDate: null,
  minPricePerKg: null,
  maxPricePerKg: null,
  minQuantity: null,
  maxQuantity: null,
  paymentoption: null,
  premium: null,
  commercial: null,
  rejected: null,
  dap: null,
  days30: null,
  days60: null,
  days90: null,
  days180: null,
  customPaymentOption: null,
  brcLevel: null,
  region: '',
  country: null,
  nutSize: '',
  soundKernelRecovery: '',
  unsoundKernelRecovery: '',
  beaumont: null,
  hybrid: null,
  integrifolia: null,
  otherTreeVariant: null,
  globalGap: null,
  ppecb: null,
  esg: null,
  halaal: false,
  kosher: false,
  organic: null,
  pasteurized: null,
  sort: filterOptions[0],
  withGraphData: true,
  pageNumber: FIRST_PAGE,
};

// eslint-disable-next-line default-param-last
const filterReducer = (state = initialFilterState, action) => {
  switch (action.type) {
    case HANDLE_MY_LISTINGS_CHANGE: {
      return { ...state, myListings: action.payload, withGraphData: true };
    }
    case HANDLE_LISTING_TYPE_CHANGE: {
      return { ...state, listingType: action.payload, withGraphData: true };
    }
    case HANDLE_PRODUCT_TYPE_CHANGE: {
      return { ...state, productType: action.payload, withGraphData: true };
    }
    case HANDLE_LISTING_DATE_CHANGE: {
      return { ...state, shippingStartDate: null, shippingEndDate: null, listingDateRange: action.payload, withGraphData: true };
    }
    case HANDLE_FCL_CONTAINER_LOAD_CHANGE: {
      return { ...state, fcl: action.payload, withGraphData: true };
    }
    case HANDLE_LCL_CONTAINER_LOAD_CHANGE: {
      return { ...state, lcl: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_SIZE_LESS_THAN_18_CHANGE: {
      return { ...state, lessThan18mm: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_SIZE_18_20_CHANGE: {
      return { ...state, size1820mm: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_SIZE_20_22_CHANGE: {
      return { ...state, size2022mm: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_SIZE_22_25_CHANGE: {
      return { ...state, size2225mm: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_SIZE_25_PLUS_CHANGE: {
      return { ...state, size25mmPlus: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_SIZE_22_PLUS_CHANGE: {
      return { ...state, size22mmPlus: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_SIZE_20_PLUS_CHANGE: {
      return { ...state, size20mmPlus: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_SIZE_18_PLUS_CHANGE: {
      return { ...state, size18mmPlus: action.payload, withGraphData: true };
    }
    case HANDLE_OTHER_NUT_SIZE_CHANGE: {
      return { ...state, otherSize: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_STYLE_0_CHANGE: {
      return { ...state, style0: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_STYLE_1_CHANGE: {
      return { ...state, style1: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_STYLE_1S_CHANGE: {
      return { ...state, style1s: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_STYLE_2_CHANGE: {
      return { ...state, style2: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_STYLE_4_CHANGE: {
      return { ...state, style4: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_STYLE_4R_CHANGE: {
      return { ...state, style4r: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_STYLE_4L_CHANGE: {
      return { ...state, style4l: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_STYLE_5_CHANGE: {
      return { ...state, style5: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_STYLE_6_CHANGE: {
      return { ...state, style6: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_STYLE_7_CHANGE: {
      return { ...state, style7: action.payload, withGraphData: true };
    }
    case HANDLE_NUT_STYLE_8_CHANGE: {
      return { ...state, style8: action.payload, withGraphData: true };
    }
    case HANDLE_OTHER_NUT_STYLE_CHANGE: {
      return { ...state, otherNutStyle: action.payload, withGraphData: true };
    }
    case HANDLE_SOUND_KERNEL_RECOVERY_CHANGE: {
      return { ...state, soundKernelRecovery: action.payload, withGraphData: true };
    }
    case HANDLE_UNSOUND_KERNEL_RECOVERY_CHANGE: {
      return { ...state, unsoundKernelRecovery: action.payload, withGraphData: true };
    }
    case HANDLE_TREE_VARIANT_BEAUMONT_CHANGE: {
      return { ...state, beaumont: action.payload, withGraphData: true };
    }
    case HANDLE_TREE_VARIANT_HYBRID_CHANGE: {
      return { ...state, hybrid: action.payload, withGraphData: true };
    }
    case HANDLE_TREE_VARIANT_INTEGRIFOLIA_CHANGE: {
      return { ...state, integrifolia: action.payload, withGraphData: true };
    }
    case HANDLE_TREE_VARIANT_OTHER_CHANGE: {
      return { ...state, otherTreeVariant: action.payload, withGraphData: true };
    }
    case HANDLE_DNIS_CERTIFICATION_GLOBAL_GAP_CHANGE: {
      return { ...state, globalGap: action.payload, withGraphData: true };
    }
    case HANDLE_DNIS_CERTIFICATION_PPECB_CHANGE: {
      return { ...state, ppecb: action.payload, withGraphData: true };
    }
    case HANDLE_DNIS_CERTIFICATION_ESG_CHANGE: {
      return { ...state, esg: action.payload, withGraphData: true };
    }
    case HANDLE_HARVEST_SEASON_CHANGE: {
      return { ...state, harvestSeason: action.payload, withGraphData: true };
    }
    case HANDLE_PROCESSED_SEASON_CHANGE: {
      return { ...state, processedSeason: action.payload, withGraphData: true };
    }
    case HANDLE_BEST_BEFORE_CHANGE: {
      return { ...state, bestBeforeDate: action.payload, withGraphData: true };
    }
    case HANDLE_SHIPPING_START_DATE_CHANGE: {
      return { ...state, listingDateRange: null, shippingStartDate: action.payload, withGraphData: true };
    }
    case HANDLE_SHIPPING_END_DATE_CHANGE: {
      return { ...state, listingDateRange: null, shippingEndDate: action.payload, withGraphData: true };
    }
    case HANDLE_PRICE_CHANGE: {
      return {
        ...state,
        minPricePerKg: parseToTwoDecimals(action.payload[0]),
        maxPricePerKg: parseToTwoDecimals(action.payload[1]),
        withGraphData: true,
      };
    }
    case HANDLE_QUANTITY_CHANGE: {
      return {
        ...state,
        minQuantity: parseToTwoDecimals(action.payload[0]),
        maxQuantity: parseToTwoDecimals(action.payload[1]),
        withGraphData: true,
      };
    }
    case HANDLE_PREMIUM_NUT_GRADE_CHANGE: {
      return { ...state, premium: action.payload, withGraphData: true };
    }
    case HANDLE_COMMERCIAL_NUT_GRADE_CHANGE: {
      return { ...state, commercial: action.payload, withGraphData: true };
    }
    case HANDLE_REJECTED_NUT_GRADE_CHANGE: {
      return { ...state, rejected: action.payload, withGraphData: true };
    }
    case HANDLE_DAP_PAYMENT_OPTION_CHANGE: {
      return { ...state, dap: action.payload, withGraphData: true };
    }
    case HANDLE_DAYS_30_PAYMENT_OPTION_CHANGE: {
      return { ...state, days30: action.payload, withGraphData: true };
    }
    case HANDLE_DAYS_60_PAYMENT_OPTION_CHANGE: {
      return { ...state, days60: action.payload, withGraphData: true };
    }
    case HANDLE_DAYS_90_PAYMENT_OPTION_CHANGE: {
      return { ...state, days90: action.payload, withGraphData: true };
    }
    case HANDLE_DAYS_180_PAYMENT_OPTION_CHANGE: {
      return { ...state, days180: action.payload, withGraphData: true };
    }
    case HANDLE_CUSTOM_PAYMENT_OPTION_CHANGE: {
      return { ...state, customPaymentOption: action.payload, withGraphData: true };
    }
    case HANDLE_HACCP_CERTIFICATION_CHANGE: {
      const brcLevel = null;
      return { ...state, haccp: action.payload, brcLevel, withGraphData: true };
    }
    case HANDLE_FSSC_CERTIFICATION_CHANGE: {
      const brcLevel = null;
      return { ...state, fssc: action.payload, brcLevel, withGraphData: true };
    }
    case HANDLE_BRC_CERTIFICATION_CHANGE: {
      const { brcLevel } = state;
      return { ...state, brc: action.payload, brcLevel, withGraphData: true };
    }
    case HANDLE_BRC_LEVEL_CHANGE: {
      return { ...state, brcLevel: action.payload, withGraphData: true };
    }
    case HANDLE_REGION_CHANGE: {
      return { ...state, region: action.payload, withGraphData: true };
    }
    case HANDLE_COUNTRY_CHANGE: {
      return { ...state, country: action.payload, withGraphData: true };
    }
    case HANDLE_PASTEURIZED_CHANGE: {
      return { ...state, pasteurized: action.payload, withGraphData: true };
    }
    case HANDLE_HALAAL_CHANGE: {
      return { ...state, halaal: action.payload, withGraphData: true };
    }
    case HANDLE_KOSHER_CHANGE: {
      return { ...state, kosher: action.payload, withGraphData: true };
    }
    case HANDLE_ORGANIC_CHANGE: {
      return { ...state, organic: action.payload, withGraphData: true };
    }
    case HANDLE_SORT_CHANGE: {
      return {
        ...state,
        sort: {
          ...state.sort,
          label: action?.payload?.label,
          column: action?.payload?.column,
          direction: action?.payload?.direction,
        },
        withGraphData: false,
      };
    }
    case HANDLE_PAGE_CHANGE: {
      return { ...state, pageNumber: action.payload, withGraphData: false };
    }
    case HANDLE_RESET_CLICK: {
      return {
        myListings: false,
        listingType: state.listingType,
        listingDateRange: state.listingDateRange,
        soundKernelRecovery: '',
        unsoundKernelRecovery: '',
        beaumont: null,
        hybrid: null,
        integrifolia: null,
        otherTreeVariant: null,
        globalGap: null,
        ppecb: null,
        esg: null,
        harvestSeason: '',
        processedSeason: '',
        bestBeforeDate: null,
        shippingStartDate: null,
        shippingEndDate: null,
        minPricePerKg: null,
        maxPricePerKg: null,
        minQuantity: null,
        maxQuantity: null,
        paymentoption: null,
        premium: null,
        commercial: null,
        rejected: null,
        dap: null,
        days30: null,
        days60: null,
        days90: null,
        days180: null,
        customPaymentOption: null,
        haccp: null,
        fssc: null,
        brc: null,
        fcl: null,
        lcl: null,
        style0: null,
        style1: null,
        style1s: null,
        style2: null,
        style4: null,
        style4r: null,
        style4l: null,
        style5: null,
        style6: null,
        style7: null,
        style8: null,
        otherNutStyle: null,
        lessThan18mm: null,
        size1820mm: null,
        size2022mm: null,
        size2225mm: null,
        size25mmPlus: null,
        size22mmPlus: null,
        size20mmPlus: null,
        size18mmPlus: null,
        otherSize: null,
        brcLevel: null,
        region: '',
        country: null,
        halaal: false,
        kosher: false,
        organic: null,
        pasteurized: null,
        sort: filterOptions[0],
        withGraphData: true,
        pageNumber: FIRST_PAGE,
      };
    }
    case HANDLE_NUT_STYLE_RESET_CLICK: {
      return {
        ...state,
        style0: null,
        style1: null,
        style1s: null,
        style2: null,
        style4: null,
        style4r: null,
        style4l: null,
        style5: null,
        style6: null,
        style7: null,
        style8: null,
        otherNutStyle: null,
        withGraphData: true,
      };
    }
    case HANDLE_NUT_SIZE_RESET_CLICK: {
      return {
        ...state,
        lessThan18mm: null,
        size1820mm: null,
        size2022mm: null,
        size2225mm: null,
        size25mmPlus: null,
        size22mmPlus: null,
        size20mmPlus: null,
        size18mmPlus: null,
        otherSize: null,
        withGraphData: true,
      };
    }
    case HANDLE_NUT_GRADE_RESET_CLICK: {
      return {
        ...state,
        premium: null,
        commercial: null,
        rejected: null,
        withGraphData: true,
      };
    }
    case HANDLE_PAYMENT_OPTION_RESET_CLICK: {
      return {
        ...state,
        dap: null,
        days30: null,
        days60: null,
        days90: null,
        days180: null,
        customPaymentOption: null,
        withGraphData: true,
      };
    }
    case HANDLE_CERTIFICATIONS_RESET_CLICK: {
      return {
        ...state,
        brc: null,
        haccp: null,
        fssc: null,
        withGraphData: true,
      };
    }
    case HANDLE_DNIS_CERTIFICATIONS_RESET_CLICK: {
      return {
        ...state,
        globalGap: null,
        ppecb: null,
        esg: null,
        withGraphData: true,
      };
    }
    case HANDLE_TREE_VARIANT_RESET_CLICK: {
      return {
        ...state,
        beaumont: null,
        hybrid: null,
        integrifolia: null,
        otherTreeVariant: null,
        withGraphData: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default filterReducer;
