import { useGetCompanyQuery } from 'api/companies';
import { useGetCurrentUserQuery, useGetProfileCompletenessQuery } from 'api/users';
import { HUNDRED_PERCENT } from 'utils/constants/progressCardDetails';
import { getEditStatus } from 'utils/helper/Portals';
import {
  isVerified,
  userRequestedVerification,
} from 'utils/helper/UserRoleValidation';

const usePortal = (isAdmin) => {
  const { data: userDetails = {} } = useGetCurrentUserQuery();
  const { id: userId, company_id: companyId } = userDetails;
  const { data: companyDetails = {} } = useGetCompanyQuery({ id: companyId }, { skip: !companyId });
  const { data: profileCompleteness = {} } = useGetProfileCompletenessQuery(userId, {
    skip: !userId,
  });

  const {
    personal_details: personalDetailsPercentage,
    business_details: businessDetailsPercentage,
  } = profileCompleteness;
  const { additional_documents: additionalDocuments } = companyDetails;

  const isUserVerified = isVerified(companyDetails);
  const hasRequestedVerification = userRequestedVerification(companyDetails);
  const isEditable = getEditStatus(isAdmin, isUserVerified, hasRequestedVerification);
  const canUploadAvatar = personalDetailsPercentage === HUNDRED_PERCENT;

  return {
    userId,
    companyId,
    userDetails,
    companyDetails,
    profileCompleteness,
    personalDetailsPercentage,
    businessDetailsPercentage,
    additionalDocuments,
    canUploadAvatar,
    isEditable,
  };
};

export default usePortal;
