import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatDate } from 'utils/helper/FormatDate';
import { isMSMSeller, isMSMSubscriptionMember } from 'utils/helper/UserRoleValidation';
import { admin } from 'utils/constants/Routes';
import { allUsersTab, appliedTab, approvedTab } from 'utils/constants/portals';
import {
  DOCUMENT_REQUESTED,
  REQUESTED_VERIFICATION,
  UNVERIFIED,
  VERIFIED,
} from 'utils/constants/userStatus';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/BaseComponents/Table';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import Icon from 'components/BaseComponents/Icon';
import { BUYER, SELLER, userRoleLabels } from 'utils/constants/userRole';
import TableColumn from '../../TableColumn';

const formattedUserStatus = {
  [UNVERIFIED]: t('admin_user_table.unapproved_tab'),
  [VERIFIED]: t('admin_user_table.approved_tab'),
  [REQUESTED_VERIFICATION]: t('admin_user_table.applied_tab'),
  [DOCUMENT_REQUESTED]: t('admin_user_table.document_requested_tab'),
};

const generateDateColumnContent = (tabKey, company) => {
  switch (tabKey) {
    case appliedTab:
      return formatDate(company.requestedVerificationAt);
    case approvedTab:
      return formatDate(company.verifiedAt);
    default:
      return null;
  }
};

const generateUserStatusColumnContent = (tabKey, company) => {
  if (tabKey === allUsersTab) {
    return formattedUserStatus[company.status];
  }

  return null;
};

const generateCompanyPermissionsContent = (company, role) => {
  if (company.status !== VERIFIED) {
    return null;
  }

  const {
    companyPermissions: {
      tradeKernel,
      tradeDriedNutInShell,
    }
  } = company;

  switch (role) {
    case BUYER:
      return `${t('admin_user_table.kernel')}, ${t('admin_user_table.dried_nut_in_shell')}`;
    case SELLER:
      return [
        tradeKernel && t('admin_user_table.kernel'),
        tradeDriedNutInShell && t('admin_user_table.dried_nut_in_shell'),
      ].filter((e) => e).join(', ');
    default:
      return null;
  }
};

const UserTable = ({ tabKey, tableHeaders, users, fetchingAllUsers }) => (
  <Table className="admin-portal__table">
    <TableHead>
      <TableRow isHeaderRow={true}>
        {tableHeaders.map((title) => (
          <TableHeader
            key={title}
            textCase="capitalize"
            unavailableText={t('admin_user_table.no_data_available')}
          >
            {title}
          </TableHeader>
        ))}
      </TableRow>
    </TableHead>

    {fetchingAllUsers ? (
      <TableBody className="position-relative padding-y-32px">
        <LoadingSpinner />
      </TableBody>
    ) : (
      <TableBody>
        {users.map(({ id, firstName, lastName, company, role, email }) => {
          const dateColumnContent = generateDateColumnContent(tabKey, company);
          const userStatusColumnContent = generateUserStatusColumnContent(tabKey, company);
          const userCompanyPermissionsContent = generateCompanyPermissionsContent(company, role);

          return (
            <TableRow key={id}>
              <TableColumn content={`${firstName} ${lastName}`} />
              <TableColumn content={company.name} />
              <TableColumn
                className={classNames('admin-portal__table--role', {
                  'admin-portal__table--role-green-text': isMSMSeller({ role }),
                  'admin-portal__table--role-gray-text': isMSMSubscriptionMember({ role }),
                })}
                content={userRoleLabels[role]}
              />
              <TableColumn content={email} />
              <TableColumn content={userCompanyPermissionsContent} />
              {dateColumnContent && <TableColumn content={dateColumnContent} />}
              {userStatusColumnContent && <TableColumn content={userStatusColumnContent} />}
              <TableColumn
                content={
                  <Link to={admin.userProfile(id)}>
                    <Icon name="show" size="normal" className="admin-portal__table--icon" />
                  </Link>
                }
              />
            </TableRow>
          );
        })}
      </TableBody>
    )}
  </Table>
);
UserTable.defaultProps = {
  users: [],
};

UserTable.propTypes = {
  tabKey: PropTypes.string.isRequired,
  fetchingAllUsers: PropTypes.bool.isRequired,
  tableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      company: PropTypes.shape({
        name: PropTypes.string,
        verifiedAt: PropTypes.string,
        requestedVerificationAt: PropTypes.string,
      }).isRequired,
      role: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
  ),
};

export default UserTable;
