import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import useCustomNavigate from 'hooks/useCustomNavigate';
import Button from 'components/BaseComponents/Button';
import Icon from 'components/BaseComponents/Icon';
import './styles.scss';

const SubmitButtons = ({
  isSaveAsDraftVisible,
  isSkipButtonVisible,
  buttonLabel,
  userRole,
  isSaveAsDraftButtonDisabled,
  isSubmitButtonDisabled,
  currentStepIndex,
  onPrevButtonClick,
  onSaveAsDraft,
  onSkip,
  onSubmitClick,
}) => {
  const navigate = useCustomNavigate();
  const showPreviousButton = currentStepIndex > 0;

  return (
    <div className="submit-buttons">
      <div className="submit-buttons__container">
        {showPreviousButton ? (
          <Button
            label={t('offer_creation.stepper.previous')}
            icon={<Icon name="arrowBackAlt" />}
            onClick={() => onPrevButtonClick(currentStepIndex - 1)}
            className="submit-buttons__container--previous"
          />
        ) : (
          <Button
            label={t('offer_creation.stepper.cancel')}
            onClick={() => navigate.listings(userRole)}
          />
        )}
        <div className="submit-buttons__container--save">
          {isSkipButtonVisible && (
            <Button
              label={t('offer_creation.food_safety.skip_button_label')}
              onClick={onSkip}
              disabled={isSubmitButtonDisabled}
            />
          )}
          {isSaveAsDraftVisible && (
            <Button
              label={t('offer_creation.stepper.save_as_draft')}
              onClick={onSaveAsDraft}
              disabled={isSaveAsDraftButtonDisabled}
            />
          )}
          <Button
            type="success"
            label={buttonLabel}
            submitType="submit"
            onClick={onSubmitClick}
            disabled={isSubmitButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
};

SubmitButtons.defaultProps = {
  isSaveAsDraftVisible: true,
  isSkipButtonVisible: false,
  buttonLabel: t('offer_creation.stepper.save'),
  isSaveAsDraftButtonDisabled: false,
  isSubmitButtonDisabled: false,
  currentStepIndex: 0,
  onPrevButtonClick: () => {},
  onSaveAsDraft: () => {},
  onSkip: () => {},
  onSubmitClick: () => {},
};

SubmitButtons.propTypes = {
  isSaveAsDraftVisible: PropTypes.bool,
  isSkipButtonVisible: PropTypes.bool,
  buttonLabel: PropTypes.string,
  isSaveAsDraftButtonDisabled: PropTypes.bool,
  isSubmitButtonDisabled: PropTypes.bool,
  userRole: PropTypes.string.isRequired,
  currentStepIndex: PropTypes.number,
  onPrevButtonClick: PropTypes.func,
  onSaveAsDraft: PropTypes.func,
  onSkip: PropTypes.func,
  onSubmitClick: PropTypes.func,
};

export default SubmitButtons;
