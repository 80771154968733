import { t } from 'i18next';

export const PERSONAL_DETAILS_FORM_KEY = 0;
export const BUSINESS_DETAILS_FORM_KEY = 1;
export const BUSINESS_CONTACTS_FORM_KEY = 2;
export const FOOD_SAFETY_FORM_KEY = 3;
export const ADDITIONAL_DOCUMENT_FORM_KEY = 4;
export const CONTRACT_DOCUMENT_FORM_KEY = 5;
export const COMPANY_PERMISSIONS_FORM_KEY = 6;

export const BUSINESS_DETAILS_LEGAL_DOMICILE_CHECKBOX = 'is_using_legal_domicile_address';
export const BUSINESS_DETAILS_COMPANY_EMAIL_CHECKBOX = 'is_using_personal_email';
export const BUSINESS_CONTACTS_AUTHORIZED_PERSON_CHECKBOX = 'is_using_personal_information';
export const TERMS_AND_CONDITIONS_CHECKBOX = 'accept_terms_and_conditions';
export const NOT_APPLICABLE = 'N/A';
export const MAX_NUMBER_OF_DIRECTOR = 10;
export const SINGLE_FILE_UPLOAD_COUNT = 1;
export const ZERO_FILE_UPLOAD_COUNT = 0;

const userManagementTableTabs = {
  allUsersTab: t('admin_user_table.all_users_tab'),
  appliedTab: t('admin_user_table.applied_tab'),
  approvedTab: t('admin_user_table.approved_tab'),
  documentRequestedTab: t('admin_user_table.document_requested_tab'),
  unapprovedTab: t('admin_user_table.unapproved_tab'),
};

const listingManagementTableTab = {
  offersTab: t('admin.listings_table.offers_tab'),
  ordersTab: t('admin.listings_table.orders_tab'),
  contractsTab: t('admin.listings_table.contracts_tab'),
};

export const productsFilter = {
  activeOffersTab: t('shared.filter_status.active'),
  inDraftTab: t('shared.filter_status.in_draft'),
  inProgressTab: t('admin.listings_table.in_progress_status'),
  completedTab: t('admin.listings_table.completed_status'),
  concludedTab: t('admin.listings_table.concluded_status'),
  archivedTab: t('admin.listings_table.archived'),
};

export const {
  activeOffersTab,
  inDraftTab,
  completedTab,
  inProgressTab,
  concludedTab,
  archivedTab,
} = productsFilter;

export const { allUsersTab, appliedTab, approvedTab, documentRequestedTab, unapprovedTab } =
  userManagementTableTabs;

export const { offersTab, ordersTab, contractsTab } = listingManagementTableTab;

const affiliationsTable = {
  onboardedTab: t('affiliate_partners.affiliations_table.onboarded_tab'),
  invitedTab: t('affiliate_partners.affiliations_table.invited_tab'),
  archivedTab: t('admin.listings_table.archived'),
};

export const { onboardedTab, invitedTab } = affiliationsTable;

const commonTableHeader = [
  t('admin_user_table.table_header_user_name'),
  t('admin_user_table.table_header_company'),
  t('admin_user_table.table_header_category'),
  t('admin_user_table.table_header_email'),
  t('admin_user_table.table_header_permissions'),
];

export const allUsersTabTableHeader = [
  ...commonTableHeader,
  t('admin_user_table.table_header_user_status'),
  t('admin_user_table.table_header_actions'),
];

export const appliedTabTableHeader = [
  ...commonTableHeader,
  t('admin_user_table.table_header_applied_date'),
  t('admin_user_table.table_header_actions'),
];

export const approvedTabTableHeader = [
  ...commonTableHeader,
  t('admin_user_table.table_header_approval_date'),
  t('admin_user_table.table_header_actions'),
];

export const unapprovedTabTableHeader = [
  ...commonTableHeader,
  t('admin_user_table.table_header_actions'),
];

const commonProductsTableHeader = [
  t('admin.listings_table.esd_header'),
  t('admin.listings_table.amount_header'),
  t('admin.listings_table.price_header'),
  t('admin_user_table.table_header_user_status'),
  t('admin_user_table.table_header_actions'),
];

export const contractsTabTableHeader = [
  t('admin.listings_table.contract_id'),
  t('admin.listings_table.created_at'),
  t('admin.listings_table.seller_header'),
  t('admin.listings_table.buyer_header'),
  ...commonProductsTableHeader,
];

export const offersTableHeader = [
  t('admin.listings_table.listing_id_header'),
  t('admin.listings_table.listed_at_header'),
  t('admin.listings_table.listed_by_header'),
  t('admin.listings_table.style_header'),
  ...commonProductsTableHeader,
];

export const dnisOffersTableHeader = [
  t('admin.listings_table.listing_id_header'),
  t('admin.listings_table.listed_at_header'),
  t('admin.listings_table.listed_by_header'),
  t('admin.listings_table.size_header'),
  ...commonProductsTableHeader,
];

export const commonAffiliationsTabHeader = [
  t('affiliate_partners.affiliations_table.user_id_header'),
  t('affiliate_partners.affiliations_table.user_name_header'),
  t('admin_user_table.table_header_email'),
  t('affiliate_partners.affiliations_table.company_name_header'),
  t('affiliate_partners.affiliations_table.category_header'),
];

export const oboardedTabTableHeader = [
  ...commonAffiliationsTabHeader,
  t('affiliate_partners.affiliations_table.onboarded_at_header'),
  t('affiliate_partners.affiliations_table.contact_count_header'),
];

export const invitedTabTableHeader = (isAdmin) => {
  const invitedHeaders = [
    ...commonAffiliationsTabHeader,
    t('admin_user_table.table_header_user_status'),
    t('affiliate_partners.affiliations_table.invited_at_header'),
    t('affiliate_partners.affiliations_table.invitition_count_header'),
  ];

  if (!isAdmin) {
    return [...invitedHeaders, t('admin_user_table.table_header_actions')];
  }

  return invitedHeaders;
};

export const archivedTabTableHeader = [
  ...commonAffiliationsTabHeader,
  t('affiliate_partners.affiliations_table.last_invited_at_header'),
  t('affiliate_partners.affiliations_table.invitition_count_header'),
];

export const commonPartnerTabTableHeader = [
  t('affiliate_partners.affiliations_table.user_id_header'),
  t('affiliate_partners.affiliations_table.user_name_header'),
  t('affiliate_partners.affiliations_table.company_name_header'),
  t('admin_user_table.table_header_email'),
  t('admin_user_table.table_header_user_status'),
];

export const appliedPartnerTableHeader = [
  ...commonPartnerTabTableHeader,
  t('affiliate_partners.affiliations_table.applied_date_header'),
  t('admin_user_table.table_header_actions'),
];

export const approvedPartnerTableHeader = [
  ...commonPartnerTabTableHeader,
  t('affiliate_partners.affiliations_table.approved_date_header'),
  t('admin_user_table.table_header_actions'),
];
