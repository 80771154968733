import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { apiSlice } from 'api';
import { useLogoutMutation } from 'api/auth';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { getAvatarBgColorCode } from 'utils/helper/Portals';
import { avatarDropdownContent } from 'utils/constants/navbar';
import { userProfileLinks } from 'utils/constants/userRole';
import { publicUrls } from 'utils/constants/landingPage';
import Avatar from 'components/BaseComponents/Avatar';
import Icon from 'components/BaseComponents/Icon';
import Button from 'components/BaseComponents/Button';
import './styles.scss';

const AvatarDropdown = ({
  firstName,
  lastName,
  description,
  userRole,
  imgSrc,
  setIsDropDownTooltipVisible,
}) => {
  const [isDropdownVisible, setisDropdownVisible] = useState(false);
  const { t } = useTranslation();
  const [logout] = useLogoutMutation();
  const navigate = useCustomNavigate();
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  const handleLogoutClick = () => {
    logout()
      .unwrap()
      .then(() => {
        dispatch(apiSlice.util.resetApiState());
        navigate.loginPage();
      })
      .catch(() => {
        if (publicUrls.includes(window.location.pathname)) {
          window.location.reload();
        }
      });
  };

  const userName = `${firstName} ${lastName}`;
  const avatarBgColorCode = getAvatarBgColorCode(userRole);
  const showDropDown = isDropdownVisible && firstName && lastName;

  const handleIsDropDownVisible = () => {
    setIsDropDownTooltipVisible((prev) => !prev);
    setisDropdownVisible((prev) => !prev);
  };

  const avatar = (
    <Avatar
      src={imgSrc}
      bgColor={avatarBgColorCode}
      firstName={firstName}
      lastName={lastName}
      size="normal"
    />
  );

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setisDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="avatar-dropdown" ref={dropdownRef}>
      <Button
        className="avatar-dropdown__button"
        submitType="button"
        onClick={() => handleIsDropDownVisible()}
        label={avatar}
      />
      {showDropDown && (
        <div className="avatar-dropdown__container">
          <div className="padding-x-16px flex gap-12px">
            <Avatar
              src={imgSrc}
              bgColor={avatarBgColorCode}
              firstName={firstName}
              lastName={lastName}
              className="padding-y-12px"
            />
            <div className="padding-y-12px">
              <h5 className="avatar-dropdown__container--username">{userName}</h5>
              <p className="avatar-dropdown__container--company-name">{description}</p>
            </div>
          </div>
          <ul className="avatar-dropdown__container--menu">
            {avatarDropdownContent.map(({ icon, name }) => (
              <Link
                to={userProfileLinks[userRole]}
                className="avatar-dropdown__container--menu-item"
                key={name}
              >
                <Icon name={icon} />
                {name()}
              </Link>
            ))}
            <Button
              submitType="button"
              className="flex flex-row-reverse justify-end gap-8px avatar-dropdown__container--menu-logout-button"
              onClick={handleLogoutClick}
              icon={<Icon name="logout" />}
              label={t('navbar.avatar.logout')}
            />
          </ul>
        </div>
      )}
    </div>
  );
};

AvatarDropdown.defaultProps = {
  firstName: null,
  lastName: null,
  description: null,
  userRole: null,
  imgSrc: null,
  setIsDropDownTooltipVisible: () => {},
};

AvatarDropdown.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  description: PropTypes.string,
  userRole: PropTypes.string,
  imgSrc: PropTypes.string,
  setIsDropDownTooltipVisible: PropTypes.func,
};

export default AvatarDropdown;
