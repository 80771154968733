import React, { useMemo } from 'react';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetListingDetailsQuery } from 'api/listings';
import { useGetContractQuery } from 'api/contracts';
import AdminNavBar from 'components/NavBar/AdminNavBar';
import useCustomNavigate from 'hooks/useCustomNavigate';
import breadcrumbRoutes from 'utils/breadcrumb-helper/route-names';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import EmptyContentPlaceholder from 'components/Shared/EmptyContentPlaceholder';
import { useGetFoodSafetyTestQuery, useGetProductDetailsQuery } from 'api/products';
import computeListingFlags from 'components/BiddingProcess/ListingDetails/computeListingFlags';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import ListingInformationBody from 'components/BiddingProcess/ListingDetails/ListingInformationBody';
import ContractHeaderDisplay from 'components/BiddingProcess/AdminContractDetails/ContractHeaderDisplay';
import './styles.scss';

const AdminOfferOderDetails = () => {
  const { id: productId } = useParams();
  const navigate = useCustomNavigate();

  const breadcrumbs = useReactRouterBreadcrumbs(breadcrumbRoutes);

  const { data: currentUserInformation = {}, isFetching: isFetchingUserInfo } =
    useGetCurrentUserQuery();

  const {
    data: productInformation = {},
    error: productDetailsError,
    isFetching: isFetchingProductInfo,
  } = useGetProductDetailsQuery({
    product_id: productId,
  });

  const { data: listingInformation = {}, isFetching: isFetchingListingInfo } =
    useGetListingDetailsQuery(
      {
        listing_id: productInformation?.listingId,
      },
      { skip: !productInformation?.listingId },
    );

  const { data: contractInformation = {}, isFetching: isFetchingContractInfo } =
    useGetContractQuery(
      { id: listingInformation?.contract?.id },
      { skip: !listingInformation?.contract?.id },
    );

  const { data: foodSafetyInformation = {} } = useGetFoodSafetyTestQuery({ productId });

  const isLoading =
    isFetchingProductInfo || isFetchingContractInfo || isFetchingListingInfo || isFetchingUserInfo;

  const {
    isListingCreator,
    isUserAdmin,
    isUserSeller,
    isUserBuyer,
    isListingPublished,
    isListingInContract,
    isListingConcluded,
    isOfferListing,
    isOrderListing,
    isListingAccepted,
  } = computeListingFlags(currentUserInformation, listingInformation);

  if (isListingInContract && isUserAdmin) {
    navigate.adminContractDetails(listingInformation?.contract?.id);
  }

  const contextValue = useMemo(
    () => ({
      currentUserInformation,
      listingInformation,
      productInformation,
      contractInformation,
      foodSafetyInformation,
      isListingCreator,
      isUserAdmin,
      isUserSeller,
      isUserBuyer,
      isListingPublished,
      isListingInContract,
      isListingConcluded,
      isListingAccepted,
      isOfferListing,
      isOrderListing,
    }),
    [
      listingInformation,
      currentUserInformation,
      contractInformation,
      foodSafetyInformation,
      productInformation,
    ],
  );

  const renderContractDetails = () => {
    if (productDetailsError) {
      return <EmptyContentPlaceholder heading={t('bidding_process.no_listing_found')} />;
    }

    return (
      <ListingDetailsContext.Provider value={contextValue}>
        <div className="admin-offer-order-details__header">
          <ContractHeaderDisplay />
        </div>
        <div className="admin-offer-order-details__body">
          <ListingInformationBody />
        </div>
      </ListingDetailsContext.Provider>
    );
  };

  return (
    <AdminNavBar breadcrumbs={breadcrumbs}>
      <div className="admin-offer-order-details">
        {isLoading ? <LoadingSpinner /> : renderContractDetails()}
      </div>
    </AdminNavBar>
  );
};

export default AdminOfferOderDetails;
