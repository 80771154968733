import React, { useMemo } from 'react';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { useGetListingDetailsQuery } from 'api/listings';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetContractQuery } from 'api/contracts';
import AdminNavBar from 'components/NavBar/AdminNavBar';
import breadcrumbRoutes from 'utils/breadcrumb-helper/route-names';
import EmptyContentPlaceholder from 'components/Shared/EmptyContentPlaceholder';
import { useGetFoodSafetyTestQuery, useGetProductDetailsQuery } from 'api/products';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import computeListingFlags from 'components/BiddingProcess/ListingDetails/computeListingFlags';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import ContractHeaderDisplay from 'components/BiddingProcess/AdminContractDetails/ContractHeaderDisplay';
import ContractDetailsSidebar from 'components/BiddingProcess/AdminContractDetails/ContractDetailsSidebar';
import './styles.scss';

const AdminContractDetails = () => {
  const breadcrumbs = useReactRouterBreadcrumbs(breadcrumbRoutes);

  const { id: contractId } = useParams();

  const { data: currentUserInformation = {}, isFetching: isFetchingUserInfo } =
    useGetCurrentUserQuery();

  const {
    data: contractInformation = {},
    error: contractDetailsError,
    isFetching: isFetchingContractInfo,
  } = useGetContractQuery({
    id: contractId,
  });

  const { data: listingInformation = {}, isFetching: isFetchingListingInfo } =
    useGetListingDetailsQuery(
      {
        listing_id: contractInformation?.listingId,
      },
      { skip: !contractInformation?.listingId },
    );

  const { data: productInformation = {}, isFetching: isFetchingProductInfo } =
    useGetProductDetailsQuery(
      {
        product_id: listingInformation?.productId,
      },
      { skip: !listingInformation?.productId },
    );

  const { data: foodSafetyInformation = {}, isLoading: isFetchingFoodSafetyInfo } =
    useGetFoodSafetyTestQuery(
      {
        productId: listingInformation?.productId,
      },
      { skip: !listingInformation?.productId },
    );

  const {
    isListingCreator,
    isUserAdmin,
    isUserSeller,
    isUserBuyer,
    isListingPublished,
    isListingInContract,
    isListingConcluded,
    isOfferListing,
    isOrderListing,
    isListingAccepted,
  } = computeListingFlags(currentUserInformation, listingInformation);

  const contextValue = useMemo(
    () => ({
      currentUserInformation,
      listingInformation,
      productInformation,
      contractInformation,
      foodSafetyInformation,
      isListingCreator,
      isUserAdmin,
      isUserSeller,
      isUserBuyer,
      isListingPublished,
      isListingInContract,
      isListingConcluded,
      isListingAccepted,
      isOfferListing,
      isOrderListing,
    }),
    [
      productInformation,
      listingInformation,
      currentUserInformation,
      contractInformation,
      foodSafetyInformation,
    ],
  );

  const isLoading =
    isFetchingFoodSafetyInfo ||
    isFetchingListingInfo ||
    isFetchingContractInfo ||
    isFetchingUserInfo ||
    isFetchingProductInfo;

  const renderResult = () => {
    if (contractDetailsError) {
      return <EmptyContentPlaceholder heading={t('bidding_process.no_contract_found')} />;
    }

    return (
      <ListingDetailsContext.Provider value={contextValue}>
        <div className="admin-contract-details__header">
          <ContractHeaderDisplay />
        </div>
        <ContractDetailsSidebar />
      </ListingDetailsContext.Provider>
    );
  };

  return (
    <AdminNavBar breadcrumbs={breadcrumbs}>
      {isLoading ? <LoadingSpinner /> : renderResult()}
    </AdminNavBar>
  );
};

export default AdminContractDetails;
